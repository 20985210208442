import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  EnterpriseIncludingValueMetrics,
  OrganizationMemberDto,
  OrganizationRole,
  ValueMetrics,
  ValueMetricsPerYearMonth,
} from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { InvitationDto } from '../../../../commons/Invitation.dto'
import { OrganizationDto } from '../../../../commons/Organization.dto'
import { environment } from '../../environments/environment'
import { AppSettingsService } from './appSettings.service'

const ORG_ROUTE = '/api/organizations'
@Injectable({
  providedIn: 'root',
})
export class OrgRestService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {}

  public getOrganization(uuid: string) {
    return this.http
      .get<OrganizationDto>(`${environment.backendUrl}${ORG_ROUTE}/${uuid}`)
      .pipe(first())
  }

  public getValueMetrics(uuid: string) {
    return this.http
      .get<ValueMetricsPerYearMonth[] | ValueMetrics[]>(
        `${environment.backendUrl}${ORG_ROUTE}/${uuid}/valueMetrics`,
      )
      .pipe(first())
  }

  public listOrganizations(onlyNotExpired: boolean = false) {
    const queryParams: string = onlyNotExpired ? '?onlyNotExpired=true' : ''
    return this.http
      .get<OrganizationDto[]>(
        `${environment.backendUrl}${ORG_ROUTE}${queryParams}`,
      )
      .pipe(first())
  }

  public createOrganization(name: string) {
    return this.http
      .post<OrganizationDto>(`${environment.backendUrl}${ORG_ROUTE}`, { name })
      .pipe(first())
  }

  public changeOrganizationName(orgId: string, newOrgName: string) {
    return this.http
      .patch(`${environment.backendUrl}${ORG_ROUTE}/${orgId}`, {
        newOrgName,
      })
      .pipe(first())
  }

  public invite(email: string, orgId: string, orgRoles: OrganizationRole[]) {
    return this.http
      .post(`${environment.backendUrl}${ORG_ROUTE}/${orgId}/invites`, {
        email,
        orgRoles,
      })
      .pipe(first())
  }

  public getInvites(orgId: string): Observable<InvitationDto[]> {
    return this.http
      .get<InvitationDto[]>(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/invites`,
      )
      .pipe(first())
  }
  public findInvites(email: string): Observable<InvitationDto[]> {
    return this.http
      .get<InvitationDto[]>(
        `${environment.backendUrl}/api/invites/?email=${encodeURIComponent(
          email,
        )}`,
      )
      .pipe(first())
  }

  public listMembers(orgId: string): Observable<OrganizationMemberDto[]> {
    return this.http
      .get<OrganizationMemberDto[]>(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/members`,
      )
      .pipe(first())
  }

  public resendInvite(orgId: string, inviteId: string) {
    return this.http
      .post(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/invites/${inviteId}/resend`,
        {},
      )
      .pipe(first())
  }

  public cancelInvite(orgId: string, inviteId: string) {
    return this.http
      .delete(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/invites/${inviteId}`,
        {},
      )
      .pipe(first())
  }

  public extendTrial(orgId, newExpireDate: string) {
    return this.http
      .post(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/extendtrial`,
        { newExpireDate },
        { responseType: 'text' },
      )
      .pipe(first())
  }

  public changeSalesPlan(
    orgId,
    salesPlanId,
    options?: {
      duration?: number
      includedValueMetrics?: EnterpriseIncludingValueMetrics
      enterPriseStartDate?: string
      onboardOrgClusterMapping?: { clusterId: string; planTypeId: string }[]
      force?: boolean
    },
  ) {
    return this.http
      .post(
        `${environment.backendUrl}${ORG_ROUTE}/${orgId}/salesplan/${salesPlanId}`,
        options ?? {},
        { responseType: 'text' },
      )
      .pipe(first())
  }

  public deleteOrganization(orgId: string) {
    return this.http
      .delete<void>(`${environment.backendUrl}${ORG_ROUTE}/${orgId}`)
      .pipe(first())
  }
}
