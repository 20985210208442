import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { OrganizationDto } from '../../../../commons/Organization.dto'
import { AugmentedOrgsResolverService } from './augmented-orgs.resolver'

@Injectable({
  providedIn: 'root',
})
export class AugmentedOrgResolverService implements Resolve<OrganizationDto> {
  constructor(
    private augmentedOrgsResolverService: AugmentedOrgsResolverService,
  ) {}
  public resolve(route: ActivatedRouteSnapshot): Observable<OrganizationDto> {
    const orgUuid = route.paramMap.get('orgUuid')
    return this.augmentedOrgsResolverService
      .resolve()
      .pipe(map((orgs) => orgs.find((org) => org.uuid === orgUuid)))
  }

  public resolveByOrgId(orgUuid: string): Observable<OrganizationDto> {
    return this.augmentedOrgsResolverService
      .resolve()
      .pipe(map((orgs) => orgs.find((org) => org.uuid === orgUuid)))
  }
}
