import { Component } from '@angular/core'
import {
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormGroup,
} from '@angular/forms'
import { AuditDto } from '@camunda-cloud/cloud-node-libs'
import { AuditService } from '../../service/audit.service'
import { validate } from 'uuid'

export interface AuditDtoExtended extends AuditDto {
  show?: boolean
}

function isUUID(control: AbstractControl): ValidationErrors | null {
  let isValidUUID = validate(control.value)

  if (isValidUUID) {
    return null
  } else {
    return { isValidUUID: { value: control.value } }
  }
}

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent {
  public loading: boolean = false

  public audits: AuditDtoExtended[] = []
  public filterForm: FormGroup = new FormGroup({
    search: new FormControl('', [Validators.required, isUUID]),
  })

  constructor(private auditService: AuditService) {
    this.filterForm.valueChanges.subscribe((_) => {
      if (this.filterForm.valid) {
        this.search()
      }
    })
  }

  public search() {
    this.loading = true
    this.audits = []

    this.auditService
      .getAuditLogs(this.filterForm.get('search').value)
      .subscribe((audits) => {
        this.loading = false
        for (const audit of audits) {
          const auditExtended: AuditDtoExtended = audit
          auditExtended.show = false
          this.audits.push(auditExtended)
        }
      })
  }
}
