import { DatePipe } from '@angular/common'
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { OrganizationRole } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { combineLatest, Subject } from 'rxjs'
import { Features } from '../../../../../commons/Features'
import { InvitationDto } from '../../../../../commons/Invitation.dto'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserDto } from '../../../../../commons/User.dto'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { OrgRestService } from '../../service/org.rest.service'

@Component({
  selector: 'invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss'],
})
export class InvitesComponent implements OnInit {
  @Input()
  public org: OrganizationDto
  @Input()
  public users$: Subject<UserDto[]>
  @Input()
  public allOrgs: OrganizationDto[]
  public invites$ = new Subject<InvitationDto[]>()

  public inviteEmailFormControl = new FormControl('', [
    Validators.email,
    Validators.required,
  ])
  public mayCreateInvite: boolean = false
  public inviteRoleFormControl = new FormControl('', [Validators.required])
  public inviteListLoading = true
  public inviteHandler: () => void
  public inviteListColums = [
    {
      name: 'Email',
      width: '2fr',
      ellipsis: 'right',
    },
    { name: 'Roles', width: '2fr', ellipsis: 'right' },
    { name: 'invited by', width: '2fr', ellipsis: 'right' },
    {
      name: 'invited at',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: '', width: '35px' },
  ]
  public inviteListEntites = []
  @ViewChild('inviteModal', { read: ElementRef })
  public inviteModal: ElementRef
  public availableRoles = [
    OrganizationRole.OWNER,
    OrganizationRole.ADMIN,
    OrganizationRole.ANALYST,
    OrganizationRole.DEVELOPER,
    OrganizationRole.OPERATIONS_ENGINEER,
    OrganizationRole.TASK_USER,
    OrganizationRole.VISITOR,
  ]
  public selectedRoles: string[] = []

  public activatedFeatures: Features
  constructor(
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private orgService: OrgRestService,
    private modalService: ConfirmationModalService,
  ) {
    this.inviteHandler = () => this.openInviteModal()
  }

  public ngOnInit() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.mayCreateInvite = this.activatedFeatures.admin.addToOrg

    this.refreshInvites()

    combineLatest([this.users$, this.invites$]).subscribe(
      ([users, invites]) => {
        this.inviteListLoading = true
        this.inviteListEntites = invites.map((invite) => {
          let contextMenu = []
          if (this.activatedFeatures.admin.addToOrg) {
            contextMenu.push({
              label: 'Resend Invitation',
              handler: () => this.resendInvite(invite),
            })
          }
          if (this.activatedFeatures.admin.removeFromOrg) {
            contextMenu.push({
              label: 'Cancel Invitation',
              handler: () => this.cancelInvite(invite),
            })
          }
          contextMenu.push({
            label: 'Show Email Activity in Sendgrid',
            handler: () => {
              window.open(
                `https://app.sendgrid.com/email_activity?filters=%5B%7B%22val%22%3A%5B%22${encodeURIComponent(
                  invite.email,
                )}%22%5D%2C%22selectedFieldName%22%3A%22to_email%22%2C%22comparisonType%22%3A%22Contains%22%7D%5D&isAndOperator=true&page=1`,
                '_blank',
              )
            },
          })

          return {
            data: [
              {
                type: 'text',
                content: invite.email,
                showCopyButton: true,
              },
              {
                type: 'text',
                content:
                  invite.roles && invite.roles.length > 0
                    ? invite.roles.join(', ')
                    : invite.role,
              },
              {
                type: 'text',
                content: users.find((user) => user.user_id === invite.invitedBy)
                  ? users.find((user) => user.user_id === invite.invitedBy)
                      .email
                  : invite.invitedBy,
              },
              {
                type: 'text',
                content: this.datePipe.transform(invite.created, 'medium'),
              },
              {
                type: 'contextMenu',
                options: [
                  {
                    options: contextMenu,
                  },
                ],
              },
            ],
          }
        })

        this.inviteListLoading = false
      },
    )
  }
  public cancelInvite(invite: InvitationDto) {
    this.ngZone.run(() => {
      this.inviteListLoading = true
      this.modalService.openModal({
        title: 'Cancel Invitation',
        bodyIsRaw: true,
        body: `Are you sure you want to <font color="red">cancel</font> the invitation for <b>${invite.email}</b>?`,
        cancelButton: {
          text: 'no thx',
          action: () => {
            this.inviteListLoading = false
          },
        },
        confirmButton: {
          text: 'yes please',
          type: 'danger',
          action: () => {
            this.orgService
              .cancelInvite(this.org.uuid, invite.uuid)
              .subscribe((_) => {
                this.refreshInvites()
              })
          },
        },
      })
    })
  }
  public resendInvite(invite: InvitationDto) {
    this.ngZone.run(() => {
      this.inviteListLoading = true
      this.modalService.openModal({
        title: 'Resend Invitation',
        bodyIsRaw: true,
        body: `Are you sure you want to resend the inviation for <b>${invite.email}</b>?`,
        cancelButton: {
          text: 'no thx',
          action: () => {
            this.inviteListLoading = false
          },
        },
        confirmButton: {
          text: 'yes please',
          type: 'primary',
          action: () => {
            this.orgService
              .resendInvite(this.org.uuid, invite.uuid)
              .subscribe((_) => {
                this.inviteListLoading = false
              })
          },
        },
      })
    })
  }

  public openInviteModal() {
    this.inviteListLoading = true
    this.selectedRoles = []
    this.ngZone.run(() => {
      ;(this.inviteModal.nativeElement as CmModal).open().then((result) => {
        if (result === 'confirm') {
          this.orgService
            .invite(
              this.inviteEmailFormControl.value,
              this.org.uuid,
              this.inviteRoleFormControl.value,
            )
            .subscribe((_) => {
              this.refreshInvites()
            })
        } else {
          this.inviteListLoading = false
        }
      })
    })
  }

  public selectRoleForNewInvite(role: string) {
    if (this.selectedRoles.includes(role)) {
      this.selectedRoles = this.selectedRoles.filter(
        (currentRole) => currentRole !== role,
      )
    } else {
      this.selectedRoles.push(role)
    }
    this.inviteRoleFormControl.setValue(this.selectedRoles)
  }

  private refreshInvites() {
    this.orgService
      .getInvites(this.org.uuid)
      .subscribe((invites) => this.invites$.next(invites))
  }
}
