import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { filter, map, mergeMap, take } from 'rxjs/operators'
import { AppInfoRestService } from './service/appinfo.rest.service'
import { FeatureService } from './service/feature.service'
import { NotificationService } from './service/notification.service'

@Component({
  selector: 'app-main',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public title = 'Camunda Accounts'
  public snackbarMessage = ''
  public showSnackbar = false
  public snackbarSeverity = ''
  public sha$: Observable<string>
  public user$: Observable<{ name: ''; email: '' }>

  loading = true

  @ViewChild('notificationContainer', { static: true, read: ElementRef })
  public notificationContainer: ElementRef<HTMLCmNotificationContainerElement>
  public user: any
  constructor(
    private notificationService: NotificationService,
    private appInfoRestService: AppInfoRestService,
    private features: FeatureService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
    this.sha$ = this.features.activated$.pipe(
      filter((activatedFeatures) => activatedFeatures.gitSha),
      mergeMap((activatedFeatures) => {
        if (activatedFeatures.gitSha) {
          return this.appInfoRestService.getAppInfo()
        }
        return of({ info: { shortSha: '' } })
      }),
      map((info) => {
        if (info.info.shortSha) {
          return `sha: ${info.info.shortSha} |`
        } else {
          return ''
        }
      }),

      take(1),
    )

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.user = user
      }
    })
  }
  ngOnInit() {
    this.notificationService.notifications$.subscribe((notification) => {
      this.notificationContainer.nativeElement.enqueueNotification(notification)
    })
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }
}
