import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BpmnModelMetrics, SalesPlanDto } from '@camunda-cloud/cloud-node-libs'
import { Features } from '../../../../../commons/Features'
import { M2MTokenRequests } from '../../../../../commons/M2MTokenRequests.dto'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserDto } from '../../../../../commons/User.dto'

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public activatedFeatures: Features

  public salesPlans: Map<SalesPlanDto, number>
  public top5Users: UserDto[]
  public top5LoginsNonCamundos: UserDto[]
  public latestUsers: UserDto[]

  public m2mTokenRequestCount: number
  public massiveM2mTokenRequester: M2MTokenRequests[]

  public latestLogins: Array<{ username: string; count: number }>

  public orgCount: number
  public latestOrgs: OrganizationDto[]
  public clusterCount: number
  public newAndInactiveClusters: number
  public newAndActiveClusters: number
  public oldAndActiveClusters: number
  public oldAndInactiveClusters: number
  public clusterPlansTypes: Map<
    string,
    {
      clusterPlans: Map<
        string,
        { datacenters: Map<string, number>; totalCount: number }
      >
      totalCount: number
    }
  >
  public clustersByDatacenter: Map<string, number>

  public newOrgNoClusterCount: number
  public newOrgWithClusterCount: number
  public oldOrgActiveClusterCount: number
  public oldOrgsStaleClusterCount: number
  public oldOrgNoClusterCount: number
  public clusterGenerations: Map<
    string,
    { datacenters: Map<string, number>; totalCount: number }
  >
  public orgsWithoutUsersCount: number
  public bpmnModelMetrics: BpmnModelMetrics

  constructor(private route: ActivatedRoute, public router: Router) {}

  public ngOnInit() {
    const orgs = this.route.snapshot.data.orgs
    const latestUsers = this.route.snapshot.data.latestUsers
    const topLogins = this.route.snapshot.data.topLogins
    const m2mTokenRequests = this.route.snapshot.data.m2mTokenRequests
    const latestLogins = this.route.snapshot.data.latestLogins
    this.bpmnModelMetrics = this.route.snapshot.data.bpmnModelMetrics
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures

    const sPtoCount = new Map<SalesPlanDto, number>()
    this.route.snapshot.data.salesPlans.forEach((salesPlan) => {
      const count = orgs.filter(
        (filter) =>
          filter.organizationToSalesPlan &&
          filter.organizationToSalesPlan.salesPlan &&
          filter.organizationToSalesPlan.salesPlan.uuid === salesPlan.uuid,
      ).length
      sPtoCount.set(salesPlan, count)
    })
    this.salesPlans = sPtoCount

    const cpTypesCounts = new Map<
      string,
      {
        clusterPlans: Map<
          string,
          { datacenters: Map<string, number>; totalCount: number }
        >
        totalCount: number
      }
    >()
    orgs.forEach((org) => {
      org.clusters.forEach((cluster) => {
        let currentClusterPlanType = cpTypesCounts.get(cluster.planType.name)
        if (currentClusterPlanType) {
          currentClusterPlanType.totalCount++
          let currentClusterPlan = currentClusterPlanType.clusterPlans.get(
            cluster.plan.name,
          )
          if (currentClusterPlan) {
            currentClusterPlan.datacenters.set(
              cluster.k8sContext!.name,
              currentClusterPlan.datacenters.has(cluster.k8sContext!.name)
                ? currentClusterPlan.datacenters.get(
                    cluster.k8sContext!.name,
                  )! + 1
                : 1,
            )
            currentClusterPlan.totalCount++
          } else {
            const datacenters = new Map<string, number>()
            datacenters.set(cluster.k8sContext!.name, 1)
            currentClusterPlan = { datacenters, totalCount: 1 }
            currentClusterPlanType.clusterPlans.set(
              cluster.plan.name,
              currentClusterPlan,
            )
          }
        } else {
          const datacenters = new Map<string, number>()
          datacenters.set(cluster.k8sContext!.name, 1)
          const clusterPlans = new Map<
            string,
            {
              datacenters: Map<string, number>
              totalCount: number
            }
          >()
          clusterPlans.set(cluster.plan.name, { datacenters, totalCount: 1 })
          currentClusterPlanType = { clusterPlans, totalCount: 1 }
          cpTypesCounts.set(cluster.planType.name, currentClusterPlanType)
        }
      })
    })
    this.clusterPlansTypes = cpTypesCounts

    this.oldOrgNoClusterCount = orgs.filter(
      (org) => org.isOld && org.clusters.length === 0,
    ).length

    this.oldOrgActiveClusterCount = orgs.filter(
      (org) =>
        org.isOld &&
        org.clusters.length > 0 &&
        org.clusters.some(
          (cluster) =>
            cluster.minAgeReached === false ||
            cluster.hasSignsOfActivity === true,
        ),
    ).length

    this.oldOrgsStaleClusterCount = orgs.filter(
      (org) =>
        org.isOld &&
        org.clusters.length > 0 &&
        !org.clusters.some(
          (cluster) =>
            cluster.minAgeReached === false ||
            cluster.hasSignsOfActivity === true,
        ),
    ).length

    this.newOrgNoClusterCount = orgs.filter(
      (org) => !org.isOld && org.clusters.length === 0,
    ).length

    this.newOrgWithClusterCount = orgs.filter(
      (org) => !org.isOld && org.clusters.length > 0,
    ).length

    this.clusterGenerations = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .reduce((acc, cluster) => {
        if (acc.has(cluster.generation.name)) {
          const currentMapping = acc.get(cluster.generation.name)
          currentMapping.totalCount++
          if (currentMapping.datacenters.get(cluster.k8sContext!.name)) {
            currentMapping.datacenters.set(
              cluster.k8sContext!.name,
              currentMapping.datacenters.get(cluster.k8sContext!.name) + 1,
            )
          } else {
            currentMapping.datacenters.set(cluster.k8sContext!.name, 1)
          }
          acc.set(cluster.generation.name, currentMapping)
        } else {
          const currentMapping = {
            totalCount: 1,
            datacenters: new Map<string, number>(),
          }
          currentMapping.datacenters.set(cluster.k8sContext!.name, 1)
          acc.set(cluster.generation.name, currentMapping)
        }
        return acc
      }, new Map<string, { datacenters: Map<string, number>; totalCount: number }>())
    this.clustersByDatacenter = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .reduce((acc, cluster) => {
        if (acc.has(cluster.k8sContext!.name)) {
          acc.set(
            cluster.k8sContext!.name,
            acc.get(cluster.k8sContext!.name)! + 1,
          )
        } else {
          acc.set(cluster.k8sContext!.name, 1)
        }
        return acc
      }, new Map<string, number>())

    this.newAndActiveClusters = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .filter(
        (cluster) => !cluster.minAgeReached && cluster.hasSignsOfActivity,
      ).length

    this.newAndInactiveClusters = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .filter(
        (cluster) => !cluster.minAgeReached && !cluster.hasSignsOfActivity,
      ).length

    this.oldAndActiveClusters = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .filter(
        (cluster) => cluster.minAgeReached && cluster.hasSignsOfActivity,
      ).length

    this.oldAndInactiveClusters = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), [])
      .filter(
        (cluster) => cluster.minAgeReached && !cluster.hasSignsOfActivity,
      ).length

    this.clusterCount = orgs
      .map((org) => org.clusters)
      .reduce((acc, cluster) => acc.concat(cluster), []).length

    this.orgCount = orgs.length

    this.latestOrgs =
      orgs.length >= 5 ? orgs.slice(0, 5) : orgs.slice(0, orgs.length)

    this.latestUsers = latestUsers

    const nonBots = topLogins.filter(
      (login) => !login.email.includes('camundacloudbot') && login.logins_count,
    )
    this.top5Users =
      nonBots.length >= 5
        ? nonBots.slice(0, 5)
        : nonBots.slice(0, nonBots.length)

    const nonBotsNonCamundos = nonBots.filter(
      (login) => !login.email.includes('@camunda') && login.logins_count,
    )

    this.top5LoginsNonCamundos =
      nonBotsNonCamundos.length >= 5
        ? nonBotsNonCamundos.slice(0, 5)
        : nonBotsNonCamundos.slice(0, nonBotsNonCamundos.length)

    this.m2mTokenRequestCount = m2mTokenRequests.reduce(
      (acc, cluster) => acc + cluster.count,
      0,
    )

    this.massiveM2mTokenRequester = m2mTokenRequests
      .filter((tokenRequest) => tokenRequest.count > 9)
      .map((tokenRequest) => {
        const organization = orgs.find(
          (org) =>
            org.clusters !== undefined &&
            org.clusters.some(
              (cluster) => cluster.uuid === tokenRequest.clusterId,
            ),
        )
        if (organization) {
          tokenRequest.orgId = organization.uuid
        }
        return tokenRequest
      })
      .filter((tokenRequest) => tokenRequest.orgId)

    const uniqueEmailsFromLogins = latestLogins
      .map((full) => full.userName)
      .filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    this.latestLogins = uniqueEmailsFromLogins.map((unique) => {
      return { username: unique, count: 0 }
    })
    latestLogins.forEach((element) => {
      this.latestLogins.find((toFind) => toFind.username === element.userName)!
        .count++
    })
  }
}
