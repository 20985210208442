import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToConfig,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { Features } from '../../../../../commons/Features'
import { SalesPlanRestService } from '../../../app/service/salesplan.rest.service'
import { SalesPlansResolverService } from '../../resolver/salesplans.resolver'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { NotificationService } from '../../service/notification.service'

enum NonConstSalesPlanType {
  PAID = 'paid',
  PAID_CC = 'paid-cc',
  ENTERPRISE = 'enterprise',
  TRIAL = 'trial',
  FREE = 'free',
  FREE_TP_PAID_REQUEST = 'free-to-paid-request',
  IN_NEGOTIATION = 'in-negotiation',
  INTERNAL = 'internal',
}
@Component({
  selector: 'list-sales-plans',
  styleUrls: ['./list-sales-plans.component.scss'],
  templateUrl: './list-sales-plans.component.html',
})
export class ListSalesPlansComponent implements OnInit {
  @ViewChild('salesPlanModal', { read: ElementRef })
  public modalRef: ElementRef
  public salesPlanModalConfirmLabel: string

  public createHandler: () => void
  public salesPlanEntities = []
  public salesPlanColumns = [
    {
      name: 'Name',
      width: '1fr',
    },
    { name: '', width: '35px' },
  ]

  public salesPlans: SalesPlanDto[]
  public activatedFeatures: Features
  public salesPlanModalData: any = {
    name: '',
    clusterPlanTypesToConfig: {},
  }
  public sortedClusterPlanUuids: string[]
  public salesPlanTypes = Object.values(NonConstSalesPlanType)

  constructor(
    private route: ActivatedRoute,
    private salesPlanRestService: SalesPlanRestService,
    private router: Router,
    private salesPlanResolverService: SalesPlansResolverService,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    this.salesPlans = this.route.snapshot.data.salesPlans
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures

    if (
      !(
        this.activatedFeatures.salesPlan.modify ||
        this.activatedFeatures.salesPlan.create
      )
    ) {
      this.salesPlanModalConfirmLabel = 'Close'
    } else {
      if (
        this.salesPlanModalData.uuid &&
        this.activatedFeatures.salesPlan.modify
      ) {
        this.salesPlanModalConfirmLabel = 'Modify'
      }
      if (
        !this.salesPlanModalData.uuid &&
        this.activatedFeatures.salesPlan.create
      ) {
        this.salesPlanModalConfirmLabel = 'Create'
      }
    }

    this.salesPlanEntities = this.salesPlans.map((salesPlan) => {
      let options = []

      let viewOrEditOption = {
        label: 'Modify',
        handler: () => {
          this.createOrModifySalesPlan(salesPlan.uuid)
        },
      }

      if (!this.activatedFeatures.salesPlan.modify) {
        viewOrEditOption.label = 'View'
      }

      options.push(viewOrEditOption)

      options.push({
        label: 'Delete',
        isDangerous: true,
        isDisabled: !this.activatedFeatures.salesPlan.delete,
        handler: () => {
          this.deleteSalesPlan(salesPlan)
        },
      })

      return {
        data: [
          {
            type: 'text',
            content: salesPlan.name,
          },
          {
            type: 'contextMenu',
            options: [
              {
                options,
              },
            ],
          },
        ],
        meta: salesPlan.uuid,
        onPress: () => this.createOrModifySalesPlan(salesPlan.uuid),
      }
    })

    this.createHandler = () => {
      this.createOrModifySalesPlan()
    }
  }

  public createOrModifySalesPlan(uuid?: string) {
    this.salesPlanRestService
      .getClusterPlanTypes()
      .subscribe((clusterPlanTypes) => {
        const clusterPlanConfiguration: ClusterPlanTypesToConfig = {}

        clusterPlanTypes.forEach((clusterPlanType) => {
          clusterPlanConfiguration[clusterPlanType.uuid] = {
            maxReserved: 0,
            minReserved: 0,
            price: '$0',
          }
        })

        let salesPlan: {
          name: string
          clusterPlanTypesToConfig: any
          salesPlanType?: string
          internal?: boolean
        } = {
          name: '',
          clusterPlanTypesToConfig: {},
          salesPlanType: NonConstSalesPlanType.PAID,
        }

        if (uuid) {
          salesPlan = this.salesPlans.find((sPlan) => sPlan.uuid === uuid)!
        }

        ;(salesPlan as any).clusterPlanTypeIdsToPlanType = {}

        clusterPlanTypes.forEach((clusterPlanType) => {
          if (
            !salesPlan.clusterPlanTypesToConfig ||
            salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] ===
              undefined
          ) {
            salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] = {
              maxReserved: 0,
              minReserved: 0,
            }
          }

          ;(salesPlan as any).clusterPlanTypeIdsToPlanType[
            clusterPlanType.uuid
          ] = clusterPlanType
        })

        Object.keys(salesPlan.clusterPlanTypesToConfig).forEach((configKey) => {
          if (
            !clusterPlanTypes.find((clusterPlanType) => {
              return clusterPlanType.uuid === configKey
            })
          ) {
            delete salesPlan.clusterPlanTypesToConfig[configKey]
          }
        })

        this.salesPlanModalData = salesPlan
        this.salesPlanModalData.internal = salesPlan.internal
        this.salesPlanModalData.type = salesPlan.salesPlanType

        this.sortedClusterPlanUuids = this.getSortedClusterPlansUuids(
          this.salesPlanModalData.clusterPlanTypesToConfig,
          this.salesPlanModalData.clusterPlanTypeIdsToPlanType,
        )

        this.ngZone.run(() => {
          ;(this.modalRef.nativeElement as CmModal).open().then((result) => {
            /* 
        you might wonder why we check for the permissions here - reason is:

        in view-only mode the cancel-button-result IS ACTUALLY confirm
        because there is no such things as a dialog without a confirm button
        in common-ui
        */
            if (result === 'confirm') {
              if (
                this.salesPlanModalData.uuid &&
                this.activatedFeatures.salesPlan.modify
              ) {
                this.notificationService.enqueueNotification({
                  headline: `Changing the Salesplan ${this.salesPlanModalData.name}`,
                  appearance: 'info',
                  description:
                    'this might take some time as all affected orgs are going to get touch. please wait',
                })
                this.salesPlanRestService
                  .modifySalesPlan(
                    this.salesPlanModalData.uuid,
                    this.salesPlanModalData.name,
                    this.salesPlanModalData.clusterPlanTypesToConfig,
                    this.salesPlanModalData.version,
                  )
                  .subscribe((_) => {
                    this.notificationService.enqueueNotification({
                      headline: `Changes Salesplan ${this.salesPlanModalData.name}`,
                      appearance: 'success',
                    })
                    this.salesPlanResolverService.refresh()
                    this.router.navigate(['/salesplans'])
                  })
              } else if (
                !this.salesPlanModalData.uuid &&
                this.activatedFeatures.salesPlan.create
              ) {
                this.salesPlanRestService
                  .createSalesPlan(
                    this.salesPlanModalData.name,
                    this.salesPlanModalData.type,
                    this.salesPlanModalData.clusterPlanTypesToConfig,
                    this.salesPlanModalData.version,
                  )
                  .subscribe((_) => {
                    this.notificationService.enqueueNotification({
                      headline: `Created Salesplan ${this.salesPlanModalData.name}`,
                      appearance: 'success',
                    })
                    this.salesPlanResolverService.refresh()
                    this.router.navigate(['/salesplans'])
                  })
              }
            }
          })
        })
      })
  }

  public deleteSalesPlan(salesplan: SalesPlanDto) {
    this.ngZone.run(() => {
      this.modalService.openModal({
        title: `Are you sure you want to burn ${salesplan.name}?`,
        body:
          'this is very rarely needed, please at least tripple check with people smarter than you',
        confirmButton: {
          type: 'danger',
          text: 'sudo',
          action: () => {
            this.salesPlanRestService
              .deleteSalesPlan(salesplan.uuid)
              .subscribe(() => {
                this.notificationService.enqueueNotification({
                  headline: `Deleted Salesplan ${salesplan.name}`,
                  appearance: 'success',
                })
                this.salesPlanResolverService.refresh()
                this.router.navigate(['/salesplans'])
              })
          },
        },
        cancelButton: {
          type: 'primary',
          text: 'haha no, thx',
          action: () => {},
        },
      })
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public getSortedClusterPlansUuids(
    cptToConfig: ClusterPlanTypesToConfig,
    cptIdsToPlanType: { [key: string]: ClusterPlanTypeDto },
  ) {
    return Object.keys(cptToConfig)
      .map((cpttc) => {
        return {
          uuid: cpttc,
          price: cptToConfig[cpttc].price,
          clusterPlanType: cptIdsToPlanType[cpttc],
        }
      })
      .sort((a, b) => {
        if (!a.price && b.price) {
          return 1
        }
        if (!b.price && a.price) {
          return -1
        }
        if (a.price && b.price) {
          const priceCompare = a.price.localeCompare(b.price)
          if (priceCompare !== 0) {
            return priceCompare
          }
        }
        const nameCompare = a.clusterPlanType.name.localeCompare(
          b.clusterPlanType.name,
        )
        if (nameCompare !== 0) {
          return nameCompare
        }
        return a.clusterPlanType.k8sContext.name.localeCompare(
          b.clusterPlanType.k8sContext.name,
        )
      })
      .map((full) => full.uuid)
  }
}
