export const SHA_VISIBLE = 'accounts:gitSha'
export const READ_ALL = 'accounts:admin:readAll'
export const CREATE_ORG = 'accounts:admin:createOrg'
export const CHANGE_ORG_NAME = 'accounts:admin:changeOrgName'
export const DELETE_ORG = 'accounts:admin:deleteOrg'
export const RESET_TOKEN_VERIFICATION = 'accounts:admin:resetToken'
export const DELETE_USER = 'accounts:admin:deleteUser'
export const DELETE_NOT_ACTIVATED_USER = 'accounts:admin:deleteNotActivateUser'
export const ADD_TO_ORG = 'accounts:admin:addToOrg'
export const REMOVE_FROM_ORG = 'accounts:admin:removeFromOrg'
export const CHANGE_ROLE = 'accounts:admin:changeRole'
export const EXTEND_TRIAL = 'accounts:admin:extendTrial'
export const CONSOLE_DELETE_CLUSTERS = 'console:clusters:deleteAllClusters'
export const CLUSTERS_CAN_BOOST = 'console:cluster:canBoost'
export const SALES_PLAN_CREATE = 'accounts:salesPlan:create'
export const SALES_PLAN_MODIFY = 'accounts:salesPlan:modify'
export const SALES_PLAN_FORCE_MODIFY = 'accounts:salesPlan:forceModify'
export const SALES_PLAN_SWITCH = 'accounts:salesPlan:switch'
export const SALES_PLAN_DELETE = 'accounts:salesPlan:delete'
export const CLUSTER_PLAN_SEE_ALL = 'console:clusterPlan:seeAll'
export const READ_AUDIT = 'audit:read'
export const SUPPORT_AGENT_REMOVE_AGENTS_FROM_ORG =
  'accounts:support:agent:removeAgentsFromOrgs'
export const SUPPORT_AGENT_SEE_ORG_MEMBERSHIPS =
  'accounts:support:agent:seeOrgMemberships'
export const SUPPORT_AGENT_ADD_TO_ORG = 'accounts:support:agent:addToOrg'
export const MODIFY_CLUSTER_PLAN_TYPE_RESERVATIONS =
  'accounts:clusterplantype:reservations:modify'
export const M2M_SALESPLAN_GET = 'accounts:m2m:salesplan:get'
export const M2M_SALESPLAN_SWITCH = 'accounts:m2m:salesplan:switch'

export const ENTERPRISE_SALESPLAN_CREATE =
  'accounts:enterprise:salesplan:create'
export const ENTERPRISE_SALESPLAN_MODIFY =
  'accounts:enterprise:salesplan:modify'
export const ENTERPRISE_SALESPLAN_DELETE =
  'accounts:enterprise:salesplan:delete'
export const ENTERPRISE_ORG_CONVERT = 'accounts:enterprise:org:convert'

export const EXTERNAL_M2M_GET_ORG_MEMBERS = 'accounts:m2m:org:members:get'

export interface Features {
  gitSha: boolean
  admin: {
    readAll: boolean
    createOrg: boolean
    changeOrgName: boolean
    deleteOrg: boolean
    resetTokenVerification: boolean
    deleteUser: boolean
    deleteNotActivatedUser: boolean
    addToOrg: boolean
    removeFromOrg: boolean
    changeRole: boolean
    deleteClusters: boolean
    boostClusters: boolean
    extendTrial: boolean
    searchForOrgs: boolean
    readAudit: boolean
    modifyClusterPlanTypeReservations: boolean
  }
  salesPlan: {
    create: boolean
    modify: boolean
    forceModify: boolean
    switch: boolean
    delete: boolean
  }
  enterprise: {
    salesPlan: {
      create: boolean
      modify: boolean
      delete: boolean
    }
    org: {
      convert: boolean
    }
  }
  supportAgent: {
    removeAgentsFromOrg: boolean
    seeOrgMemberships: boolean
    addAgentsToOrg: boolean
  }
  m2m: {
    salesplan: {
      get: boolean
      switch: boolean
    }
  }
  externalM2m: {
    org: {
      members: {
        get: boolean
      }
    }
  }
}

export function createEmptyFeatures(): Features {
  return {
    gitSha: false,
    admin: {
      readAll: false,
      createOrg: false,
      changeOrgName: false,
      deleteOrg: false,
      resetTokenVerification: false,
      deleteUser: false,
      deleteNotActivatedUser: false,
      addToOrg: false,
      removeFromOrg: false,
      changeRole: false,
      deleteClusters: false,
      boostClusters: false,
      extendTrial: false,
      searchForOrgs: false,
      readAudit: false,
      modifyClusterPlanTypeReservations: false,
    },
    salesPlan: {
      create: false,
      modify: false,
      forceModify: false,
      switch: false,
      delete: false,
    },
    enterprise: {
      salesPlan: {
        create: false,
        modify: false,
        delete: false,
      },
      org: { convert: false },
    },
    supportAgent: {
      removeAgentsFromOrg: false,
      seeOrgMemberships: false,
      addAgentsToOrg: false,
    },
    m2m: {
      salesplan: {
        get: false,
        switch: false,
      },
    },
    externalM2m: {
      org: {
        members: {
          get: false,
        },
      },
    },
  }
}
export function userFeaturesFromPermissions(permissions: string[]) {
  const newFeatures: Features = {
    gitSha: permissions.includes(SHA_VISIBLE),
    admin: {
      readAll: permissions.includes(READ_ALL),
      createOrg: permissions.includes(CREATE_ORG),
      changeOrgName: permissions.includes(CHANGE_ORG_NAME),
      deleteOrg: permissions.includes(DELETE_ORG),
      resetTokenVerification: permissions.includes(RESET_TOKEN_VERIFICATION),
      deleteUser: permissions.includes(DELETE_USER),
      deleteNotActivatedUser: permissions.includes(DELETE_NOT_ACTIVATED_USER),
      addToOrg: permissions.includes(ADD_TO_ORG),
      removeFromOrg: permissions.includes(REMOVE_FROM_ORG),
      changeRole: permissions.includes(CHANGE_ROLE),
      deleteClusters: permissions.includes(CONSOLE_DELETE_CLUSTERS),
      boostClusters: permissions.includes(CLUSTERS_CAN_BOOST),
      extendTrial: permissions.includes(EXTEND_TRIAL),
      searchForOrgs: permissions.includes(CLUSTER_PLAN_SEE_ALL),
      readAudit: permissions.includes(READ_AUDIT),
      modifyClusterPlanTypeReservations: permissions.includes(
        MODIFY_CLUSTER_PLAN_TYPE_RESERVATIONS,
      ),
    },
    salesPlan: {
      create: permissions.includes(SALES_PLAN_CREATE),
      modify: permissions.includes(SALES_PLAN_MODIFY),
      forceModify: permissions.includes(SALES_PLAN_FORCE_MODIFY),
      switch: permissions.includes(SALES_PLAN_SWITCH),
      delete: permissions.includes(SALES_PLAN_DELETE),
    },
    enterprise: {
      salesPlan: {
        create: permissions.includes(ENTERPRISE_SALESPLAN_CREATE),
        modify: permissions.includes(ENTERPRISE_SALESPLAN_MODIFY),
        delete: permissions.includes(ENTERPRISE_SALESPLAN_DELETE),
      },
      org: { convert: permissions.includes(ENTERPRISE_ORG_CONVERT) },
    },
    supportAgent: {
      removeAgentsFromOrg: permissions.includes(
        SUPPORT_AGENT_REMOVE_AGENTS_FROM_ORG,
      ),
      seeOrgMemberships: permissions.includes(
        SUPPORT_AGENT_SEE_ORG_MEMBERSHIPS,
      ),
      addAgentsToOrg: permissions.includes(SUPPORT_AGENT_ADD_TO_ORG),
    },
    m2m: {
      salesplan: {
        get: permissions.includes(M2M_SALESPLAN_GET),
        switch: permissions.includes(M2M_SALESPLAN_SWITCH),
      },
    },
    externalM2m: {
      org: {
        members: {
          get: permissions.includes(EXTERNAL_M2M_GET_ORG_MEMBERS),
        },
      },
    },
  }
  return newFeatures
}
