import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { M2MTokenRequests } from '../../../../commons/M2MTokenRequests.dto'
import { AugmentedOrgsResolverService } from './augmented-orgs.resolver'
const RANGE_IN_HOURS = 24

@Injectable({
  providedIn: 'root',
})
export class M2mTokenRequestsResolverService
  implements Resolve<M2MTokenRequests[]> {
  constructor(
    private augmentedOrgResolverService: AugmentedOrgsResolverService,
  ) {}
  public resolve(): Observable<M2MTokenRequests[]> {
    return this.augmentedOrgResolverService.getAllTokenRequests()
  }
}
