import { DatePipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { CommonUiModule } from '@camunda-cloud/common-ui-angular'
import { CookieService } from 'ngx-cookie-service'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import * as config from '../../auth.config.json'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { InterceptorService } from './auth/interceptor.service'
import { AcceptInviteComponent } from './components/accept-invite/accept-invite.component'
import { AddUserComponent } from './components/add-user/add-user.component'
import { AuditComponent } from './components/audit/audit.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { EmailVerificationComponent } from './components/email-verification/email-verification.component'
import { InvitesComponent } from './components/invites/invites.component'
import { ListAllUsersComponent } from './components/list-all-users/list-all-users.component'
import { ListInvitesComponent } from './components/list-invites/list-invites.component'
import { ListOrganizationsComponent } from './components/list-organizations/list-organizations.component'
import { ListSalesPlansComponent } from './components/list-sales-plans/list-sales-plans.component'
import { ModalComponent } from './components/modal-component/modal.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { OrganizationComponent } from './components/organization/organization.component'
import { RedirectComponent } from './components/redirect/redirect.component'
import { EnterpriseSalesplanComponent } from './components/sales-plan/enterprise-salesplan/enterprise-salesplan.component'
import { ListEnterprisePlansComponent } from './components/sales-plan/list-enterprise-plans/list-enterprise-plans.component'
import { ListSalesplansComponent } from './components/sales-plan/list-salesplans/list-salesplans.component'
import { SalesplanComponent } from './components/sales-plan/salesplan/salesplan.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { ListItemPricesComponent } from './components/subscription-management/list-item-prices/list-item-prices.component'
import { ProductItemsComponent } from './components/subscription-management/product-items/product-items.component'
import { SupportAgentComponent } from './components/support-agent/support-agents.component'
import { UsersComponent } from './components/users/users.component'
import { NavbarComponent } from './navbar/navbar.component'
import { OrganizationDetailsPage } from './pages/organization-details/organization-details.page'
import { OverviewPage } from './pages/overview/overview.page'
import { SalesPlansPage } from './pages/salesplans/salesplans.page'
import { UsersPage } from './pages/users/users.page'
import { ProfileComponent } from './profile/profile.component'
import { RootComponent } from './root.component'
import { CacheInterceptorService } from './service/cacheInterceptor.service'

@NgModule({
  bootstrap: [RootComponent],
  declarations: [
    RootComponent,
    AppComponent,
    NavbarComponent,
    ProfileComponent,
    ListOrganizationsComponent,
    AddUserComponent,
    DashboardComponent,
    ListAllUsersComponent,
    SignUpComponent,
    NotFoundComponent,
    ListSalesPlansComponent,
    UsersComponent,
    OrganizationComponent,
    AcceptInviteComponent,
    SupportAgentComponent,
    UsersPage,
    AuditComponent,
    OverviewPage,
    OrganizationDetailsPage,
    ModalComponent,
    EmailVerificationComponent,
    RedirectComponent,
    InvitesComponent,
    ListItemPricesComponent,
    ListSalesplansComponent,
    SalesplanComponent,
    SalesPlansPage,
    ProductItemsComponent,
    ListInvitesComponent,
    ListEnterprisePlansComponent,
    EnterpriseSalesplanComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    CommonUiModule,
    AuthModule.forRoot({
      domain: config.domain,
      clientId: config.clientId,
      redirectUri: window.location.origin,
      audience: environment.camundaCloudAudience,
      leeway: 10,
      httpInterceptor: {
        allowedList: [
          `${environment.backendUrl}/api/*`,
          `${environment.consoleBackendUrl}/api/*`,
          `${environment.consoleBackendUrl}/external/*`,
          `${environment.auditBackendUrl}/api/*`,
        ],
      },
    }),
  ],
  providers: [
    CookieService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppModule {}
