<form [formGroup]="filterForm">
  <input
    class="input-search"
    placeholder="Search users"
    formControlName="search"
  />

  <cm-filter
    [filters]="filters"
    (cmFilterSelected)="filterSelected($event)"
  ></cm-filter>
</form>

<users
  [users$]="filteredUsers$"
  [allOrgs]="allOrgs"
  [allRoles]="allRoles"
  [memberListLoading]="loading"
  (update)="find()"
></users>
