<cm-entity-list
  [enableCreateButton]="mayCreateInvite && !inviteListLoading"
  headline="Pending Invitations"
  createButtonLabel="Invite"
  [createHandler]="inviteHandler"
  [columns]="inviteListColums"
  [entities]="inviteListEntites"
  [loading]="inviteListLoading"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">No Pending Invites</div>
</cm-entity-list>
<cm-modal
  #inviteModal
  headline="Invite new User"
  cancelLabel="Cancel"
  confirmLabel="Invite"
  [confirmDisabled]="
    !inviteEmailFormControl.valid || !inviteRoleFormControl.valid
  "
>
  <cm-text> Send out a new invite to email </cm-text>
  <input
    type="text"
    placeholder="email address"
    [formControl]="inviteEmailFormControl"
  /><br />
  <cm-text>for role</cm-text>
  <div class="checkboxes">
    <cm-checkbox
      *ngFor="let role of availableRoles"
      label="{{ role }}"
      [checked]="selectedRoles.includes(role)"
      (cmInput)="selectRoleForNewInvite(role)"
    ></cm-checkbox>
  </div>
</cm-modal>
