<h1>Audit Logs</h1>

<form [formGroup]="filterForm">
  <input
    class="input-search"
    placeholder="Organization ID"
    formControlName="search"
  />
</form>

<cm-card>
  <h3 slot="header">Logs</h3>

  <div>
    <div class="loader" *ngIf="loading">
      <cm-loader></cm-loader>
    </div>

    <div class="auditTable" *ngIf="audits.length">
      <div class="header">Timestamp</div>
      <div class="header">Event</div>
      <div class="header">Service</div>

      <ng-container class="row" *ngFor="let audit of audits">
        <div class="cell">
          <cm-text>{{ audit.timestamp | date: 'short' }}</cm-text>
        </div>
        <div class="cell" (click)="audit.show = !audit.show">
          <cm-text>
            <strong>{{ audit.audit }}</strong>
          </cm-text>
          <ng-container *ngIf="audit.show">
            <br />
            <br />
            <cm-datalist label-width="130px">
              <cm-datalist-item label="Organization ID">
                <cm-text>{{ audit.orgId }}</cm-text>
              </cm-datalist-item>
              <cm-datalist-item label="Entity">
                <cm-text>{{ audit.entity }}</cm-text>
              </cm-datalist-item>
              <cm-datalist-item label="Entity ID">
                <cm-text>{{ audit.entityId }}</cm-text>
              </cm-datalist-item>
              <cm-datalist-item label="By User ID">
                <cm-text>{{ audit.userId }}</cm-text>
              </cm-datalist-item>

              <ng-container *ngIf="audit.entityAttribute">
                <cm-datalist-item label="Entity Attribute">
                  <cm-text>{{ audit.entityAttribute }}</cm-text>
                </cm-datalist-item>
                <cm-datalist-item label="Entity Attribute Change">
                  <cm-text
                    >{{ audit.entityAttributeValueFrom }} -->
                    {{ audit.entityAttributeValueTo }}
                  </cm-text>
                </cm-datalist-item>
              </ng-container>
            </cm-datalist>
          </ng-container>
        </div>
        <div class="cell">
          <cm-text>{{ audit.service }}</cm-text>
        </div>
      </ng-container>
    </div>

    <cm-text *ngIf="audits.length === 0">No Logs found.</cm-text>
  </div>
</cm-card>
