import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BpmnModelMetrics } from '@camunda-cloud/cloud-node-libs'
import { of } from 'rxjs'
import { catchError, first } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BpmnModelerRestService {
  constructor(private http: HttpClient) {}

  public getMetrics(orgId?: string) {
    const route = orgId ? `bpmnmodels/orgs/${orgId}/count` : `bpmnmodels/count`
    return this.http
      .get<BpmnModelMetrics>(
        `${environment.consoleBackendUrl}/api/admin/${route}`,
      )
      .pipe(first())
  }
}
