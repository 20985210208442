import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  private orgId: string
  private inviteToken: string
  private campaign: string
  private target: 'console'

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.orgId = this.route.snapshot.queryParams.organization
    this.inviteToken = this.route.snapshot.queryParams.invitation
    this.campaign = this.route.snapshot.queryParams.campaign

    this.target = this.route.snapshot.data.target
    switch (this.target) {
      case 'console':
        if (this.orgId && this.inviteToken) {
          window.location.href = `${environment.consoleBackendUrl}/onboarding/organization/${this.orgId}/invitation/${this.inviteToken}`
        } else if (this.campaign) {
          window.location.href = `${environment.consoleBackendUrl}?campaign=${this.campaign}`
        } else {
          window.location.href = `${environment.consoleBackendUrl}`
        }
        break
    }
  }
}
