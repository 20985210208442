import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { NotificationService } from '../service/notification.service'

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private excludedRoutes: string[] = ['/verification', '/signup']

  constructor(private notificationService: NotificationService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.excludeRoute(req.url)) {
      return next.handle(req)
    }
    return next.handle(req).pipe(
      catchError((err) => {
        this.notificationService.enqueueNotification({
          headline: `${req.method} on ${req.url} bricked`,
          description: `${err.status} - ${err.error}`,
          appearance: 'error',
        })
        return throwError(err)
      }),
    )
  }

  private excludeRoute(url: string): boolean {
    for (const route of this.excludedRoutes) {
      if (url.search(route) >= 0) {
        return true
      }
    }
    return false
  }
}
