import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'
import { VerificationDto } from '../../../../../commons/Verification.dto'
import { VerifyService } from '../../service/verify.service'

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  public token: string
  public userId: string | undefined
  public verification: VerificationDto
  public loading = false

  constructor(
    private verifyService: VerifyService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.params.token
    this.userId = this.route.snapshot.params.userId
    this.loading = true
    if (this.token === 'uxGottFranz' && !environment.production) {
      this.verification = {
        tokenValid: true,
        userVerified: false,
      }
    } else {
      this.checkToken()
    }
  }

  public checkToken() {
    this.verifyService
      .verifyToken(this.token, this.userId)
      .subscribe((verification) => {
        this.verification = verification
        if (this.verification.tokenValid && this.verification.userVerified) {
          this.forwardToLogin()
        }
        if (this.verification.tokenValid && !this.verification.userVerified) {
          this.verifyService.activateUser(this.token).subscribe((_) => {
            this.forwardToLogin()
          })
        }
        if (!this.verification.tokenValid) {
          this.loading = false
        }
      })
  }

  // eslint-disable-next-line class-methods-use-this
  public forwardToLogin(event?) {
    if (event) {
      event.preventDefault()
    }
    const url = this.verification.email
      ? `${environment.consoleBackendUrl}?email=${encodeURIComponent(
          this.verification.email,
        )}`
      : `${environment.consoleBackendUrl}`
    window.location.href = url
  }
}
