import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { SalesPlanDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { SalesPlanRestService } from '../service/salesplan.rest.service'

@Injectable({
  providedIn: 'root',
})
export class SalesPlansResolverService implements Resolve<SalesPlanDto[]> {
  private salesplans: Observable<SalesPlanDto[]>
  constructor(private salesPlanService: SalesPlanRestService) {
    this.refresh()
  }
  public resolve(): Observable<SalesPlanDto[]> {
    return this.salesplans
  }

  public refresh() {
    this.salesplans = this.salesPlanService.getSalesPlans().pipe(shareReplay())
  }
}
