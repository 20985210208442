<div class="signUpPage">
  <div class="container">
    <div class="marketing">
      <a href="https://camunda.com" class="logo"></a>

      <div class="text-container">
        <span class="small-text"
          >Enterprise Scale SaaS for Process Automation</span
        >
        <div class="grid">
          <span class="big-text">Start your 30-day free trial</span>

          <div class="checkmark">Get immediate access to Camunda Cloud</div>
          <div class="checkmark">
            Start to design, automate and monitor your processes instantly
          </div>
          <div class="checkmark">
            Includes horizontally scalable BPMN workflow engine, collaborative
            modeling, operations and analytics
          </div>
          <div class="checkmark">
            Seamlessly integrate with your programming languages and tools
          </div>
        </div>
      </div>
    </div>

    <div class="form">
      <div class="formContainer" *ngIf="status === 'BEFORE_SUBMIT'">
        <cm-text
          >Already have an account?
          <cm-link label="Log in" href="{{ consoleUrl }}"></cm-link
        ></cm-text>
        <h1>Create Your Camunda Cloud Account</h1>
        <form
          ngNativeValidate
          (submit)="attemptCreateUser()"
          [ngClass]="{ hasCawemoInfo: showCawemoInfo }"
        >
          <div class="formInput firstname">
            <input
              type="text"
              id="first-name"
              placeholder=" "
              required
              [formControl]="firstNameFormControl"
            />

            <label for="first-name">First Name</label>
          </div>
          <div class="formInput lastname">
            <input
              type="text"
              id="last-name"
              placeholder=" "
              required
              [formControl]="lastNameFormControl"
            />
            <label for="last-name">Last Name</label>
          </div>

          <div class="formInput email" *ngIf="!userEmail">
            <input
              #emailInput
              type="email"
              id="email"
              placeholder=" "
              required
              [formControl]="emailFormControl"
              oninput="this.setCustomValidity('')"
            />
            <label for="email">Email</label>
          </div>

          <div class="input-group email" *ngIf="userEmail">
            <div class="input-group__label-container">
              <label for="email">Email</label>
            </div>
            <cm-text id="email">{{ userEmail }}</cm-text>
          </div>

          <div class="formInput password">
            <input
              #passwordInput
              [type]="passwordInputType"
              id="password"
              placeholder=" "
              [formControl]="pwControl"
            />
            <label for="password">Password (min 12 characters)</label>

            <cm-icon
              class="pwIcon"
              [icon]="passwordEyeIconSource"
              (click)="togglePasswordInputType()"
            ></cm-icon>
          </div>

          <div class="formInput company">
            <input
              type="text"
              id="company-name"
              placeholder=" "
              required
              [formControl]="companyFormControl"
            />
            <label for="company-name">Company Name</label>
          </div>

          <div class="formInput jobtitle">
            <select
              id="jobFunction"
              required
              (change)="changeJobFunction($event.target.value)"
            >
              <option value="" disabled selected>Job Function</option>
              <option value="Software Developer">Software Developer</option>
              <option value="Enterprise Architect">Enterprise Architect</option>
              <option value="Business Analyst">Business Analyst</option>
              <option value="Automation Lead">Automation Lead</option>
              <option value="Student">Student</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div class="formInput usecase">
            <select
              id="why-camunda"
              required
              (change)="changeMotivation($event.target.value)"
            >
              <option value="" disabled selected>
                What are you interested in
              </option>
              <option value="Process Automation">Process Automation</option>
              <option value="Process Modeling / Documentation">
                Process Modeling / Documentation
              </option>
              <option value="Just generally interested">
                Just generally interested
              </option>
              <option value="Other">Other</option>
            </select>
            <span class="allRequiredNotice">All fields are required</span>
          </div>

          <div class="cawemoInfo" *ngIf="showCawemoInfo">
            Camunda Cloud is for Process Automation. If you would like to
            document processes, please check out
            <cm-link
              href="https://cawemo.com/?utm_source=cloudsignup&utm_content=motivation"
              label="Cawemo"
            ></cm-link
            >, our free online editor for BPMN.
          </div>

          <div class="newsletter">
            <cm-checkbox
              label="I would like to receive regular updates on Camunda
          products and offers."
            ></cm-checkbox>
            <div class="checkboxLabelExtension">
              I may at any time unsubscribe from this service.
            </div>
          </div>

          <div class="signup">
            <div class="form-actions form-center">
              <button
                type="submit"
                [disabled]="status !== 'BEFORE_SUBMIT'"
                class="signUpButton"
              >
                Sign up
              </button>
            </div>

            <cm-text>or sign up with</cm-text>
            <div class="social-buttons">
              <button (click)="loginWithSocialProvider('google-oauth2')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    fill="#FBBB00"
                    d="M5.5459375,11.669 L4.989,13.748125 L2.95340625,13.7911875 C2.3450625,12.6628437 2,11.371875 2,10 C2,8.67340625 2.322625,7.42240625 2.8945,6.320875 L2.8949375,6.320875 L4.7071875,6.653125 L5.5010625,8.4545 C5.33490625,8.93890625 5.24434375,9.45890625 5.24434375,10 C5.24440625,10.58725 5.35078125,11.1499062 5.5459375,11.669 Z"
                  />
                  <path
                    fill-rule="evenodd"
                    fill="#518EF8"
                    d="M17.8602188,8.5055 C17.9520938,8.9894375 18,9.48921875 18,10 C18,10.57275 17.9397813,11.1314375 17.8250625,11.6703437 C17.435625,13.5041875 16.4180313,15.1055 15.008375,16.2386875 L15.0079375,16.23825 L12.7253125,16.1217812 L12.40225,14.1050625 C13.337625,13.5565 14.068625,12.6980312 14.4536875,11.6703437 L10.175875,11.6703437 L10.175875,8.5055 L14.5160938,8.5055 L17.8602188,8.5055 L17.8602188,8.5055 Z"
                  />
                  <path
                    fill-rule="evenodd"
                    fill="#28B446"
                    d="M15.0079062,16.23825 L15.0083437,16.2386875 C13.637375,17.3406562 11.8958125,18 10,18 C6.95340625,18 4.304625,16.2971562 2.95340625,13.7912187 L5.5459375,11.6690312 C6.22153125,13.4720937 7.960875,14.755625 10,14.755625 C10.8764687,14.755625 11.6975937,14.5186875 12.4021875,14.1050625 L15.0079062,16.23825 Z"
                  />
                  <path
                    fill-rule="evenodd"
                    fill="#F14336"
                    d="M15.106375,3.84175 L12.5147187,5.9635 C11.7855,5.5076875 10.9235,5.244375 10,5.244375 C7.91471875,5.244375 6.14284375,6.58678125 5.50109375,8.4545 L2.8949375,6.320875 L2.8945,6.320875 C4.2259375,3.75384375 6.908125,2 10,2 C11.9410937,2 13.720875,2.6914375 15.106375,3.84175 Z"
                  />
                </svg>
                <span class="socialButtonText">Google</span>
              </button>
              <button (click)="loginWithSocialProvider('github')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <defs>
                    <path
                      id="github-a"
                      d="M8,0 C12.418278,0 16,3.581722 16,8 C16,11.6084066 13.6109968,14.6588316 10.3283852,15.6558801 L10.3297901,15.6665786 C10.3267845,15.6442043 10.3252812,15.6216538 10.3252901,15.5990786 C10.3317901,15.3225786 10.3422901,14.4090786 10.3422901,13.2770786 C10.3855798,12.6959664 10.1759957,12.1244398 9.76729011,11.7090786 C11.6537901,11.5025786 13.6327901,10.7840786 13.6327901,7.53157855 C13.6457969,6.69083559 13.3341921,5.87743968 12.7627901,5.26057855 C13.0209455,4.53006114 12.9907246,3.72858109 12.6782901,3.01957855 C12.6782901,3.01957855 11.9687901,2.79207855 10.3527901,3.88807855 C8.9652937,3.50841248 7.50128651,3.50841248 6.11379011,3.88807855 C4.49729011,2.79257855 3.78579011,3.01957855 3.78579011,3.01957855 C3.47374485,3.72875079 3.44405908,4.53026645 3.70279011,5.26057855 C3.13119479,5.87746175 2.81894542,6.69067084 2.83079011,7.53157855 C2.83079011,10.7755786 4.80729011,11.5025786 6.68679011,11.7155786 C6.37851499,12.0168488 6.1879108,12.4182701 6.14929011,12.8475786 L6.13329011,12.8565786 C5.71398348,13.0873072 5.22014614,13.141858 4.76058948,13.0082114 C4.30103281,12.8745648 3.91346786,12.5636878 3.68329011,12.1440786 C3.4088916,11.6687837 2.93042817,11.3467942 2.38679011,11.2715786 L2.36433461,11.2717961 C2.23689011,11.2747436 1.63434011,11.3131286 2.32779011,11.7860786 C2.77885532,12.0735359 3.11226047,12.5128149 3.26779011,13.0245786 C3.26779011,13.0245786 3.76429011,14.6710786 6.11779011,14.1585786 C6.12099011,14.7245786 6.12611011,15.1782586 6.12803011,15.4397466 L6.12879011,15.5985786 L6.12879011,15.5985786 C6.12881426,15.6206681 6.13172612,15.7554933 6.12891592,15.780608 C2.61304185,14.9373158 0,11.7738122 0,8 C0,3.581722 3.581722,0 8,0 Z"
                    />
                  </defs>
                  <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#FFF"
                      fill-rule="nonzero"
                    />
                    <use
                      fill="#000"
                      fill-rule="nonzero"
                      xlink:href="#github-a"
                    />
                  </g>
                </svg>
                <span class="socialButtonText">Github</span>
              </button>
            </div>

            <cm-text>
              By signing up, you agree to our
              <cm-link
                href="https://camunda.com/legal/privacy/"
                label="Privacy Policy"
              ></cm-link>
              and
              <cm-link
                href="https://console.cloud.camunda.io/terms/"
                label="Terms of Use"
              ></cm-link
              >.
            </cm-text>
          </div>
        </form>
      </div>

      <div class="loaderContainer" *ngIf="status === 'LOADING'">
        <cm-loader></cm-loader>
      </div>

      <div
        class="successContainer"
        *ngIf="status === 'SUCCESS' || status === 'RESEND_EMAIL_VERIFICATION'"
      >
        <h1>Verify your email</h1>
        <cm-text>
          We sent a verification email to
          <strong>{{ emailFormControl.value }}</strong
          >. Click the link inside to get started.
        </cm-text>
        <br />
        <br />
        <cm-text>
          <cm-link
            *ngIf="status === 'SUCCESS'"
            label="Email did not arrive?"
            (click)="resendEmailVerification($event)"
          ></cm-link>
          <span *ngIf="status === 'RESEND_EMAIL_VERIFICATION'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              class="check-icon"
            >
              <polygon
                fill-rule="evenodd"
                fill="#10d070"
                points="5.743 14.314 1 9.57 2.897 7.673 5.743 10.519 13.103 3.16 15 5.057"
              />
            </svg>
            Email sent
          </span>
        </cm-text>
      </div>

      <div class="failureContainer" *ngIf="status === 'FAILURE'">
        <h1>Sign up failed</h1>
        <cm-text
          >Unfortunately we can not sign you up right now, if the problem
          continues please contact
          <cm-link
            href="mailto:cloudbeta@camunda.com"
            label="cloudbeta@camunda.com"
          ></cm-link
          >.
        </cm-text>
        <br />
        <br />
        <cm-button
          (cmPress)="backToSignup($event)"
          appearance="link"
          label="Back to Sign up"
        ></cm-button>
      </div>

      <div class="failureContainer" *ngIf="status === 'ENTERPRISE_CONNECTION'">
        <h1>SSO available</h1>
        <cm-text
          >A Single Sign On is configured for your e-mail domain so that you can
          log in directly with your e-mail address. You will now be redirected
          to the login.
        </cm-text>
      </div>

      <div class="failureContainer" *ngIf="status === 'FAILURE_INVITATION'">
        <h1>Joining the Organization has failed</h1>
        <cm-text>The invitation link is invalid.</cm-text>
        <br />
        <br />
        <cm-button
          (cmPress)="forwardToLogin($event)"
          appearance="link"
          label="Go to Log in"
        ></cm-button>
      </div>
    </div>
  </div>

  <span class="footer">
    <cm-button
      appearance="link"
      (cmPress)="openOsano()"
      label="Cookie Preferences"
    ></cm-button>
    ©2021 Camunda</span
  >
</div>

<div class="loading" *ngIf="status === 'LOADING'">
  <cm-loader></cm-loader>
</div>
