import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { SubscriptionConfig } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { ItemPrice } from '@camunda-cloud/subscription-management'
import { ConfirmationModalService } from '../../../service/confirmation-modal.service'
import { SubscriptionManagementRestService } from '../../../service/subscription-management.rest.service'

export interface InputFilters {
  subscriptionConfig: SubscriptionConfig
  typeFilter: 'addon' | 'plan'
  selection: boolean
  changeTs: number
}

export interface ItemPriceAssignment {
  subscriptionConfigId: string
  type: 'plan' | 'addon'
  itemPrice: ItemPrice
  operation: 'add' | 'remove'
}

@Component({
  selector: 'app-list-item-prices',
  templateUrl: './list-item-prices.component.html',
  styleUrls: ['./list-item-prices.component.scss'],
})
export class ListItemPricesComponent implements OnInit, OnChanges {
  @Input()
  inputFilters: InputFilters

  @Input()
  chargebeeSite: string

  @Output()
  assignment = new EventEmitter<ItemPriceAssignment>()

  status: 'ready' | 'processing' = 'ready'
  entityListHeadline: string = 'Item Prices'

  itemPrices: ItemPrice[]
  selectedItemPrice: ItemPrice

  @ViewChild('itemPriceDetails', { read: ElementRef })
  public itemPriceDetails: ElementRef

  itemPricesColumns = [
    {
      name: 'Name',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Type',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Metadata?',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Created',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Currency',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: 'Details', width: '100px' },
    { name: '', width: '35px' },
  ]
  itemPricesContent = []

  constructor(
    private subscriptionManagementService: SubscriptionManagementRestService,
    private modalService: ConfirmationModalService,
    private ngZone: NgZone,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFilters?.previousValue) {
      await this.getItemPrices()
    }
  }

  async ngOnInit() {
    if (this.inputFilters) {
      if (this.inputFilters.selection) {
        this.entityListHeadline = 'Add Item Price to Subscription'
      } else {
        if (this.inputFilters.typeFilter === 'plan') {
          this.entityListHeadline = 'Assigned Plan Item Price'
        } else {
          this.entityListHeadline = 'Assigned Addon Item Prices'
        }
      }
    }
    await this.getItemPrices()
  }

  public updateMetadata(itemPriceId: string, type: string, data: string) {
    this.status = 'processing'
    const itemPrice = this.itemPrices.find(
      (currentItem) => currentItem.id === itemPriceId,
    )
    if (!itemPrice) {
      this.status = 'ready'
      return
    }
    const request = {
      chargebeeSite: this.chargebeeSite,
      itemId: itemPrice.item_id,
      metadata: {
        type,
        data,
      },
    }
    this.subscriptionManagementService
      .updateItemMetadata(request)
      .subscribe((_response) => {
        this.getItemPrices()
      })
  }

  private assignItemPrice(operation: 'add' | 'remove', itemPrice: ItemPrice) {
    if (this.inputFilters && ['plan', 'addon'].includes(itemPrice.item_type)) {
      const type: 'plan' | 'addon' =
        itemPrice.item_type === 'plan' ? 'plan' : 'addon'
      const itemPriceAssignment: ItemPriceAssignment = {
        itemPrice,
        type,
        subscriptionConfigId: this.inputFilters.subscriptionConfig.id,
        operation,
      }
      this.assignment.emit(itemPriceAssignment)
    }
  }

  private async getItemPrices() {
    this.status = 'processing'
    if (!this.itemPrices) {
      this.itemPrices = await this.subscriptionManagementService
        .getItemPrices(this.chargebeeSite)
        .toPromise()
    }
    this.itemPricesContent = this.filterItemPrices().map((itemPriceItem) => {
      return this.entityListItem(itemPriceItem)
    })
    this.status = 'ready'
  }

  private filterItemPrices(): ItemPrice[] {
    let filteredItemPrices: ItemPrice[] = []

    if (this.inputFilters) {
      const addonItemPriceIds = this.inputFilters.subscriptionConfig.addons
        ? this.inputFilters.subscriptionConfig.addons.map(
            (addon) => addon.itemPriceId,
          )
        : []
      filteredItemPrices = this.itemPrices.filter((itemPriceItem) => {
        if (this.inputFilters.selection) {
          const itemPriceAlreadyAssigned = [
            ...addonItemPriceIds,
            this.inputFilters.subscriptionConfig.planItemPriceId,
          ].includes(itemPriceItem.id)
          let showAddon = true
          if (itemPriceItem.item_type === 'addon') {
            showAddon =
              itemPriceItem.metadata?.data && itemPriceItem.metadata?.type
                ? true
                : false
          }
          return !itemPriceAlreadyAssigned && showAddon
        } else {
          if (this.inputFilters.typeFilter === 'plan') {
            return (
              itemPriceItem.id ===
              this.inputFilters.subscriptionConfig.planItemPriceId
            )
          } else {
            return addonItemPriceIds.includes(itemPriceItem.id)
          }
        }
      })
    } else {
      filteredItemPrices = this.itemPrices
    }

    return filteredItemPrices
  }

  private entityListItem(itemPrice: ItemPrice) {
    return {
      onPress: this.inputFilters?.selection
        ? () => {
            this.assignItemPrice('add', itemPrice)
          }
        : null,
      data: [
        {
          type: 'text',
          content: itemPrice.name,
        },
        {
          type: 'text',
          content: itemPrice.item_type,
        },
        {
          type: 'text',
          content:
            itemPrice.metadata?.type && itemPrice.metadata?.data ? 'yes' : 'no',
        },
        {
          type: 'text',
          content: new Date(itemPrice.created_at * 1000).toISOString(),
        },
        {
          type: 'text',
          content: itemPrice.currency_code,
        },
        {
          type: 'button',
          label: 'Details',
          onPress: () => {
            this.ngZone.run(() => {
              this.selectedItemPrice = itemPrice
              ;(this.itemPriceDetails.nativeElement as CmModal).open()
            })
          },
        },
        {
          type: 'contextMenu',
          options: [
            {
              options: this.contextMenu(itemPrice),
            },
          ],
        },
      ],
    }
  }

  private contextMenu(itemPrice: ItemPrice) {
    if (this.inputFilters?.selection === true) {
      return []
    }

    if (this.inputFilters?.selection === false) {
      return [
        {
          label: `Remove Assignment`,
          isDangerous: true,
          handler: () => {
            this.assignItemPrice('remove', itemPrice)
          },
        },
      ]
    }

    const options: Array<{
      type: string
      data: string
    }> = [
      {
        type: 'fencing-metric',
        data: 'hardware-package-reservations',
      },
      {
        type: 'value-metric',
        data: 'process-instances',
      },
      {
        type: 'value-metric',
        data: 'task-users',
      },
    ]

    const contextMenu: any[] = []

    for (const option of options) {
      contextMenu.push({
        label: `Set as ${option.type}: ${option.data}`,
        isDangerous: true,
        handler: () =>
          this.ngZone.run(() => {
            this.status = 'processing'

            this.modalService.openModal({
              bodyIsRaw: true,
              title: 'Change Item and ItemPrice Metadata?',
              body: `Are you sure you want to change Metadata of this item price and corresponding item to <b>${option.type}: ${option.data}</b>?`,
              cancelButton: {
                text: 'No',
                action: () => {
                  this.status = 'ready'
                },
              },
              confirmButton: {
                text: 'I am sure',
                type: 'danger',
                action: () => {
                  this.updateMetadata(itemPrice.id, option.type, option.data)
                },
              },
            })
          }),
      })
    }

    return contextMenu
  }
}
