import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanTypeDto,
  OrganizationDto,
  SalesPlanDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { Features } from '../../../../../../commons/Features'
import { SalesPlansResolverService } from '../../../resolver/salesplans.resolver'
import { ConfirmationModalService } from '../../../service/confirmation-modal.service'
import { NotificationService } from '../../../service/notification.service'
import { SalesPlanRestService } from '../../../service/salesplan.rest.service'
import { ListSalesplansComponent } from '../list-salesplans/list-salesplans.component'

@Component({
  selector: 'list-enterprise-plans',
  templateUrl: './list-enterprise-plans.component.html',
  styleUrls: ['./list-enterprise-plans.component.scss'],
})
export class ListEnterprisePlansComponent implements OnInit {
  public activatedFeatures: Features
  public allOrgs: OrganizationDto[]
  salesPlans: SalesPlanDto[]
  clusterPlanTypes: ClusterPlanTypeDto[]
  newSalesPlanForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    version: new FormControl('', [Validators.required, Validators.min(0)]),
    type: new FormControl(false, [Validators.required]),
    internal: new FormControl(false, [Validators.required]),
  })

  salesPlanColumns = [
    {
      name: 'Name',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: 'Orgs', width: '1fr' },
    // {
    //   name: 'Version',
    //   width: '1fr',
    //   ellipsis: 'right',
    // },
    { name: '', width: '35px' },
  ]
  salesPlanContent = []
  status: 'ready' | 'processing' = 'ready'

  @ViewChild('createSalesPlanModal', { static: true, read: ElementRef })
  public createSalesPlanModal: ElementRef<CmModal>

  @ViewChild('assignedOrgsModal', { static: true, read: ElementRef })
  public assignedOrgsModal: ElementRef<CmModal>
  public assignedOrgs: OrganizationDto[]
  public assignedSalesPlan: SalesPlanDto

  public salesPlanListCreateHandler: () => void

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private ngZone: NgZone,
    private salesPlanService: SalesPlanRestService,
    private salesPlanResolverService: SalesPlansResolverService,
    private modalService: ConfirmationModalService,
  ) {}

  public ngOnInit(): void {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.allOrgs = this.route.snapshot.data.orgs
    this.salesPlans = this.route.snapshot.data.salesPlans.filter(
      (salesPlan) => salesPlan.salesPlanType === 'enterprise',
    )
    this.clusterPlanTypes = this.route.snapshot.data.allClusterPlanTypes
    this.updateEntityList()
    this.salesPlanListCreateHandler = () => this.openCreateSalesPlanModal()
  }

  public updateEntityList() {
    if (!this.salesPlans) {
      this.salesPlanContent = []
      return
    }
    this.salesPlanContent = this.salesPlans.map((salesPlan) => {
      const assignedOrgs = this.allOrgs.filter(
        (org) => org.organizationToSalesPlan?.salesPlan.uuid === salesPlan.uuid,
      )
      return {
        onPress: () => {
          this.ngZone.run(() => {
            this.navigateToSalesPlanDetails(salesPlan.uuid)
          })
        },
        data: [
          { type: 'text', content: salesPlan.name },
          // {
          //   type: 'text',
          //   content: salesPlan.version,
          // },
          {
            type: 'button',
            label: `${
              this.allOrgs.filter(
                (org) =>
                  org.organizationToSalesPlan?.salesPlan.uuid ===
                  salesPlan.uuid,
              ).length
            } Orgs`,
            onPress: () => {
              this.ngZone.run(() => {
                this.assignedSalesPlan = salesPlan
                this.assignedOrgs = assignedOrgs
                this.assignedOrgsModal.nativeElement.open()
              })
            },
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: this.contextMenu(salesPlan, assignedOrgs),
              },
            ],
          },
        ],
      }
    })
  }
  public openCreateSalesPlanModal() {
    this.status = 'processing'
    this.newSalesPlanForm.setValue({
      name: '',
      version: 0,
      type: 'enterprise',
      internal: false,
    })
    this.ngZone.run(() => {
      this.createSalesPlanModal.nativeElement.open().then((result) => {
        if (result === 'confirm') {
          const salesPlanDto = ListSalesplansComponent.createSalesPlanObject(
            this.clusterPlanTypes,
            this.newSalesPlanForm,
          )
          this.salesPlanService
            .createSalesPlan(
              salesPlanDto.name,
              SalesPlanType.ENTERPRISE,
              salesPlanDto.clusterPlanTypesToConfig,
              0,
            )
            .subscribe(
              (_) => {
                this.salesPlanResolverService.refresh()
                this.salesPlanResolverService
                  .resolve()
                  .subscribe((salesPlans) => {
                    this.salesPlans = salesPlans.filter(
                      (salesplan) => salesplan.salesPlanType === 'enterprise',
                    )
                    this.updateEntityList()
                    this.status = 'ready'
                  })
              },
              (_error) => {
                this.notification.enqueueNotification({
                  headline: 'unable to create a new enterprise plan',
                  appearance: 'error',
                  description: JSON.stringify(_error),
                })
                this.status = 'ready'
              },
            )
        } else {
          this.status = 'ready'
        }
      })
    })
  }
  public goToOrg(orgUuid: string) {
    this.router.navigate([`organizations/${orgUuid}`])
  }
  private navigateToSalesPlanDetails(salesPlanId: string) {
    this.router.navigate([`enterprise/${salesPlanId}`])
  }
  private contextMenu(
    salesPlan: SalesPlanDto,
    assignedOrgs: OrganizationDto[],
  ) {
    const contextMenu: any[] = []
    if (this.activatedFeatures.enterprise.salesPlan.delete) {
      contextMenu.push({
        label: 'Delete',
        isDisabled: assignedOrgs?.length > 0,
        isDangerous: true,
        handler: () => {
          this.ngZone.run(() => {
            this.modalService.openModal({
              title: 'Delete Enterprise Plan',
              body: `Are you sure you want to delete the Enterprise Plan "${salesPlan.name}"?`,
              cancelButton: {
                text: 'No',
                action: () => {
                  this.status = 'ready'
                },
              },
              confirmButton: {
                text: 'Yes',
                type: 'primary',
                action: () => {
                  this.salesPlanService
                    .deleteSalesPlan(salesPlan.uuid)
                    .subscribe(
                      (_) => {
                        this.salesPlanResolverService.refresh()
                        this.salesPlanResolverService
                          .resolve()
                          .subscribe((salesPlans) => {
                            this.salesPlans = salesPlans.filter(
                              (currentSalesPlan) =>
                                currentSalesPlan.salesPlanType === 'enterprise',
                            )
                            this.updateEntityList()
                            this.status = 'ready'
                          })
                      },
                      (error) => {
                        this.notification.enqueueNotification({
                          headline: `Unable to delete Enterprise Salesplan ${salesPlan.name}`,
                          appearance: 'error',
                          description: JSON.stringify(error),
                        })
                        this.status = 'ready'
                      },
                    )
                },
              },
            })
          })
        },
      })
    }
    return contextMenu
  }
}
