<cm-page #cmPage>
  <div slot="header">
    <div class="header-grid">
      <div class="header-title">
        <h1>Enterprise Sales Plan {{ salesPlan?.name }}</h1>
      </div>
    </div>
  </div>
  <cm-page-tab label="Cluster Plan Types">
    <cm-entity-list
      [enableCreateButton]="false"
      headline="Cluster Plan Types"
      createButtonLabel="..."
      [columns]="clusterPlanTypeColumns"
      [entities]="clusterPlanTypeContent"
      [loading]="status === 'processing'"
      style="min-height: 200px; margin-bottom: 150px"
    >
      <div slot="empty">No Cluster Plan Types</div>
    </cm-entity-list>
  </cm-page-tab>
  <cm-page-tab label="Organizations">
    <div
      class="organizations"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <ng-container *ngFor="let organization of orgsOfThisPlan">
        <organization [organization]="organization"></organization>
      </ng-container>
    </div>
  </cm-page-tab>
  <cm-page-tab
    label="Onboard Organizations"
    *ngIf="this.activatedFeatures.enterprise.org.convert"
  >
    <div class="search-grid">
      <div class="organization">
        <label>
          <input
            placeholder="Search Organizations"
            [(ngModel)]="searchOrgInput"
            (input)="setFilter({ searchTerm: searchOrgInput })"
          />
          <small>Note: min. 3 characters</small>
          <small
            >Prefix <code>cluster:</code> searches for cluster name or
            uuid</small
          >
        </label>
      </div>
    </div>

    <div
      *ngIf="filteredOrganizations$ | async as filteredOrganizations"
      class="organizations"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <ng-container *ngFor="let organization of filteredOrganizations">
        <organization
          [organization]="organization"
          *ngIf="filteredOrganizations.indexOf(organization) < displayingOrgs"
          specialOperationName="Apply"
          specialOperationAppearance="danger"
          [specialOperationAction]="convert(organization)"
          [specialOperationDisabled]="!allowConvert(organization)"
        >
        </organization>
      </ng-container>
    </div>
  </cm-page-tab>
</cm-page>

<cm-modal
  #modifyItemModal
  headline="{{ selectedItem?.name }} ({{ selectedItem?.uuid }})"
  cancelLabel="Cancel"
  confirmLabel="Modify"
>
  <span *ngIf="selectedItem">
    <form [formGroup]="clusterPlanTypeToConfigItemForm">
      <label
        >Amount<br />
        <input min="0" type="number" formControlName="amount" />
      </label>
    </form>
  </span>
</cm-modal>

<cm-modal
  #onboardModal
  headline="Convert {{ onboardOrg?.name }} to {{ salesPlan.name }}"
  cancelLabel="Cancel"
  confirmLabel="Convert"
  [confirmDisabled]="
    !onboardOrgForm.valid || !checkIfClusterPlanTypeMappingIsOk()
  "
>
  <div
    *ngIf="
      onboardOrgClusterMapping.clusters &&
      onboardOrgClusterMapping.clusters.length > 0
    "
  >
    <div>
      <cm-text
        >Convert the ClusterPlanTypes of existing Clusters to new
        ClusterPlanTypes in the Enterprise SalesPlan. The following listed
        ClusterPlanTypes are unique, you can only proceede if you do a 1-to-1
        mapping!</cm-text
      >
    </div>
    <table style="width: 100%">
      <tr>
        <th>From</th>
        <th>To</th>
      </tr>
      <tr *ngFor="let cluster of onboardOrgClusterMapping.clusters">
        <td>{{ cluster.name }} - {{ cluster.planType.name }}</td>
        <td>
          <select [(ngModel)]="cluster.selectedPt">
            <option
              *ngFor="
                let type of onboardOrgClusterMapping.availableClusterPlanTypes
              "
              [value]="type.name"
            >
              {{ type.name }}
            </option>
          </select>
        </td>
      </tr>
    </table>
    <hr />
  </div>
  <form [formGroup]="onboardOrgForm">
    <label
      >Contract Duration in Months<br /><input
        min="1"
        type="number"
        formControlName="period" /></label
    ><br />
    <label
      >Value Metrics: Included Process Instances<br /><input
        min="0"
        type="number"
        formControlName="includedProcessInstances" /></label
    ><br />
    <label
      >Value Metrics: Included Decision Instances<br /><input
        min="0"
        type="number"
        formControlName="includedDecisionInstances" /></label
    ><br />
    <label
      >Value Metrics: Included Task Users<br /><input
        min="0"
        type="number"
        formControlName="includedTaskUsers"
    /></label>
  </form>
  <label>
    Start Date:<br />
    <input type="date" [(ngModel)]="startDate" [max]="startDateMaximum" />
  </label>
</cm-modal>

<div class="loading" *ngIf="status === 'processing'">
  <cm-loader></cm-loader>
</div>
