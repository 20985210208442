import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UserQueryParams } from '@camunda-cloud/camunda-cloud-idm'
import { OrganizationRole } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { LoginDto } from '../../../../commons/Login.dto'
import { M2MTokenRequests } from '../../../../commons/M2MTokenRequests.dto'
import { UserDto } from '../../../../commons/User.dto'
import { environment } from '../../environments/environment'

const USER_ROUTE = '/api/users'
const ADMIN_ROUTE = '/api/admin'
const LOG_ROUTE = '/api/logs'

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private http: HttpClient) {}

  // returns nothing
  public deleteUser(userId: string) {
    return this.http
      .delete<void>(`${environment.backendUrl}${USER_ROUTE}/${userId}`)
      .pipe(first())
  }

  // returns nothing
  public addUserToOrganization(
    userId: string,
    orgId: string,
    orgRole: OrganizationRole = OrganizationRole.OWNER,
  ) {
    return this.http
      .put<void>(
        `${environment.backendUrl}${USER_ROUTE}/${userId}/organization/${orgId}`,
        { orgRole },
      )
      .pipe(first())
  }

  // returns nothing
  public removeUserFromOrganization(userId: string, orgId: string) {
    return this.http
      .delete<void>(
        `${environment.backendUrl}${USER_ROUTE}/${userId}/organization/${orgId}`,
        {},
      )
      .pipe(first())
  }

  public searchUsers(filter: UserQueryParams): Observable<UserDto[]> {
    const uriEncoded = encodeURI(JSON.stringify(filter))
    return this.http
      .get<UserDto[]>(
        `${environment.backendUrl}${USER_ROUTE}/search?q=${uriEncoded}`,
      )
      .pipe(first())
  }

  public getTopLogins(): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(`${environment.backendUrl}${USER_ROUTE}/?q=top_logins`)
      .pipe(first())
  }

  public getLatestUsers(): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(`${environment.backendUrl}${USER_ROUTE}/?q=latest`)
      .pipe(first())
  }

  public getSupportAgents(): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(`${environment.backendUrl}${USER_ROUTE}/?q=supportagent`)
      .pipe(first())
  }

  public getUser(
    userId: string,
    enrichRoles: boolean = false,
  ): Observable<UserDto> {
    const queryparams = enrichRoles ? `?roles=true` : ''
    return this.http
      .get<UserDto>(
        `${environment.backendUrl}${USER_ROUTE}/${userId}${queryparams}`,
      )
      .pipe(first())
  }

  public addRoleToUser(userId: string, roleToAdd: string) {
    return this.http
      .patch(`${environment.backendUrl}${USER_ROUTE}/${userId}/roles`, {
        roleToAdd,
      })
      .pipe(first())
  }
  public removeRoleFromUser(userId: string, roleToRemove: string) {
    return this.http
      .patch(`${environment.backendUrl}${USER_ROUTE}/${userId}/roles`, {
        roleToRemove,
      })
      .pipe(first())
  }

  // returns nothing
  public refreshVerificationTokenAndResendWelcomeMail(userId: string) {
    return this.http
      .post<void>(
        `${environment.backendUrl}${ADMIN_ROUTE}/resend-welcome-mail`,
        { userId },
      )
      .pipe(first())
  }

  // returns nothing
  public activateUser(userId: string) {
    return this.http
      .post<void>(`${environment.backendUrl}${ADMIN_ROUTE}/activate`, {
        userId,
      })
      .pipe(first())
  }

  public deleteNotActivatedUser(userId: string) {
    return this.http
      .delete<void>(
        `${environment.backendUrl}${ADMIN_ROUTE}/users/inactive/${userId}`,
      )
      .pipe(first())
  }

  public setUserOrgRoles(userId: string, orgId: string, orgRoles: string[]) {
    return this.http
      .patch<void>(
        `${environment.backendUrl}${USER_ROUTE}/${userId}/organization/${orgId}/roles`,
        { userId, orgRoles },
      )
      .pipe(first())
  }

  // returns nothing
  public setUserAsOrgMember(userId: string, orgId: string) {
    return this.setUserOrgRoles(userId, orgId, ['member'])
  }

  // returns nothing
  public setUserAsOrgAdmin(userId: string, orgId: string) {
    return this.setUserOrgRoles(userId, orgId, ['admin'])
  }

  public setUserAsOrgOwner(userId: string, orgId: string) {
    return this.setUserOrgRoles(userId, orgId, ['owner'])
  }

  // Roles
  public getAllRoles() {
    return this.http
      .get<string[]>(`${environment.backendUrl}/api/roles`)
      .pipe(first())
  }

  // Logs
  public getM2MTokenRequests(
    rangeInHours: number,
  ): Observable<M2MTokenRequests[]> {
    return this.http
      .get<M2MTokenRequests[]>(
        `${environment.backendUrl}${LOG_ROUTE}/clients/tokens/count?rangeInHours=${rangeInHours}`,
      )
      .pipe(first())
  }

  public getLatestLogins(rangeInHours: number): Observable<LoginDto[]> {
    return this.http
      .get<LoginDto[]>(
        `${environment.backendUrl}${LOG_ROUTE}/logins?rangeInHours=${rangeInHours}`,
      )
      .pipe(first())
  }
}
