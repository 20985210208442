/* eslint-disable no-shadow */
export enum BaseRoute {
  API = 'api',
}

export enum SubscriptionManagementRoute {
  BASE = 'subscriptionmanagement',
  CHARGEBEE_SITES = 'chargebeesites',
  ITEM_PRICES = 'itemprices',
  ITEMS = 'items',
  SUBSCRIPTIONS = 'subscriptions',
}
