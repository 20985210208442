import { Injectable } from '@angular/core'
import { NotificationItem } from '@camunda-cloud/common-ui/dist/types/components/cm-notification-container/cm-notification-container'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public notifications$ = new Subject<Omit<NotificationItem, 'createdAt'>>()

  public enqueueNotification(
    notificationItem: Omit<NotificationItem, 'createdAt'>,
  ) {
    this.notifications$.next(notificationItem)
  }
}
