<cm-page #cmPage>
  <div slot="header">
    <h1>Camunda Cloud Accounts Admin</h1>
  </div>
  <cm-page-tab label="Organizations">
    <list-organizations></list-organizations>
  </cm-page-tab>
  <cm-page-tab label="Audits">
    <app-audit></app-audit>
  </cm-page-tab>
</cm-page>
