import { DatePipe } from '@angular/common'
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToConfig,
  OrganizationDto,
  SalesPlanDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { CmPage } from '@camunda-cloud/common-ui/dist/types/components/cm-page/cm-page'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { first } from 'rxjs/operators'
import { ClusterRestService } from 'src/app/service/cluster.rest.service'
import { Features } from '../../../../../../commons/Features'
import { AugmentedOrgsResolverService } from '../../../resolver/augmented-orgs.resolver'
import { SalesPlansResolverService } from '../../../resolver/salesplans.resolver'
import { NotificationService } from '../../../service/notification.service'
import { OrgRestService } from '../../../service/org.rest.service'
import { OrgFilter, OrgFilterService } from '../../../service/orgFilter.service'
import { SalesPlanRestService } from '../../../service/salesplan.rest.service'
import { ClusterPlanTypeToConfigItem } from '../salesplan/salesplan.component'

@Component({
  selector: 'enterprise-salesplan',
  templateUrl: './enterprise-salesplan.component.html',
  styleUrls: ['./enterprise-salesplan.component.scss'],
})
export class EnterpriseSalesplanComponent implements OnInit {
  status: 'ready' | 'processing' = 'ready'

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef<CmPage>

  @ViewChild('modifyItemModal', { static: true, read: ElementRef })
  public modifyItemModal: ElementRef<CmModal>

  selectedItem: ClusterPlanTypeToConfigItem
  clusterPlanTypeToConfigItemForm = new FormGroup({
    amount: new FormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
    ]),
  })

  onboardOrgClusterMapping = {
    clusters: [],
    availableClusterPlanTypes: [],
    hasUnmappedCluster: false,
    onePtWasSelectedTwice: false,
  }

  onboardOrgForm = new FormGroup({
    period: new FormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1000),
    ]),
    includedProcessInstances: new FormControl('', [
      Validators.required,
      Validators.min(0),
    ]),
    includedDecisionInstances: new FormControl('', [
      Validators.required,
      Validators.min(0),
    ]),
    includedTaskUsers: new FormControl('', [
      Validators.required,
      Validators.min(0),
    ]),
  })
  public startDate: string
  public startDateMaximum

  @ViewChild('onboardModal', { static: true, read: ElementRef })
  public onboardModal: ElementRef<CmModal>
  public onboardOrg: OrganizationDto

  public activatedFeatures: Features
  public allOrgs: OrganizationDto[]
  public orgsOfThisPlan: OrganizationDto[]
  clusterPlanTypes: ClusterPlanTypeDto[]
  salesPlan: SalesPlanDto
  salesPlanId: string

  clusterPlanTypeColumns = [
    {
      name: 'Cluster Plan Type',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Region',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Amount',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: '', width: '35px' },
  ]
  clusterPlanTypeContent = []

  public filteredOrganizations$: BehaviorSubject<OrganizationDto[]>
  public searchOrgInput = ''
  public filter: BehaviorSubject<OrgFilter> = new BehaviorSubject({})
  public displayingOrgs = 10

  public tabChangedTo: BehaviorSubject<string> = new BehaviorSubject('')
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public orgFilterService: OrgFilterService,
    private ngZone: NgZone,
    private salesPlanService: SalesPlanRestService,
    private salesPlanResolverService: SalesPlansResolverService,
    private notificationService: NotificationService,
    private orgService: OrgRestService,
    private augmentedOrgsResolverService: AugmentedOrgsResolverService,
    private datePipe: DatePipe,
    private clusterService: ClusterRestService,
  ) {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.allOrgs = this.route.snapshot.data.orgs
    this.startDateMaximum = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.salesPlanId = params.salesPlanId
      const salesPlans: SalesPlanDto[] = this.route.snapshot.data.salesPlans
      this.clusterPlanTypes = this.route.snapshot.data.allClusterPlanTypes
      this.updateClusterPlanTypeEntityList(salesPlans)
    })
    this.filteredOrganizations$ = new BehaviorSubject<OrganizationDto[]>(
      this.route.snapshot.data.orgs,
    )
    this.orgsOfThisPlan = this.allOrgs.filter(
      (org) => org.organizationToSalesPlan?.salesPlan.uuid === this.salesPlanId,
    )

    combineLatest([
      this.filter,
      this.augmentedOrgsResolverService.resolve(),
    ]).subscribe(([filter, allOrganizations]) => {
      this.orgFilterService.init(allOrganizations, this.clusterPlanTypes)
      const filteredOrgs = this.orgFilterService.update(filter)
      this.displayingOrgs = 10
      this.filteredOrganizations$.next(filteredOrgs)
    })
    this.cmPage.nativeElement.activeLabel = this.route.snapshot.data.activeLabel

    const detailsIndex = location.href.split('/').indexOf(this.salesPlanId)
    this.route.url.subscribe((_urlSegments) => {
      ;(this.cmPage.nativeElement as any).addEventListener(
        'tabChanged',
        (_event) => {
          const url = location.href
            .split('/')
            .slice(0, detailsIndex + 1)
            .join('/')

          switch (this.cmPage.nativeElement.activeLabel) {
            case 'Cluster Plan Types':
              history.replaceState({}, '', `${url}/clusterPlanTypes`)
              this.tabChangedTo.next('clusterPlanTypes')
              break
            case 'Organizations':
              history.replaceState({}, '', `${url}/organizations`)
              this.tabChangedTo.next('organizations')
              break
            case 'Onboard Organizations':
              history.replaceState({}, '', `${url}/onboard`)
              this.tabChangedTo.next('onboard')
              break
            default:
              history.replaceState({}, '', `${url}`)
              this.tabChangedTo.next('')
              break
          }
        },
      )
    })
  }

  public refreshTabs(label: string = 'dummy') {
    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = label
  }

  private updateClusterPlanTypeEntityList(salesPlans: SalesPlanDto[]) {
    this.salesPlan = salesPlans.find((csp) => csp.uuid === this.salesPlanId)
    const clusterPlanConfiguration: ClusterPlanTypesToConfig = {}
    const clusterPlanTypes = this.clusterPlanTypes
    clusterPlanTypes.forEach((clusterPlanType) => {
      clusterPlanConfiguration[clusterPlanType.uuid] = {
        maxReserved: 0,
        minReserved: 0,
        price: '$0',
      }
    })
    const salesPlan: SalesPlanDto = this.salesPlan
      ? this.salesPlan
      : {
          name: '',
          version: 0,
          clusterPlanTypesToConfig: {},
        }
    ;(salesPlan as any).clusterPlanTypeIdsToPlanType = {}
    clusterPlanTypes.forEach((clusterPlanType) => {
      if (
        !salesPlan.clusterPlanTypesToConfig ||
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] === undefined
      ) {
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] = {
          maxReserved: 0,
          minReserved: 0,
          price: '',
        }
      }
      ;(salesPlan as any).clusterPlanTypeIdsToPlanType[
        clusterPlanType.uuid
      ] = clusterPlanType
    })
    Object.keys(salesPlan.clusterPlanTypesToConfig).forEach((configKey) => {
      if (
        !clusterPlanTypes.find((clusterPlanType) => {
          return clusterPlanType.uuid === configKey
        })
      ) {
        delete salesPlan.clusterPlanTypesToConfig[configKey]
      }
    })
    this.clusterPlanTypeContent = Object.keys(
      salesPlan.clusterPlanTypesToConfig,
    ).map((configKey) => {
      const clusterPlanTypeToConfig =
        salesPlan.clusterPlanTypesToConfig[configKey]
      const clusterPlanType = clusterPlanTypes.find(
        (currentClusterPlanType) => currentClusterPlanType.uuid === configKey,
      )
      return {
        data: [
          {
            type: 'text',
            content: clusterPlanType ? clusterPlanType.name : configKey,
          },
          {
            type: 'text',
            content: clusterPlanType
              ? clusterPlanType.k8sContext.name
              : 'unknown',
          },
          {
            type: 'text',
            content: String(clusterPlanTypeToConfig.minReserved),
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: this.contextMenu({
                  uuid: configKey,
                  name: clusterPlanType ? clusterPlanType.name : configKey,
                  clusterPlanTypeToConfig,
                }),
              },
            ],
          },
        ],
      }
    })
  }

  private contextMenu(item: ClusterPlanTypeToConfigItem) {
    const contextMenu: any[] = []

    contextMenu.push({
      label: `Modify Entry`,
      isDisabled: !this.activatedFeatures.enterprise.salesPlan.modify,
      handler: () =>
        this.ngZone.run(() => {
          this.status = 'processing'
          this.selectedItem = item
          this.clusterPlanTypeToConfigItemForm.setValue({
            amount: item.clusterPlanTypeToConfig.minReserved,
          })
          ;(this.modifyItemModal.nativeElement as CmModal)
            .open()
            .then((result) => {
              if (result === 'confirm') {
                this.salesPlan.clusterPlanTypesToConfig[
                  this.selectedItem.uuid
                ] = {
                  minReserved: this.clusterPlanTypeToConfigItemForm.value
                    .amount,
                  maxReserved: this.clusterPlanTypeToConfigItemForm.value
                    .amount,
                  price: '',
                }
                this.salesPlanService
                  .modifySalesPlan(
                    this.salesPlan.uuid,
                    this.salesPlan.name,
                    this.salesPlan.clusterPlanTypesToConfig,
                    this.salesPlan.version,
                    false,
                  )
                  .subscribe(
                    (_) => {
                      this.salesPlanResolverService.refresh()
                      this.salesPlanResolverService.resolve().subscribe(
                        (salesPlans) => {
                          this.updateClusterPlanTypeEntityList(salesPlans)
                          this.status = 'ready'
                        },
                        (error) => {
                          this.notificationService.enqueueNotification({
                            headline: 'unable to refresh salesplan',
                            appearance: 'error',
                            description: JSON.stringify(error),
                          })
                          this.status = 'ready'
                        },
                      )
                    },
                    (error) => {
                      this.notificationService.enqueueNotification({
                        headline: 'unable to modify salesplan',
                        appearance: 'error',
                        description: JSON.stringify(error),
                      })
                      this.status = 'ready'
                    },
                  )
              } else {
                this.status = 'ready'
              }
            })
        }),
    })

    return contextMenu
  }
  public onScroll() {
    this.displayingOrgs += 10
  }
  public setFilter(filter: OrgFilter) {
    this.filter.next(filter)
  }

  // eslint-disable-next-line class-methods-use-this
  public convert(organization: OrganizationDto) {
    return () => {
      this.ngZone.run(async () => {
        let clusters = await this.clusterService
          .getClustersForOrg(organization.uuid)
          .pipe(first())
          .toPromise()
        const availableClusterPlanTypes: ClusterPlanTypeDto[] = [
          { name: '' } as any,
        ]
        let counter = 0
        Object.keys(this.salesPlan.clusterPlanTypesToConfig).forEach(
          (clusterPlanTypeToConfigUuid) => {
            for (
              let index = 0;
              index <
              this.salesPlan.clusterPlanTypesToConfig[
                clusterPlanTypeToConfigUuid
              ].maxReserved;
              index++
            ) {
              const cpType = {
                ...this.clusterPlanTypes.find(
                  (clusterPT) => clusterPT.uuid === clusterPlanTypeToConfigUuid,
                ),
              }
              cpType.name = `${counter} - ${cpType.name}`
              counter++
              availableClusterPlanTypes.push(cpType)
            }
          },
        )
        this.onboardOrgClusterMapping = {
          clusters,
          availableClusterPlanTypes,
          hasUnmappedCluster: false,
          onePtWasSelectedTwice: false,
        }
        this.onboardOrgForm.setValue({
          period: 12,
          includedProcessInstances: 1,
          includedDecisionInstances: 1,
          includedTaskUsers: 1,
        })
        this.onboardModal.nativeElement.open().then((result) => {
          if (result === 'confirm') {
            this.status = 'processing'
            this.onboardOrgClusterMapping.clusters = this.onboardOrgClusterMapping.clusters.map(
              (mapping) => {
                mapping.selectedPt = this.onboardOrgClusterMapping.availableClusterPlanTypes.find(
                  (clusPt) => clusPt.name === mapping.selectedPt,
                )
                return mapping
              },
            )
            this.orgService
              .changeSalesPlan(organization.uuid, this.salesPlan.uuid, {
                duration: this.onboardOrgForm.value.period,
                includedValueMetrics: {
                  processInstances: this.onboardOrgForm.value
                    .includedProcessInstances,
                  decisionInstances: this.onboardOrgForm.value
                    .includedDecisionInstances,
                  taskUsers: this.onboardOrgForm.value.includedTaskUsers,
                },
                enterPriseStartDate: this.startDate,
                onboardOrgClusterMapping: this.onboardOrgClusterMapping.clusters.map(
                  (clus) => {
                    return {
                      clusterId: clus.uuid,
                      planTypeId: clus.selectedPt.uuid,
                    }
                  },
                ),
              })
              .subscribe(
                (_) => {
                  this.augmentedOrgsResolverService.refreshOrgs()
                  this.augmentedOrgsResolverService.resolve().subscribe((_) => {
                    this.router.navigate([
                      `enterprise`,
                      this.salesPlanId,
                      `organizations`,
                    ])
                    this.status = 'ready'
                    this.notificationService.enqueueNotification({
                      headline: `switched ${organization.name} to ${this.salesPlan.name}`,
                      appearance: 'success',
                    })
                  })
                },
                (error) => {
                  this.status = 'ready'
                  this.notificationService.enqueueNotification({
                    headline: 'unable to switch sales plan',
                    description: JSON.stringify(error),
                    appearance: 'error',
                  })
                },
              )
          }
        })
      })
    }
  }

  public checkIfClusterPlanTypeMappingIsOk() {
    this.onboardOrgClusterMapping.hasUnmappedCluster =
      undefined !==
      this.onboardOrgClusterMapping.clusters.find(
        (mappedCluster) =>
          mappedCluster.selectedPt === '' || !mappedCluster.selectedPt,
      )
    this.onboardOrgClusterMapping.onePtWasSelectedTwice =
      undefined !==
      this.onboardOrgClusterMapping.clusters.find((mappedCluster) => {
        return (
          this.onboardOrgClusterMapping.clusters.find(
            (mappedOtherCluster) =>
              mappedOtherCluster.uuid !== mappedCluster.uuid &&
              mappedCluster.selectedPt !== undefined &&
              mappedCluster.selectedPt === mappedOtherCluster.selectedPt,
          ) !== undefined
        )
      })
    return !(
      this.onboardOrgClusterMapping.hasUnmappedCluster ||
      this.onboardOrgClusterMapping.onePtWasSelectedTwice
    )
  }

  // eslint-disable-next-line class-methods-use-this
  public allowConvert(organization: OrganizationDto) {
    const currentSpt =
      organization.organizationToSalesPlan?.salesPlan.salesPlanType

    // TODO: we probably want paid plans to get converted to enterprise as well
    if (
      currentSpt === SalesPlanType.ENTERPRISE ||
      currentSpt === SalesPlanType.PAID_CC ||
      currentSpt === SalesPlanType.INTERNAL ||
      !currentSpt
    ) {
      return false
    }
    return this.activatedFeatures.enterprise.org.convert
  }
}
