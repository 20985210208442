import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SalesPlanType } from '@camunda-cloud/cloud-node-libs'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { Features } from '../../../../../commons/Features'
import { LoginDto } from '../../../../../commons/Login.dto'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserSearchFilterDto } from '../../../../../commons/SearchFilter.dto'
import { UserDto } from '../../../../../commons/User.dto'
import { UserRestService } from '../../../app/service/user.rest.service'
import { AugmentedOrgsResolverService } from '../../resolver/augmented-orgs.resolver'
import { ClusterRestService } from '../../service/cluster.rest.service'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { FeatureService } from '../../service/feature.service'
import { OrgRestService } from '../../service/org.rest.service'

@Component({
  selector: 'organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  public activatedFeatures: Features

  @Input()
  public organization: OrganizationDto

  @Input() specialOperationName: string | undefined
  @Input() specialOperationAppearance:
    | 'primary'
    | 'danger'
    | 'secondary'
    | undefined
  @Input() specialOperationAction: () => void
  public specialOperation() {
    this.specialOperationAction()
  }
  @Input() specialOperationDisabled: boolean | undefined

  @Input()
  public showSelection

  @Input()
  public selected = false

  @Input()
  public logins: LoginDto[]

  @Output()
  public selectionChange = new EventEmitter<string>()

  public isTrial: boolean
  public hasClusters: boolean
  public users: UserDto[]

  public applicationLogins: Array<{ name: string; count: number | string }> = [
    { name: 'Console', count: 'Loading...' },
    { name: 'Operate', count: 'Loading...' },
  ]

  public hasDetails = false
  public showDetails = false
  public showClusterDetailsSet: Set<string> = new Set<string>()

  @ViewChild('selectionCheckbox', { read: ElementRef })
  public selectionCheckbox: ElementRef

  constructor(
    public features: FeatureService,
    private orgService: OrgRestService,
    private clusterService: ClusterRestService,
    private userRestService: UserRestService,
    private router: Router,
    private route: ActivatedRoute,
    private augmentedOrgsResolverService: AugmentedOrgsResolverService,
    private modalService: ConfirmationModalService,
  ) {}

  public ngOnInit() {
    this.showSelection =
      this.showSelection === undefined ? false : this.showSelection

    this.isTrial =
      this.organization &&
      this.organization.organizationToSalesPlan &&
      this.organization.organizationToSalesPlan.salesPlan &&
      this.organization.organizationToSalesPlan.salesPlan.salesPlanType ===
        SalesPlanType.TRIAL

    this.hasClusters =
      this.organization &&
      this.organization.clusters &&
      this.organization.clusters.length > 0

    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
  }

  public updateSelection() {
    this.selected = !this.selected
    this.selectionChange.emit(this.organization.uuid)
  }

  public getDetails() {
    this.hasDetails = true

    const filter: UserSearchFilterDto = {
      organization: {
        uuid: this.organization.uuid,
        member: true,
      },
    }

    this.userRestService.searchUsers(filter).subscribe((users) => {
      this.users = users
      this.getApplicationLogins()
    })
  }

  public getApplicationLogins() {
    const userIds = this.users.map((user) => user.user_id)
    const orgLogins = this.logins.filter((login) =>
      userIds.includes(login.userId),
    )

    const operateLogins = orgLogins.filter((login) =>
      login.clientName.includes('Operate'),
    ).length

    const consoleLogins = orgLogins.filter((login) =>
      login.clientName.includes('Console'),
    ).length

    this.applicationLogins = [
      { name: 'Console', count: consoleLogins },
      { name: 'Operate', count: operateLogins },
    ]
  }

  public deleteClustersDialog() {
    const clusters = this.organization.clusters
    const clusterNames = clusters!.map((cluster) => cluster.name)

    this.modalService.openModal({
      title: `Delete Clusters for "${this.organization.name}"`,
      bodyIsRaw: true,
      body: `Are you sure you want to delete all clusters for <b>${
        this.organization.name
      }?</b><p>This will remove the following clusters:</p><ul>${clusterNames
        .map((clustername) => `<li>${clustername}</li>`)
        .join('')}</ul></p>`,
      confirmButton: {
        text:
          clusterNames.length === 1
            ? 'Delete the Cluster'
            : `Delete all ${clusterNames.length}`,
        type: 'danger',
        action: () => {
          from(clusters)
            .pipe(
              mergeMap((cluster) => {
                return this.clusterService.deleteCluster(
                  this.organization.uuid,
                  cluster.uuid,
                )
              }),
            )
            .subscribe(null, null, () => {
              this.augmentedOrgsResolverService.refreshClusters()
              this.organization.clusters = []
              this.router.navigate(['organizations'])
            })
        },
      },
      cancelButton: {
        text: 'No',
        type: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteDialog() {
    this.modalService.openModal({
      title: 'Delete Organization?',
      body: `Are you sure to delete Organization "${this.organization.name}"?`,
      confirmButton: {
        text: 'I am sure',
        type: 'danger',
        action: () => {
          this.orgService
            .deleteOrganization(this.organization.uuid)
            .toPromise()
            .then(() => {
              this.augmentedOrgsResolverService.refreshOrgs()
              this.router.navigate(['organizations'])
            })
        },
      },
      cancelButton: {
        text: 'No',
        type: 'secondary',
        action: () => {},
      },
    })
  }

  public toggleClusterDetails(clusterId: string) {
    if (this.showClusterDetails(clusterId)) {
      this.showClusterDetailsSet.delete(clusterId)
    } else {
      this.showClusterDetailsSet.add(clusterId)
    }
  }

  public showClusterDetails(clusterId: string) {
    return this.showClusterDetailsSet.has(clusterId)
  }

  // eslint-disable-next-line class-methods-use-this
  public copyToClipboard(toClipboard: string) {
    return navigator.clipboard.writeText(toClipboard)
  }
}
