<app-navbar></app-navbar>

<main id="routerWrapper">
  <div class="loading" *ngIf="loading">
    <cm-loader></cm-loader>
  </div>
  <router-outlet *ngIf="!loading"></router-outlet>
</main>

<div
  class="snackbar"
  [ngClass]="{
    'is-visible': showSnackbar,
    error: snackbarSeverity === 'error',
    warn: snackbarSeverity === 'warn'
  }"
>
  {{ snackbarMessage }}
</div>

<cm-notification-container #notificationContainer></cm-notification-container>

<cm-footer
  *ngIf="user$ | async"
  text="{{ sha$ | async }} ©2020 Camunda Services GmbH {{
    (user$ | async) ? '(' + (user$ | async).email + ')' : ''
  }}"
></cm-footer>
<modal></modal>
