<cm-header>
  <a class="header-name" [routerLink]="['/']" slot="left">
    <cm-logo></cm-logo><span>Camunda Cloud</span>
  </a>

  <div class="header-vertical-divider" slot="left"></div>
  <div class="header-breadcrumb" slot="left">
    <a routerLink="/organizations"> Organizations </a>
    <span class="header-breadcrumb-divider"></span>
    <a routerLink="/users"> Users </a>
    <span class="header-breadcrumb-divider"></span>
    <a routerLink="/salesplans"> Sales Plans </a>
  </div>
  <div class="header-vertical-divider" slot="left"></div>
  <div class="header-breadcrumb" slot="left">
    <a routerLink="/stats"> Stats </a>
  </div>
  <span *ngIf="stage$ | async" slot="left" style="margin-left: 10px">
    <cm-toggle
      (cmInput)="toggleAllOrgs($event)"
      [attr.checked]="appSettingsService.settings.readOnlyNotExpiredOrgs"
      label="Only active Orgs"
    ></cm-toggle>
  </span>

  <span
    *ngIf="stage$ | async"
    class="stageIndicator"
    [ngClass]="stage$ | async"
    slot="right"
  ></span>
</cm-header>
