import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { AugmentedOrgsResolverService } from '../resolver/augmented-orgs.resolver'
import { AppSetting, AppSettingsService } from '../service/appSettings.service'
import { FeatureService } from '../service/feature.service'
import { NavbarService } from '../service/navbar.service'

interface Breadcrumb {
  label: string
  href?: string
}

@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  public stage$: Observable<
    'development' | 'integration' | 'production' | undefined
  >
  public profile$: any
  public breadcrumbs$: Observable<Breadcrumb[]>

  constructor(
    private authService: AuthService,
    public features: FeatureService,
    private router: Router,
    private navbarService: NavbarService,
    public appSettingsService: AppSettingsService,
    private augmentedOrgsResolverService: AugmentedOrgsResolverService,
  ) {
    const currentOrg$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const breadcrumbs: Breadcrumb[] = []

        const fragments = event.url
          .split('/')
          .filter((fragment) => fragment !== '')
        if (fragments.length === 0) {
          this.breadcrumbs$ = of([])
        }
        if (fragments[0] === 'organizations') {
          this.navbarService.$currentOrg
            .pipe(filter((org) => org !== undefined))
            .subscribe((selectedOrg) => {
              this.breadcrumbs$ = of([
                {
                  label: selectedOrg.name,
                  href: `/organization/${selectedOrg.uuid}`,
                },
              ])
            })
        }
      })
  }

  public ngOnInit(): void {
    this.features.activated$.subscribe((activatedFeatures) => {
      if (!activatedFeatures.admin.readAll) {
        window.location.href = environment.consoleBackendUrl
      }
    })

    this.profile$ = this.authService.user$
    this.stage$ = this.features.activated$.pipe(
      map((activatedFeatures) => {
        if (
          activatedFeatures.admin.deleteClusters ||
          activatedFeatures.salesPlan.modify ||
          activatedFeatures.salesPlan.delete ||
          activatedFeatures.salesPlan.create
        ) {
          if (environment.production) {
            return 'production'
          } else if (environment.integration) {
            return 'integration'
          } else {
            return 'development'
          }
        } else {
          return undefined
        }
      }),
    )
  }

  public toggleAllOrgs(event) {
    this.appSettingsService.setAppSetting(
      AppSetting.READ_ONLY_NOT_EXPIRED_ORGS,
      event.detail.isChecked,
    )
    this.augmentedOrgsResolverService.refreshAll()
    this.router.navigate(['/'])
  }

  public login() {
    this.authService.loginWithRedirect()
  }

  public logout() {
    this.authService.logout()
  }
}
