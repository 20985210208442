import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToConfig,
  ClusterPlanTypesToReserved,
  SalesPlanDto,
  SalesPlanMetadata,
  SalesPlanType,
  SubscriptionConfig,
} from '@camunda-cloud/cloud-node-libs'
import { first } from 'rxjs/operators'
import { environment } from '../../environments/environment'

const SALES_PLANS_ROUTE = '/api/salesplans'
const CLUSTER_PLAN_TYPES_ROUTE = '/api/plans/type'

@Injectable({
  providedIn: 'root',
})
export class SalesPlanRestService {
  constructor(private http: HttpClient) {}

  public getClusterPlanTypes() {
    return this.http
      .get<ClusterPlanTypeDto[]>(
        `${environment.consoleBackendUrl}${CLUSTER_PLAN_TYPES_ROUTE}`,
        {},
      )
      .pipe(first())
  }

  public getSalesPlans() {
    return this.http
      .get<SalesPlanDto[]>(`${environment.backendUrl}${SALES_PLANS_ROUTE}`, {})
      .pipe(first())
  }

  public createSalesPlan(
    name: string,
    type: SalesPlanType,
    clusterPlanConfigurationMap: ClusterPlanTypesToConfig,
    version: number,
  ) {
    const internal = type === SalesPlanType.INTERNAL
    return this.http
      .post(
        `${environment.backendUrl}${SALES_PLANS_ROUTE}`,
        {
          name,
          type,
          internal,
          clusterPlanConfigurationMap,
          version,
        },
        { responseType: 'text' },
      )
      .pipe(first())
  }

  public modifySalesPlan(
    salesPlanUuid: string,
    name: string,
    clusterPlanConfigurationMap: ClusterPlanTypesToConfig,
    version: number,
    force: boolean = false,
  ) {
    return this.http
      .put(
        `${environment.backendUrl}${SALES_PLANS_ROUTE}/${salesPlanUuid}`,
        {
          name,
          clusterPlanConfigurationMap,
          version,
          force,
        },
        { responseType: 'text' },
      )
      .pipe(first())
  }

  public updateNameOrMetadata(
    salesPlanId: string,
    newData: {
      name: string
      metadata: SalesPlanMetadata
    },
  ) {
    return this.http
      .patch(
        `${environment.backendUrl}${SALES_PLANS_ROUTE}/${salesPlanId}/metadata`,
        {
          name: newData.name,
          metadata: newData.metadata,
        },
      )
      .pipe(first())
  }

  public updateSubscriptionConfigs(
    salesPlanUuid: string,
    subscriptionConfigs: SubscriptionConfig[],
  ) {
    return this.http
      .put(
        `${environment.backendUrl}${SALES_PLANS_ROUTE}/${salesPlanUuid}/subscription-configs`,
        { subscriptionConfigs },
      )
      .pipe(first())
  }

  public deleteSalesPlan(salesPlanUuid: string) {
    return this.http
      .delete(
        `${environment.backendUrl}${SALES_PLANS_ROUTE}/${salesPlanUuid}`,
        { responseType: 'text' },
      )
      .pipe(first())
  }

  public setReservedClusterPlanTypes(
    orgUuid: string,
    clusterPlanTypesToReserved: ClusterPlanTypesToReserved,
  ) {
    return this.http.patch<void>(
      `${environment.backendUrl}${SALES_PLANS_ROUTE}/reserved?orgId=${orgUuid}`,
      {
        clusterPlanTypesToReserved,
        changedBy: 'admin',
      },
    )
  }
}
