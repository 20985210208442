import { NgModule } from '@angular/core'
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'
import { OrgSearchGuard } from './auth/orgSearch.guard'
import { ReadAllGuard } from './auth/readAll.guard'
import { AcceptInviteComponent } from './components/accept-invite/accept-invite.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { EmailVerificationComponent } from './components/email-verification/email-verification.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { RedirectComponent } from './components/redirect/redirect.component'
import { EnterpriseSalesplanComponent } from './components/sales-plan/enterprise-salesplan/enterprise-salesplan.component'
import { SalesplanComponent } from './components/sales-plan/salesplan/salesplan.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { ProductItemsComponent } from './components/subscription-management/product-items/product-items.component'
import { OrganizationDetailsPage } from './pages/organization-details/organization-details.page'
import { OverviewPage } from './pages/overview/overview.page'
import { SalesPlansPage } from './pages/salesplans/salesplans.page'
import { UsersPage } from './pages/users/users.page'
import { ProfileComponent } from './profile/profile.component'
import { ActivatedFeaturesResolverService } from './resolver/activated-features.resolver'
import { AllClusterPlanTypesResolverService } from './resolver/all-cluster-plan-types.resolver'
import { AllRolesResolverService } from './resolver/all-roles.resolver'
import { AugmentedOrgResolverService } from './resolver/augmented-org.resolver'
import { AugmentedOrgsResolverService } from './resolver/augmented-orgs.resolver'
import { BpmnModelMetricsResolver } from './resolver/bpmnModelMetrics.resolver'
import { LatestLoginsResolverService } from './resolver/latest-logins.resolver'
import { LatestUsersResolverService } from './resolver/latest-users.resolver'
import { M2mTokenRequestsResolverService } from './resolver/m2m-token-requests.resolver'
import { OrgsResolverService } from './resolver/orgs.resolver'
import { SalesPlansResolverService } from './resolver/salesplans.resolver'
import { SubsidiariesResolverService } from './resolver/subsidiaries.resolver'
import { TopLoginUsersResolverService } from './resolver/top-login-users.resolver'
import { NoCacheRouteReuseStrategy } from './route-reuse-strategy'
import { AppSettingsService } from './service/appSettings.service'

const routes: Routes = [
  {
    component: ProfileComponent,
    path: 'profile',
  },
  {
    path: 'stats',
    canActivate: [AuthGuard, ReadAllGuard],
    component: DashboardComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      latestUsers: LatestUsersResolverService,
      topLogins: TopLoginUsersResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      latestLogins: LatestLoginsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
    },
    data: {
      activeLabel: 'Dashboard',
    },
  },
  {
    path: 'products',
    canActivate: [AuthGuard, ReadAllGuard],
    component: ProductItemsComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      activatedFeatures: ActivatedFeaturesResolverService,
      subsidiaries: SubsidiariesResolverService,
    },
  },
  {
    path: '',
    redirectTo: 'organizations',
    pathMatch: 'full',
  },
  {
    path: 'organizations',
    canActivate: [AuthGuard, ReadAllGuard, OrgSearchGuard],
    component: OverviewPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      settings: AppSettingsService,
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
    },
    data: {
      activeLabel: 'Organizations',
    },
  },
  {
    path: 'audits',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OverviewPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
    },
    data: {
      activeLabel: 'Audits',
    },
  },
  {
    path: 'users',
    canActivate: [AuthGuard, ReadAllGuard],
    component: UsersPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      activatedFeatures: ActivatedFeaturesResolverService,
      allRoles: AllRolesResolverService,
      orgs: AugmentedOrgsResolverService,
    },
    data: {
      activeLabel: 'Users',
    },
  },
  {
    path: 'invites',
    canActivate: [AuthGuard, ReadAllGuard],
    component: UsersPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      activatedFeatures: ActivatedFeaturesResolverService,
      allRoles: AllRolesResolverService,
      orgs: AugmentedOrgsResolverService,
    },
    data: {
      activeLabel: 'Invites',
    },
  },
  {
    path: 'organizations/:orgUuid',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
  },
  {
    path: 'organizations/:orgUuid/valuemetrics',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Value Metrics',
    },
  },
  {
    path: 'organizations/:orgUuid/clusters',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Clusters',
    },
  },
  {
    path: 'organizations/:orgUuid/members',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Members',
    },
  },
  {
    path: 'organizations/:orgUuid/invites',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Invites',
    },
  },
  {
    path: 'organizations/:orgUuid/supportagents',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Support Agents',
    },
  },
  {
    path: 'organizations/:orgUuid/audit',
    canActivate: [AuthGuard, ReadAllGuard],
    component: OrganizationDetailsPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      org: AugmentedOrgResolverService,
      allOrgs: OrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      salesPlans: SalesPlansResolverService,
      m2mTokenRequests: M2mTokenRequestsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      allRoles: AllRolesResolverService,
      bpmnModelMetrics: BpmnModelMetricsResolver,
    },
    data: {
      activeLabel: 'Audit',
    },
  },
  {
    path: 'salesplans',
    canActivate: [AuthGuard, ReadAllGuard],
    component: SalesPlansPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
    },
    data: {
      activeLabel: 'Sales Plans',
    },
  },
  {
    path: 'salesplans/:salesPlanId',
    canActivate: [AuthGuard, ReadAllGuard],
    component: SalesplanComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      subsidiaries: SubsidiariesResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
    },
    data: {
      activeLabel: 'Sales Plans',
    },
  },
  {
    path: 'enterprise',
    canActivate: [AuthGuard, ReadAllGuard],
    component: SalesPlansPage,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
    },
    data: {
      activeLabel: 'Enterprise Plans',
    },
  },
  {
    path: 'enterprise/:salesPlanId',
    canActivate: [AuthGuard, ReadAllGuard],
    component: EnterpriseSalesplanComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
    },
    data: {
      activeLabel: 'Cluster Plan Types',
    },
  },
  {
    path: 'enterprise/:salesPlanId/clusterPlanTypes',
    canActivate: [AuthGuard, ReadAllGuard],
    component: EnterpriseSalesplanComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
    },
    data: {
      activeLabel: 'Cluster Plan Types',
    },
  },
  {
    path: 'enterprise/:salesPlanId/organizations',
    canActivate: [AuthGuard, ReadAllGuard],
    component: EnterpriseSalesplanComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
    },
    data: {
      activeLabel: 'Organizations',
    },
  },
  {
    path: 'enterprise/:salesPlanId/onboard',
    canActivate: [AuthGuard, ReadAllGuard],
    component: EnterpriseSalesplanComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      salesPlans: SalesPlansResolverService,
      allClusterPlanTypes: AllClusterPlanTypesResolverService,
      orgs: AugmentedOrgsResolverService,
      activatedFeatures: ActivatedFeaturesResolverService,
    },
    data: {
      activeLabel: 'Onboard Organizations',
    },
  },
  {
    component: SignUpComponent,
    path: 'signup',
  },
  {
    component: RedirectComponent,
    path: 'redirect',
    data: {
      target: 'console',
    },
  },
  {
    component: SignUpComponent,
    path: 'signup/:inviteToken',
  },
  {
    component: EmailVerificationComponent,
    path: 'verification/email/:token',
  },
  {
    component: EmailVerificationComponent,
    path: 'verification/user/:userId/:token',
  },
  {
    component: AcceptInviteComponent,
    path: 'verification/organization/:orgId/invitation/:inviteToken',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
]

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: NoCacheRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
