import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { VerificationDto } from '../../../../commons/Verification.dto'
import { first } from 'rxjs/operators'
import { AcceptInvitationDto } from '../../../../commons/Invitation.dto'

@Injectable({
  providedIn: 'root',
})
export class VerifyService {
  private httpOptions = {
    headers: new HttpHeaders({}),
  }

  constructor(private http: HttpClient) {}

  public verifyToken(
    token: string,
    userId: string | undefined,
  ): Observable<VerificationDto> {
    return userId
      ? this.http
          .get<VerificationDto>(
            `${environment.backendUrl}/verification?userId=${userId}&token=${token}`,
            this.httpOptions,
          )
          .pipe(first())
      : this.http
          .get<VerificationDto>(
            `${environment.backendUrl}/verification?token=${token}`,
            this.httpOptions,
          )
          .pipe(first())
  }

  public verifyInvitation(
    orgId: string,
    inviteToken: string,
    email?: string,
  ): Observable<AcceptInvitationDto> {
    const url = email
      ? `${
          environment.backendUrl
        }/verification/organization/${orgId}/invitation/${inviteToken}/accept?email=${encodeURIComponent(
          email,
        )}`
      : `${environment.backendUrl}/verification/organization/${orgId}/invitation/${inviteToken}/accept`
    return this.http
      .post<AcceptInvitationDto>(url, this.httpOptions)
      .pipe(first())
  }

  public activateUser(token: string): Observable<any> {
    return this.http
      .post(
        `${environment.backendUrl}/verification/activate`,
        { token },
        this.httpOptions,
      )
      .pipe(first())
  }

  public getStatusOfInviteToken(
    inviteToken: string,
    orgId?: string,
    email?: string,
  ): Observable<AcceptInvitationDto> {
    const url =
      email && orgId
        ? `${
            environment.backendUrl
          }/verification/invitation/${inviteToken}?orgId=${encodeURIComponent(
            orgId,
          )}&email=${encodeURIComponent(email)}`
        : `${environment.backendUrl}/verification/invitation/${inviteToken}`
    return this.http
      .get<AcceptInvitationDto>(url, this.httpOptions)
      .pipe(first())
  }
}
