<cm-entity-list
  [enableCreateButton]="mayAddExistingUsers && !memberListLoading"
  headline="{{ org ? 'Members' : 'Users' }}"
  createButtonLabel="{{ org ? 'Add existing user' : 'Create user' }}"
  [createHandler]="addHandler"
  [columns]="memberListColumns"
  [entities]="memberListEntites"
  [loading]="memberListLoading"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">
    {{ org ? 'There are no Members in this Organization.' : 'No Users found.' }}
  </div>
</cm-entity-list>
<cm-modal
  #identitiesModal
  headline="Identities of {{ selectedUser ? selectedUser.name : '' }}"
  confirmLabel="Close"
>
  <h4 *ngIf="tokenValidTo">user is not activated yet!</h4>
  <ul *ngIf="selectedUser">
    <li *ngFor="let identity of selectedUser.identities">
      {{ identity.provider }} -
      <span *ngIf="identity.provider === 'google-oauth2'">
        userId: {{ identity.user_id }}
      </span>
      <span *ngIf="identity.provider === 'auth0'">
        userId: {{ identity.user_id }}
      </span>
      <span *ngIf="identity.provider === 'github'">
        <span *ngIf="identity.profileData">
          {{ identity.profileData.name }} - {{ identity.profileData.email }} -
          nickname:
          <cm-link
            href="{{ identity.profileData.html_url }}"
            label="{{ identity.profileData.nickname }}"
          ></cm-link>
        </span>
        - userId: {{ identity.user_id }}
      </span>
      <span *ngIf="identity.provider === 'samlp'">
        {{ identity.user_id }}
      </span>
    </li>
  </ul>
  <p *ngIf="tokenValidTo">token valid until {{ tokenValidToRepresentation }}</p>
  <p *ngIf="selectedUser && selectedUser.user_metadata">
    <span *ngIf="selectedUser.user_metadata.privacy"
      >hasAcknowledgedPrivacyNotice:
      {{
        selectedUser.user_metadata.privacy.hasAcknowledgedPrivacyNotice
      }},</span
    >
    <span *ngIf="selectedUser.user_metadata.cookies">
      hasOptedIntoPerformanceCookies:
      {{ selectedUser.user_metadata.cookies.hasOptedIntoPerformanceCookies }}
    </span>
  </p>
</cm-modal>

<cm-modal
  #orgMembershipsModal
  headline="Orgs {{ selectedUser ? selectedUser.name : '' }} is part of"
  confirmLabel="Close"
>
  <cm-text>Memberships this user has: </cm-text><br />
  <ul *ngIf="selectedUser && selectedUserOrgs">
    <li *ngFor="let userorg of selectedUserOrgs">
      <cm-button
        appearance="link"
        (cmPress)="goToOrg(userorg.uuid)"
        [label]="userorg.name"
      ></cm-button>
    </li>
  </ul>
</cm-modal>

<cm-modal
  #addExistingUserModal
  headline="Add existing user to this org"
  cancelLabel="Cancel"
  confirmLabel="Do it"
  [confirmDisabled]="!selectedUserToAdd || !inviteRoleFormControl.valid"
>
  <cm-text>Search for an existing user</cm-text><br />
  <input
    matInput
    placeHolder="search"
    [(ngModel)]="existingUserSearch"
    (keyup)="findUser()"
  /><br />
  <select (change)="selectUserToAdd($event)">
    <option></option>
    <option
      *ngFor="let foundUser of foundUsers$ | async"
      [value]="foundUser.user_id"
    >
      {{ foundUser.name }} - {{ foundUser.email }}
    </option>
  </select>

  <cm-text>for role</cm-text>
  <div class="radio">
    <input type="radio" value="member" [formControl]="inviteRoleFormControl" />
    Member<br />
    <input type="radio" value="admin" [formControl]="inviteRoleFormControl" />
    Admin<br />
    <input type="radio" value="owner" [formControl]="inviteRoleFormControl" />
    Owner<br />
  </div>
</cm-modal>

<cm-modal
  #rolesModal
  headline="Roles of {{ userForRoles ? userForRoles.name : '' }}"
  confirmLabel="Close"
>
  <cm-loader *ngIf="updatingRoles"></cm-loader>
  <div *ngIf="!updatingRoles && userForRoles" class="checkbox flex-container-2">
    <span *ngFor="let role of allRoles">
      <input
        id="checkbox-{{ userForRoles.user_id }}-{{ role }}"
        type="checkbox"
        [disabled]="!activatedFeatures.admin.changeRole"
        [checked]="rolesOfUser.includes(role)"
        (click)="
          changeRole(userForRoles.user_id, role, !rolesOfUser.includes(role))
        "
      />
      <label for="checkbox-{{ userForRoles.user_id }}-{{ role }}">{{
        role
      }}</label>
    </span>
  </div>
</cm-modal>
