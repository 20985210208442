import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { M2MTokenRequests } from '../../../../commons/M2MTokenRequests.dto'
import { OrganizationDto } from '../../../../commons/Organization.dto'
import { environment } from '../../environments/environment'
import { AppSettingsService } from '../service/appSettings.service'
import { ClusterRestService } from '../service/cluster.rest.service'
import { OrgRestService } from '../service/org.rest.service'
import { UserRestService } from '../service/user.rest.service'

const RANGE_IN_HOURS = 24

@Injectable({
  providedIn: 'root',
})
export class AugmentedOrgsResolverService
  implements Resolve<OrganizationDto[]> {
  private orgs: Observable<OrganizationDto[]>
  private clusters: Observable<ClusterDto[]>
  private clusterStats: Observable<{
    clusters: Array<{
      uuid: string
      count: number
    }>
  }>
  private tokenRequests: Observable<M2MTokenRequests[]>

  constructor(
    private orgRestService: OrgRestService,
    private clusterRestService: ClusterRestService,
    private userRestService: UserRestService,
    private appSettingsService: AppSettingsService,
  ) {
    this.refreshAll()
  }
  public getAllOrgs() {
    return this.orgs
  }
  public getAllClusters() {
    return this.clusters
  }
  public getAllTokenRequests() {
    return this.tokenRequests
  }
  public refreshAll() {
    this.refreshOrgs()
    this.refreshClusters()
    this.refreshClusterStats()
  }
  public refreshOrgs() {
    this.orgs = this.orgRestService
      .listOrganizations(
        this.appSettingsService.settings.readOnlyNotExpiredOrgs,
      )
      .pipe(shareReplay())
  }
  public refreshClusters() {
    this.clusters = this.clusterRestService.getAllClusters().pipe(shareReplay())
  }
  public refreshClusterStats() {
    this.clusterStats = this.clusterRestService
      .getClusterStats('1w')
      .pipe(shareReplay())
    this.tokenRequests = this.userRestService
      .getM2MTokenRequests(RANGE_IN_HOURS)
      .pipe(shareReplay())
  }
  public resolve(): Observable<OrganizationDto[]> {
    return combineLatest(
      this.orgs,
      this.clusters,
      this.clusterStats,
      this.tokenRequests,
    ).pipe(
      map(([orgs, clusters, clusterStats, tokenRequests]) => {
        const reversedOrgs = orgs.reverse()
        const youngestOldtimer = new Date(
          new Date().getTime() -
            1000 * 60 * environment.orgIsAgedTresholdInMinutes,
        )
        // mark old orgs as old
        reversedOrgs.forEach((org) => {
          org.isOld = new Date(org.created) < youngestOldtimer
        })

        for (const cluster of clusters) {
          cluster.minAgeReached = new Date(cluster.created) < youngestOldtimer
        }

        reversedOrgs.forEach((org) => {
          const foundClustersOfOrg = clusters
            .filter((cluster) => cluster.ownerId === org.uuid)
            .map((cluster) => {
              const clusterStat = clusterStats.clusters.find(
                (currentClusterStat) =>
                  currentClusterStat.uuid === cluster.uuid,
              )
              cluster.createdWorkflowInstances = clusterStat
                ? clusterStat.count
                : 0
              cluster.hasSignsOfActivity =
                cluster.hasSignsOfActivity ||
                cluster.createdWorkflowInstances > 0

              const tokenRequestForCluster = tokenRequests.find(
                (tokenRequest) => tokenRequest.clusterId === cluster.uuid,
              )
              cluster.m2mTokenCount = tokenRequestForCluster
                ? tokenRequestForCluster.count
                : 0
              cluster.hasSignsOfActivity =
                cluster.hasSignsOfActivity || cluster.m2mTokenCount > 0
              return cluster
            })
          org.clusters = foundClustersOfOrg
          org.isInactive = org.clusters.length === 0

          AugmentedOrgsResolverService.calculateActivity(org)
        })

        return reversedOrgs
      }),
    )
  }

  private static calculateActivity(org: OrganizationDto) {
    const orgHasM2MChecked = org.clusters!.some(
      (cluster) => cluster.m2mTokenCount !== undefined,
    )
    const orgHasM2M = org.clusters!.some(
      (cluster) => cluster.m2mTokenCount! > 0,
    )
    const orgHasProcessInstancesChecked = org.clusters!.some(
      (cluster) => cluster.createdWorkflowInstances !== undefined,
    )
    const orgHasProcessInstances = org.clusters!.some(
      (cluster) => cluster.createdWorkflowInstances! > 0,
    )

    if (orgHasM2MChecked && orgHasProcessInstances) {
      org.isInactive = false
    }
    if (orgHasProcessInstancesChecked && orgHasM2M) {
      org.isInactive = false
    }
    if (orgHasM2MChecked && orgHasProcessInstancesChecked) {
      org.isInactive = !(orgHasM2M || orgHasProcessInstances)
    }
  }
}
