import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToConfig,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OrganizationDto } from '../../../../../../commons/Organization.dto'
import { ConfirmationModalService } from '../../../service/confirmation-modal.service'
import { NotificationService } from '../../../service/notification.service'
import { SalesPlanRestService } from '../../../service/salesplan.rest.service'

enum NonConstSalesPlanType {
  PAID = 'paid',
  PAID_CC = 'paid-cc',
  ENTERPRISE = 'enterprise',
  TRIAL = 'trial',
  FREE = 'free',
  FREE_TP_PAID_REQUEST = 'free-to-paid-request',
  IN_NEGOTIATION = 'in-negotiation',
  INTERNAL = 'internal',
}

@Component({
  selector: 'app-list-salesplans',
  templateUrl: './list-salesplans.component.html',
  styleUrls: ['./list-salesplans.component.scss'],
})
export class ListSalesplansComponent implements OnInit {
  status: 'ready' | 'processing' = 'ready'

  @ViewChild('createSalesPlanModal', { static: true, read: ElementRef })
  public createSalesPlanModal: ElementRef

  public salesPlanListCreateHandler: () => void

  newSalesPlanForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    version: new FormControl('', [Validators.required, Validators.min(0)]),
    type: new FormControl(false, [Validators.required]),
  })

  orgs: OrganizationDto[]

  salesPlans: SalesPlanDto[]
  clusterPlanTypes: ClusterPlanTypeDto[] = []
  salesPlanColumns = [
    {
      name: 'Name',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Type',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Version',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: '# Orgs',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'ChargebeeSite',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Subscription Configs',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: '', width: '35px' },
  ]
  salesPlanContent = []

  public salesPlanTypes = Object.values(NonConstSalesPlanType).filter(
    (salesplan) => salesplan !== NonConstSalesPlanType.ENTERPRISE,
  )

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private ngZone: NgZone,
    private salesPlanService: SalesPlanRestService,
    private modalService: ConfirmationModalService,
  ) {}

  ngOnInit(): void {
    this.orgs = this.route.snapshot.data.orgs
    this.salesPlans = this.route.snapshot.data.salesPlans.filter(
      (salesPlan) =>
        salesPlan.salesPlanType !== NonConstSalesPlanType.ENTERPRISE,
    )
    this.clusterPlanTypes = this.route.snapshot.data.allClusterPlanTypes
    this.updateEntityList()
    this.salesPlanListCreateHandler = () => this.openCreateSalesPlanModal()
  }

  openCreateSalesPlanModal() {
    this.status = 'processing'
    this.newSalesPlanForm.setValue({
      name: '',
      version: 0,
      type: this.salesPlanTypes[0],
    })
    this.ngZone.run(() => {
      ;(this.createSalesPlanModal.nativeElement as CmModal)
        .open()
        .then((result) => {
          if (result === 'confirm') {
            const salesPlanDto = this.createSalesPlanObject()
            this.salesPlanService
              .createSalesPlan(
                salesPlanDto.name,
                this.newSalesPlanForm.value.type,
                salesPlanDto.clusterPlanTypesToConfig,
                salesPlanDto.version,
              )
              .subscribe(
                (_) => {
                  this.salesPlanService.getSalesPlans().subscribe(
                    (salesPlans) => {
                      this.salesPlans = salesPlans.filter(
                        (salesplan) =>
                          salesplan.salesPlanType !==
                          NonConstSalesPlanType.ENTERPRISE,
                      )
                      this.updateEntityList()
                      this.status = 'ready'
                    },
                    (error) => {
                      this.notification.enqueueNotification({
                        headline: 'unable to create a new sales plan',
                        appearance: 'error',
                        description: JSON.stringify(error),
                      })
                      this.status = 'ready'
                    },
                  )
                },
                (_) => {
                  this.status = 'ready'
                },
              )
          } else {
            this.status = 'ready'
          }
        })
    })
  }

  goToProducts() {
    this.router.navigate([`products`])
  }

  private updateEntityList() {
    if (!this.salesPlans) {
      this.salesPlanContent = []
      return
    }
    this.salesPlanContent = this.salesPlans.map((salesPlan) => {
      return {
        onPress: () => {
          this.ngZone.run(() => {
            this.navigateToSalesPlanDetails(salesPlan.uuid)
          })
        },
        data: [
          {
            type: 'text',
            content: salesPlan.name,
          },
          {
            type: 'text',
            content: salesPlan.salesPlanType,
          },
          {
            type: 'text',
            content: salesPlan.version,
          },
          {
            type: 'text',
            content: this.orgs
              ? String(
                  this.orgs.filter(
                    (org) =>
                      org.organizationToSalesPlan?.salesPlan.uuid ===
                      salesPlan.uuid,
                  ).length,
                )
              : '0',
          },
          {
            type: 'text',
            content:
              salesPlan.subscriptionConfigs?.length > 0
                ? salesPlan.subscriptionConfigs[0].chargebeeSite
                : '',
          },
          {
            type: 'text',
            content:
              salesPlan.subscriptionConfigs?.length > 0
                ? `${salesPlan.subscriptionConfigs.length}`
                : 'no',
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: this.contextMenu(salesPlan),
              },
            ],
          },
        ],
      }
    })
  }

  private navigateToSalesPlanDetails(salesPlanId: string) {
    this.router.navigate([`salesplans/${salesPlanId}`])
  }

  private createSalesPlanObject(): SalesPlanDto {
    return ListSalesplansComponent.createSalesPlanObject(
      this.clusterPlanTypes,
      this.newSalesPlanForm,
    )
  }

  public static createSalesPlanObject(
    clusterPlanTypes: ClusterPlanTypeDto[],
    newSalesPlanForm: FormGroup,
  ): SalesPlanDto {
    const clusterPlanConfiguration: ClusterPlanTypesToConfig = {}
    clusterPlanTypes.forEach((clusterPlanType) => {
      clusterPlanConfiguration[clusterPlanType.uuid] = {
        maxReserved: 0,
        minReserved: 0,
        price: '$0',
      }
    })
    const salesPlan: SalesPlanDto = {
      name: newSalesPlanForm.value.name,
      version: newSalesPlanForm.value.version,
      clusterPlanTypesToConfig: {},
    }
    ;(salesPlan as any).clusterPlanTypeIdsToPlanType = {}
    clusterPlanTypes.forEach((clusterPlanType) => {
      if (
        !salesPlan.clusterPlanTypesToConfig ||
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] === undefined
      ) {
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] = {
          maxReserved: 0,
          minReserved: 0,
          price: '',
        }
      }
      ;(salesPlan as any).clusterPlanTypeIdsToPlanType[
        clusterPlanType.uuid
      ] = clusterPlanType
    })
    Object.keys(salesPlan.clusterPlanTypesToConfig).forEach((configKey) => {
      if (
        !clusterPlanTypes.find((clusterPlanType) => {
          return clusterPlanType.uuid === configKey
        })
      ) {
        delete salesPlan.clusterPlanTypesToConfig[configKey]
      }
    })
    return salesPlan
  }

  private contextMenu(salesPlan: SalesPlanDto) {
    const contextMenu: any[] = []

    contextMenu.push({
      label: `Delete`,
      isDangerous: true,
      handler: () =>
        this.ngZone.run(() => {
          this.status = 'processing'
          this.modalService.openModal({
            title: 'Delete Sales Plan',
            body: `Are you sure you want to delete Sales Plan "${salesPlan.name}"?`,
            cancelButton: {
              text: 'No',
              action: () => {
                this.status = 'ready'
              },
            },
            confirmButton: {
              text: 'Yes',
              type: 'primary',
              action: () => {
                this.salesPlanService.deleteSalesPlan(salesPlan.uuid).subscribe(
                  (_) => {
                    this.salesPlanService
                      .getSalesPlans()
                      .subscribe((salesPlans) => {
                        this.salesPlans = salesPlans.filter(
                          (salesPlan) =>
                            salesPlan.salesPlanType !==
                            NonConstSalesPlanType.ENTERPRISE,
                        )
                        this.updateEntityList()
                        this.status = 'ready'
                      })
                  },
                  (error) => {
                    this.notification.enqueueNotification({
                      headline: `Unable to delete Salesplan ${salesPlan.name}`,
                      appearance: 'error',
                      description: JSON.stringify(error),
                    })
                    this.status = 'ready'
                  },
                )
              },
            },
          })
        }),
    })

    contextMenu.push({
      label: `Show Metadata`,
      isDangerous: false,
      handler: () =>
        this.ngZone.run(() => {
          this.status = 'processing'
          this.modalService.openModal({
            bodyIsRaw: true,
            title: 'Sales Plan Metadata',
            body: salesPlan.metadata
              ? salesPlan.metadata.description
              : `No Metadata available. You can update the Metadata in the SalesPlan Details.`,
            cancelButton: {
              text: 'Cancel',
              action: () => {
                this.status = 'ready'
              },
            },
            confirmButton: {
              text: 'Thx',
              type: 'primary',
              action: () => {
                this.status = 'ready'
              },
            },
          })
        }),
    })

    return contextMenu
  }
}
