import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToConfig,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { Features } from '../../../../../../commons/Features'
import { OrganizationDto } from '../../../../../../commons/Organization.dto'
import { ConfirmationModalService } from '../../../service/confirmation-modal.service'
import { NotificationService } from '../../../service/notification.service'
import { SalesPlanRestService } from '../../../service/salesplan.rest.service'
import { SubscriptionConfigController } from './subscriptionConfig.controller'

export interface ClusterPlanTypeToConfigItem {
  name: string
  uuid: string
  clusterPlanTypeToConfig: {
    minReserved: number
    maxReserved: number
    price: string
  }
}

@Component({
  selector: 'app-salesplan',
  templateUrl: './salesplan.component.html',
  styleUrls: ['./salesplan.component.scss'],
})
export class SalesplanComponent implements OnInit {
  status: 'ready' | 'processing' = 'ready'

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  @ViewChild('modifyItemModal', { static: true, read: ElementRef })
  public modifyItemModal: ElementRef

  @ViewChild('selectSubsidiaryModal', { static: true, read: ElementRef })
  public selectSubsidiaryModal: ElementRef

  subscriptionConfigController = new SubscriptionConfigController()
  salesPlanNameFormControl = new FormControl()
  salesPlanDescriptionFormControl = new FormControl()

  clusterPlanTypeToConfigItemForm = new FormGroup({
    minReserved: new FormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
    ]),
    maxReserved: new FormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
    ]),
    price: new FormControl(''),
  })

  clusterPlanTypes: ClusterPlanTypeDto[]
  orgs: OrganizationDto[]

  salesPlan: SalesPlanDto
  salesPlanId: string
  subsidiaries: Array<{ name: string; site: string }> = []
  selectedSubsidiary: { name: string; site: string }
  subsidiaryOptions: Array<{ label: string; value: string }> = []
  selectedSubsidiaryFromModal: string

  selectedItem: ClusterPlanTypeToConfigItem

  activatedFeatures: Features

  modifyForce = false

  clusterPlanTypeColumns = [
    {
      name: 'Cluster Plan Type',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Region',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Min Reserved',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Max Reserved',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Price per Unit/Month',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: '', width: '35px' },
  ]
  clusterPlanTypeContent = []

  orgColumns = [
    {
      name: 'Org Name',
      width: '3fr',
      ellipsis: 'right',
    },
    {
      name: 'Created',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Amount of Clusters',
      width: '1fr',
      ellipsis: 'right',
    },
  ]
  orgContent = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private salesPlanService: SalesPlanRestService,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
  ) {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
  }

  ngOnInit() {
    this.orgs = this.route.snapshot.data.orgs
    this.subsidiaries = this.route.snapshot.data.subsidiaries
    this.subsidiaryOptions = this.subsidiaries.map((currentSubsidiary) => {
      return { label: currentSubsidiary.name, value: currentSubsidiary.site }
    })
    this.route.params.subscribe((params) => {
      this.salesPlanId = params.salesPlanId
      const salesPlans: SalesPlanDto[] = this.route.snapshot.data.salesPlans
      this.clusterPlanTypes = this.route.snapshot.data.allClusterPlanTypes
      this.refresh(salesPlans)
    })
  }

  public addSubscriptionConfig() {
    if (!this.selectedSubsidiary) {
      this.selectedSubsidiaryFromModal = this.subsidiaryOptions[0]?.value
      this.ngZone.run(() => {
        ;(this.selectSubsidiaryModal.nativeElement as CmModal)
          .open()
          .then((result) => {
            if (result === 'confirm') {
              const selectedSubsidiary = this.subsidiaries.find(
                (currentSubsidiary) =>
                  currentSubsidiary.site === this.selectedSubsidiaryFromModal,
              )
              this.selectedSubsidiary = selectedSubsidiary
              const id = this.subscriptionConfigController.addSubscriptionConfig(
                this.selectedSubsidiary.site,
              )
              this.refreshTabs(id)
            }
          })
      })
    } else {
      const id = this.subscriptionConfigController.addSubscriptionConfig(
        this.selectedSubsidiary.site,
      )
      this.refreshTabs(id)
    }
  }

  public saveSubscriptionConfigs() {
    this.ngZone.run(() => {
      this.status = 'processing'
      this.modalService.openModal({
        title: 'Save Subscription Configs',
        body: `Are you sure you want to update the Subscription Configs?`,
        cancelButton: {
          text: 'No',
          action: () => {
            this.status = 'ready'
          },
        },
        confirmButton: {
          text: 'Yes',
          type: 'primary',
          action: () => {
            this.notificationService.enqueueNotification({
              headline: `Updating Subscription Configs of Sales Plan ${this.salesPlan.name}`,
              appearance: 'info',
              description:
                'This change does not affect existing customer subscriptions',
            })
            this.salesPlanService
              .updateSubscriptionConfigs(
                this.salesPlan.uuid,
                this.subscriptionConfigController.subscriptionConfigs,
              )
              .subscribe((_) => {
                this.salesPlanService
                  .getSalesPlans()
                  .subscribe((salesPlans) => {
                    this.refresh(salesPlans)
                    this.notificationService.enqueueNotification({
                      headline: `Sales Plan ${this.salesPlan.name} updated`,
                      appearance: 'success',
                    })
                    this.status = 'ready'
                  })
              })
          },
        },
      })
    })
  }

  public removeLastSubscriptionConfig() {
    this.ngZone.run(() => {
      this.status = 'processing'
      this.modalService.openModal({
        title: `Remove Subscription Config`,
        body: `Are you sure you want to remove the last Subscription Config?`,
        cancelButton: {
          text: 'No',
          action: () => {
            this.status = 'ready'
          },
        },
        confirmButton: {
          text: 'Yes',
          type: 'primary',
          action: () => {
            this.subscriptionConfigController.removeLastSubscriptionConfig()
            this.refreshTabs()
            this.status = 'ready'
          },
        },
      })
    })
  }

  public resetSubscriptionConfigs() {
    this.ngZone.run(() => {
      this.status = 'processing'
      this.modalService.openModal({
        title: `Reset Subscription Configs`,
        body: `Are you sure you want to reset the Subscription Configs?`,
        cancelButton: {
          text: 'No',
          action: () => {
            this.status = 'ready'
          },
        },
        confirmButton: {
          text: 'Yes',
          type: 'primary',
          action: () => {
            const salesPlanSubscriptionConfigs = this.salesPlan
              .subscriptionConfigs
            this.subscriptionConfigController.updateSubscriptionConfigs(
              salesPlanSubscriptionConfigs,
            )
            this.refreshTabs()
            this.status = 'ready'
          },
        },
      })
    })
  }

  public refreshTabs(label: string = 'dummy') {
    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = label
  }

  public toggleModifyForce(event) {
    this.modifyForce = event.detail.isChecked
  }

  public saveSalesPlanInformation() {
    this.status = 'processing'
    this.ngZone.run(() => {
      this.modalService.openModal({
        title: 'Save SalesPlan Information',
        bodyIsRaw: true,
        body: `Are you sure you want to update the SalesPlan Information?`,
        cancelButton: {
          text: 'no thx',
          action: () => {
            this.status = 'ready'
          },
        },
        confirmButton: {
          text: 'yes please',
          type: 'danger',
          action: () => {
            this.salesPlanService
              .updateNameOrMetadata(this.salesPlan.uuid, {
                name: this.salesPlanNameFormControl.value,
                metadata: {
                  description: this.salesPlanDescriptionFormControl.value,
                },
              })
              .subscribe((_) => {
                this.salesPlanService
                  .getSalesPlans()
                  .subscribe((salesPlans) => {
                    this.refresh(salesPlans)
                    this.notificationService.enqueueNotification({
                      headline: `Sales Plan ${this.salesPlan.name} updated`,
                      appearance: 'success',
                    })
                    this.status = 'ready'
                  })
              })
          },
        },
      })
    })
  }

  private refresh(salesPlans: SalesPlanDto[]) {
    this.salesPlan = salesPlans.find(
      (currentSalesPlan) => currentSalesPlan.uuid === this.salesPlanId,
    )
    const salesPlanSubscriptionConfigs = this.salesPlan.subscriptionConfigs
    if (salesPlanSubscriptionConfigs?.length > 0) {
      this.selectedSubsidiary = this.subsidiaries.find(
        (currentSubsidiary) =>
          currentSubsidiary.site ===
          salesPlanSubscriptionConfigs[0].chargebeeSite,
      )
    }
    this.subscriptionConfigController.updateSubscriptionConfigs(
      salesPlanSubscriptionConfigs,
    )
    this.updateClusterPlanTypeEntityList()
    this.salesPlanNameFormControl.setValue(this.salesPlan.name)
    this.salesPlanDescriptionFormControl.setValue(
      this.salesPlan.metadata ? this.salesPlan.metadata.description : '',
    )
    if (this.salesPlan) {
      this.updateOrgListEntityList(this.salesPlan.uuid)
    }
  }

  private updateOrgListEntityList(salesPlanId: string) {
    this.orgContent = this.orgs
      .filter(
        (org) => org.organizationToSalesPlan?.salesPlan.uuid === salesPlanId,
      )
      .map((org) => {
        return {
          onPress: () => {
            this.ngZone.run(() => {
              this.router.navigate(['organizations', org.uuid])
            })
          },
          data: [
            {
              type: 'text',
              content: org.name,
            },
            {
              type: 'text',
              content: org.created,
            },
            {
              type: 'text',
              content: org.clusters ? String(org.clusters.length) : '0',
            },
          ],
        }
      })
  }

  private updateClusterPlanTypeEntityList() {
    const clusterPlanConfiguration: ClusterPlanTypesToConfig = {}
    const clusterPlanTypes = this.clusterPlanTypes
    clusterPlanTypes.forEach((clusterPlanType) => {
      clusterPlanConfiguration[clusterPlanType.uuid] = {
        maxReserved: 0,
        minReserved: 0,
        price: '$0',
      }
    })
    const salesPlan: SalesPlanDto = this.salesPlan
      ? this.salesPlan
      : {
          name: '',
          version: 0,
          clusterPlanTypesToConfig: {},
        }
    ;(salesPlan as any).clusterPlanTypeIdsToPlanType = {}
    clusterPlanTypes.forEach((clusterPlanType) => {
      if (
        !salesPlan.clusterPlanTypesToConfig ||
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] === undefined
      ) {
        salesPlan.clusterPlanTypesToConfig[clusterPlanType.uuid] = {
          maxReserved: 0,
          minReserved: 0,
          price: '',
        }
      }
      ;(salesPlan as any).clusterPlanTypeIdsToPlanType[
        clusterPlanType.uuid
      ] = clusterPlanType
    })
    Object.keys(salesPlan.clusterPlanTypesToConfig).forEach((configKey) => {
      if (
        !clusterPlanTypes.find((clusterPlanType) => {
          return clusterPlanType.uuid === configKey
        })
      ) {
        delete salesPlan.clusterPlanTypesToConfig[configKey]
      }
    })
    this.clusterPlanTypeContent = Object.keys(
      salesPlan.clusterPlanTypesToConfig,
    ).map((configKey) => {
      const clusterPlanTypeToConfig =
        salesPlan.clusterPlanTypesToConfig[configKey]
      const clusterPlanType = clusterPlanTypes.find(
        (currentClusterPlanType) => currentClusterPlanType.uuid === configKey,
      )
      return {
        data: [
          {
            type: 'text',
            content: clusterPlanType ? clusterPlanType.name : configKey,
          },
          {
            type: 'text',
            content: clusterPlanType
              ? clusterPlanType.k8sContext.name
              : 'unknown',
          },
          {
            type: 'text',
            content: String(clusterPlanTypeToConfig.minReserved),
          },
          {
            type: 'text',
            content: String(clusterPlanTypeToConfig.maxReserved),
          },
          {
            type: 'text',
            content: clusterPlanTypeToConfig.price,
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: this.contextMenu({
                  uuid: configKey,
                  name: clusterPlanType ? clusterPlanType.name : configKey,
                  clusterPlanTypeToConfig,
                }),
              },
            ],
          },
        ],
      }
    })
  }

  private contextMenu(item: ClusterPlanTypeToConfigItem) {
    const contextMenu: any[] = []

    contextMenu.push({
      label: `Modify Entry`,
      handler: () =>
        this.ngZone.run(() => {
          this.status = 'processing'
          this.selectedItem = item
          this.clusterPlanTypeToConfigItemForm.setValue({
            minReserved: item.clusterPlanTypeToConfig.minReserved,
            maxReserved: item.clusterPlanTypeToConfig.maxReserved,
            price: item.clusterPlanTypeToConfig.price
              ? item.clusterPlanTypeToConfig.price
              : '',
          })
          ;(this.modifyItemModal.nativeElement as CmModal)
            .open()
            .then((result) => {
              if (result === 'confirm') {
                this.salesPlan.clusterPlanTypesToConfig[
                  this.selectedItem.uuid
                ] = {
                  minReserved: this.clusterPlanTypeToConfigItemForm.value
                    .minReserved,
                  maxReserved: this.clusterPlanTypeToConfigItemForm.value
                    .maxReserved,
                  price: this.clusterPlanTypeToConfigItemForm.value.price,
                }
                this.salesPlanService
                  .modifySalesPlan(
                    this.salesPlan.uuid,
                    this.salesPlan.name,
                    this.salesPlan.clusterPlanTypesToConfig,
                    this.salesPlan.version,
                    this.modifyForce,
                  )
                  .subscribe(
                    (_) => {
                      this.salesPlanService.getSalesPlans().subscribe(
                        (salesPlans) => {
                          this.refresh(salesPlans)
                          this.status = 'ready'
                        },
                        (error) => {
                          this.notificationService.enqueueNotification({
                            headline: 'unable to refresh salesplan',
                            appearance: 'error',
                            description: JSON.stringify(error),
                          })
                          this.status = 'ready'
                        },
                      )
                    },
                    (error) => {
                      this.notificationService.enqueueNotification({
                        headline: 'unable to modify salesplan',
                        appearance: 'error',
                        description: JSON.stringify(error),
                      })
                      this.status = 'ready'
                    },
                  )
              } else {
                this.status = 'ready'
              }
            })
        }),
    })

    return contextMenu
  }
}
