<cm-entity-list
  headline="Sales Plans"
  createButtonLabel="Create new Sales Plan"
  [enableCreateButton]="activatedFeatures.salesPlan.create"
  [createHandler]="createHandler"
  [entities]="salesPlanEntities"
  [columns]="salesPlanColumns"
  style="min-height: 200px; margin-bottom: 150px"
></cm-entity-list>

<cm-modal
  #salesPlanModal
  headline="{{
    salesPlanModalData && salesPlanModalData.uuid
      ? 'Modify SalesPlan ' + salesPlanModalData.name
      : 'Create SalesPlan'
  }}"
  cancelLabel="Cancel"
  confirmLabel="{{ salesPlanModalConfirmLabel }}"
>
  <div>
    <h3>General</h3>
    <hr />
    <div>
      <div class="input-group__label-container">
        <label>Name</label>
      </div>
      <div>
        <input [(ngModel)]="salesPlanModalData.name" type="text" />
      </div>
    </div>
    <div>
      <div class="input-group__label-container">
        <label>Version</label>
      </div>
      <div>
        <input [(ngModel)]="salesPlanModalData.version" type="number" />
      </div>
    </div>
    <div>
      <select
        [(ngModel)]="salesPlanModalData.type"
        [disabled]="salesPlanModalData.uuid"
      >
        <option [ngValue]="null" disabled>Choose Type</option>
        <option *ngFor="let type of salesPlanTypes" [ngValue]="type">
          {{ type }}
        </option>
      </select>
    </div>
    <div>
      <input
        id="checkbox-internal"
        type="checkbox"
        [disabled]="salesPlanModalData.uuid"
        [(ngModel)]="salesPlanModalData.internal"
      />
      <label for="checkbox-internal">Internal</label>
    </div>

    <h3>Configure Visible Cluster Plan Types and Max Instances</h3>
    <hr />
    <table>
      <tr>
        <th>Cluster Plan Type</th>
        <th>Region</th>
        <th>minReserved</th>
        <th>maxReserved</th>
        <th>Price per unit/month</th>
      </tr>
      <tr *ngFor="let item of sortedClusterPlanUuids">
        <td>
          {{ salesPlanModalData.clusterPlanTypeIdsToPlanType[item].name }}
        </td>
        <td>
          {{
            salesPlanModalData.clusterPlanTypeIdsToPlanType[item].k8sContext
              .name
          }}
        </td>
        <td>
          <div>
            <div>
              <input
                style="width: 50px"
                min="0"
                [max]="
                  salesPlanModalData.clusterPlanTypesToConfig[item].maxReserved
                "
                type="number"
                [(ngModel)]="
                  salesPlanModalData.clusterPlanTypesToConfig[item].minReserved
                "
              />
            </div>
          </div>
        </td>
        <td>
          <div>
            <div>
              <input
                style="width: 50px"
                [min]="
                  salesPlanModalData.clusterPlanTypesToConfig[item].minReserved
                "
                type="number"
                [(ngModel)]="
                  salesPlanModalData.clusterPlanTypesToConfig[item].maxReserved
                "
              />
            </div>
          </div>
        </td>
        <td>
          <div>
            <div>
              <input
                style="width: 100px"
                type="text"
                [(ngModel)]="
                  salesPlanModalData.clusterPlanTypesToConfig[item].price
                "
              />
            </div>
          </div>
        </td>
      </tr>
    </table>

    <hr />
  </div>
</cm-modal>
