<cm-page #cmPage>
  <div class="cards">
    <cm-card>
      <h3 slot="header">Latest Users</h3>

      <p *ngFor="let user of latestUsers">
        <cm-text>{{ user.name }}</cm-text>
      </p>
    </cm-card>

    <cm-card>
      <h3 slot="header">Organizations</h3>

      <cm-datalist>
        <cm-datalist-item label="Total Organizations">
          <cm-text>{{ orgCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="New Orgs without Clusters">
          <cm-text>{{ newOrgNoClusterCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="New Orgs with Clusters">
          <cm-text>{{ newOrgWithClusterCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="Old Orgs with active Clusters">
          <cm-text>{{ oldOrgActiveClusterCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="Old Orgs without active Clusters">
          <cm-text>{{ newOrgWithClusterCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="Old Orgs without Clusters">
          <cm-text>{{ oldOrgNoClusterCount }}</cm-text>
        </cm-datalist-item>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Latest orgs</h3>

      <cm-datalist>
        <ng-container *ngFor="let org of latestOrgs">
          <cm-datalist-item label="{{ org.name }}">
            <ng-container *ngIf="!activatedFeatures.admin.searchForOrgs">
              <cm-text>{{ org.uuid }}</cm-text>
            </ng-container>
            <ng-container *ngIf="activatedFeatures.admin.searchForOrgs">
              <cm-button
                label="Inspect"
                appearance="link"
                (cmPress)="router.navigate(['/organizations/' + org.uuid])"
              ></cm-button>
            </ng-container>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Clusters</h3>

      <cm-datalist>
        <cm-datalist-item label="Total">
          <cm-text>{{ clusterCount }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="New & Inactive">
          <cm-text>{{ newAndInactiveClusters }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="New & Active">
          <cm-text>{{ newAndActiveClusters }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="Old & Inactive">
          <cm-text>{{ oldAndInactiveClusters }}</cm-text>
        </cm-datalist-item>

        <cm-datalist-item label="Old & Active">
          <cm-text>{{ oldAndActiveClusters }}</cm-text>
        </cm-datalist-item>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Datacenters</h3>

      <cm-datalist>
        <ng-container
          *ngFor="let datacenter of clustersByDatacenter | keyvalue"
        >
          <cm-datalist-item label="{{ datacenter.key }}">
            <cm-text>{{ datacenter.value }}</cm-text>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card *ngIf="bpmnModelMetrics">
      <h3 slot="header">BPMN Models</h3>

      <cm-datalist>
        <cm-datalist-item label="Total">
          <cm-text>{{ bpmnModelMetrics.all }}</cm-text>
        </cm-datalist-item>
        <cm-datalist-item label="Deployed">
          <cm-text>{{ bpmnModelMetrics.deployed }}</cm-text>
        </cm-datalist-item>
        <cm-datalist-item label="Not deployed">
          <cm-text>{{ bpmnModelMetrics.notdeployed }}</cm-text>
        </cm-datalist-item>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Top Logins (all)</h3>

      <cm-datalist>
        <ng-container *ngFor="let user of top5Users">
          <cm-datalist-item label="{{ user.name }}">
            <cm-text>{{ user.logins_count }}</cm-text>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Top Logins (non-Camundos)</h3>

      <cm-datalist>
        <ng-container *ngFor="let user of top5LoginsNonCamundos">
          <cm-datalist-item label="{{ user.name }}">
            <cm-text>{{ user.logins_count }}</cm-text>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Latest Logins (last 24h)</h3>

      <cm-datalist>
        <ng-container *ngFor="let login of latestLogins">
          <cm-datalist-item label="{{ login.username }}">
            <cm-text>{{ login.count }}</cm-text>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Sales Plans</h3>

      <cm-datalist>
        <ng-container *ngFor="let salesPlan of salesPlans | keyvalue">
          <cm-datalist-item label="{{ salesPlan.key.name }}">
            <cm-text>{{ salesPlan.value }}</cm-text>
          </cm-datalist-item>
        </ng-container>
      </cm-datalist>
    </cm-card>

    <cm-card>
      <h3 slot="header">Generations</h3>

      <ul>
        <li *ngFor="let generation of clusterGenerations | keyvalue">
          {{ generation.key }}
          <ul>
            <li>
              Total: <b>{{ generation.value.totalCount }}</b>
            </li>
            <li
              *ngFor="let datacenter of generation.value.datacenters | keyvalue"
            >
              {{ datacenter.key }}: <b>{{ datacenter.value }}</b>
            </li>
          </ul>
        </li>
      </ul>
    </cm-card>

    <cm-card>
      <h3 slot="header">Cluster Plan Types and Cluster Plans</h3>

      <ul>
        <li *ngFor="let clusterPlanType of clusterPlansTypes | keyvalue">
          {{ clusterPlanType.key }}:
          <b> {{ clusterPlanType.value.totalCount }} </b>
          <ul
            *ngFor="
              let clusterPlan of clusterPlanType.value.clusterPlans | keyvalue
            "
          >
            <li>
              {{ clusterPlan.key }}: <b> {{ clusterPlan.value.totalCount }} </b>
              <ul>
                <li
                  *ngFor="
                    let datacenter of clusterPlan.value.datacenters | keyvalue
                  "
                >
                  {{ datacenter.key }}: <b> {{ datacenter.value }}</b>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </cm-card>
  </div>
</cm-page>
