import { Component, ElementRef, ViewChild } from '@angular/core'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import {
  ModalOptions,
  ConfirmationModalService,
} from '../../service/confirmation-modal.service'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @ViewChild('modal', { read: ElementRef })
  public modalRef: ElementRef

  public currentModalOptions?: ModalOptions

  constructor(modalService: ConfirmationModalService) {
    modalService.modalRequest$.subscribe((modalOptions) => {
      this.openModal(modalOptions)
    })
  }

  private openModal(modalOptions: ModalOptions) {
    this.currentModalOptions = modalOptions
    if (!this.currentModalOptions!.confirmButton.type) {
      this.currentModalOptions!.confirmButton.type = 'main'
    }
    if (!this.currentModalOptions!.cancelButton.type) {
      this.currentModalOptions!.cancelButton.type = 'main'
    }
    // This is needed to allow the Modal to take focus, due to some stupid shit that materials menus are doing
    requestAnimationFrame(() => {
      ;(this.modalRef.nativeElement as CmModal).open().then((result) => {
        if (result === 'confirm') {
          modalOptions.confirmButton.action()
        } else {
          modalOptions.cancelButton.action()
        }
      })
    })
  }
}
