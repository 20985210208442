<cm-page #cmPage>
  <div slot="header">
    <h1>Product Items</h1>
  </div>
  <span
    *ngIf="
      subsidiaries && chargebeeEnabled && activatedFeatures.salesPlan.modify
    "
  >
    <cm-page-tab
      label="{{ subsidiary.name }}"
      *ngFor="let subsidiary of subsidiaries"
    >
      <app-list-item-prices
        [chargebeeSite]="subsidiary.site"
      ></app-list-item-prices>
    </cm-page-tab>
  </span>
  <span *ngIf="!chargebeeEnabled || !activatedFeatures.salesPlan.modify">
    Permissions missing
  </span>
</cm-page>
