import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { first } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ClientRestService {
  constructor(private http: HttpClient) {}

  public getClientsForCluster(orgId: string, clusterId: string) {
    return this.http
      .get<
        {
          clientId: string
          uuid: string
          name: string
          created: Date
          lastUsed: Date
          internal: boolean
          createdBy: string
          permissions: string[]
          cluster?: ClusterDto
        }[]
      >(
        `${environment.consoleBackendUrl}/api/orgs/${orgId}/clusters/${clusterId}/clients`,
      )
      .pipe(first())
  }
  public checkClientCredentials(clientId: string, clientSecret: string) {
    return this.http
      .get<{ valid: boolean }>(
        `${environment.consoleBackendUrl}/api/admin/clusterClients/check?clientId=${clientId}&clientSecret=${clientSecret}`,
      )
      .pipe(first())
  }
  public findClients(search: string) {
    return this.http
      .get<
        {
          clientId: string
          uuid: string
          name: string
          created: Date
          lastUsed: Date
          createdBy: string
          permissions: string[]
          cluster?: ClusterDto
        }[]
      >(
        `${environment.consoleBackendUrl}/api/admin/clusterClients/find?search=${search}`,
      )
      .pipe(first())
  }
}
