import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Features } from '../../../../../../commons/Features'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.scss'],
})
export class ProductItemsComponent implements OnInit {
  activatedFeatures: Features
  chargebeeEnabled = true
  subsidiaries: Array<{ name: string; site: string }> = []

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.subsidiaries = this.route.snapshot.data.subsidiaries
  }
}
