<cm-entity-list
  headline="Support Agents"
  [enableCreateButton]="
    activatedFeatures.supportAgent.addAgentsToOrg && !agentListLoading
  "
  createButtonLabel="Add support agent"
  [createHandler]="createHandler"
  [columns]="agentListColums"
  [entities]="agentListEntites"
  [loading]="agentListLoading"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">no agents assigned to this org</div>
</cm-entity-list>
<cm-modal
  #addAgentModal
  headline="Add Support Agents"
  cancelLabel="Cancel"
  confirmLabel="Add"
  [confirmDisabled]="!selectedAgent"
>
  <cm-text>Select one of those agents to add</cm-text><br />
  <select (change)="selectAgent($event)">
    <option></option>
    <option *ngFor="let agent of availableAgents" [value]="agent.user_id">
      {{ agent.name }} - {{ agent.email }}
    </option>
  </select>
</cm-modal>
