import { Component, NgZone, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { FormControl, FormGroup } from '@angular/forms'
import { OrgRestService } from '../../service/org.rest.service'
import { Features } from '../../../../../commons/Features'
import { InvitationDto } from '../../../../../commons/Invitation.dto'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'list-invites',
  templateUrl: './list-invites.component.html',
  styleUrls: ['./list-invites.component.scss'],
})
export class ListInvitesComponent implements OnInit {
  public allOrgs: OrganizationDto[]

  public filterForm: FormGroup = new FormGroup({
    search: new FormControl(),
  })

  public inviteColumns = [
    { name: 'Email', width: '3fr', ellipsis: 'right' },
    { name: 'Org', width: '1fr', ellipsis: 'right' },
    { name: 'Role', width: '1fr', ellipsis: 'right' },
    { name: 'Invited at', width: '1fr', ellipsis: 'right' },
    { name: '', width: '35px' },
  ]
  public inviteEntities = []
  public inviteListLoading = false
  public activatedFeatures: Features
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private orgService: OrgRestService,
    private modalService: ConfirmationModalService,
    private datePipe: DatePipe,
  ) {
    this.filterForm.valueChanges.subscribe((_) => {
      this.find()
    })
  }

  public ngOnInit(): void {
    this.allOrgs = this.route.snapshot.data.orgs
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
  }
  public find() {
    this.inviteListLoading = true
    const searchControl = this.filterForm.get('search')
    const email =
      searchControl?.value?.length > 4 ? searchControl.value : undefined
    if (!email) {
      this.inviteEntities = []
      this.inviteListLoading = false
    } else {
      this.orgService.findInvites(email).subscribe((invites) => {
        this.inviteEntities = invites.map((invite) => {
          let contextMenu = []
          if (this.activatedFeatures.admin.addToOrg) {
            contextMenu.push({
              label: 'Resend Invitation',
              handler: () => this.resendInvite(invite),
            })
          }
          if (this.activatedFeatures.admin.removeFromOrg) {
            contextMenu.push({
              label: 'Cancel Invitation',
              handler: () => this.cancelInvite(invite),
            })
          }
          contextMenu.push({
            label: 'Show Email Activity in Sendgrid',
            handler: () => {
              window.open(
                `https://app.sendgrid.com/email_activity?filters=%5B%7B%22val%22%3A%5B%22${encodeURIComponent(
                  invite.email,
                )}%22%5D%2C%22selectedFieldName%22%3A%22to_email%22%2C%22comparisonType%22%3A%22Contains%22%7D%5D&isAndOperator=true&page=1`,
                '_blank',
              )
            },
          })

          return {
            data: [
              { type: 'text', content: invite.email },
              {
                type: 'button',
                label: this.allOrgs.find((org) => org.uuid === invite.orgId)
                  ? this.allOrgs.find((org) => org.uuid === invite.orgId).name
                  : invite.orgId,
                onPress: () =>
                  this.ngZone.run(() =>
                    this.router.navigate([`organizations/${invite.orgId}`]),
                  ),
              },
              { type: 'text', content: invite.role },
              {
                type: 'text',
                content: this.datePipe.transform(invite.created, 'medium'),
              },
              {
                type: 'contextMenu',
                options: [
                  {
                    options: contextMenu,
                  },
                ],
              },
            ],
          }
        })
        this.inviteListLoading = false
      })
    }
  }

  public cancelInvite(invite: InvitationDto) {
    this.ngZone.run(() => {
      this.inviteListLoading = true
      this.modalService.openModal({
        title: 'Cancel Invitation',
        bodyIsRaw: true,
        body: `Are you sure you want to <font color="red">cancel</font> the invitation for <b>${invite.email}</b>?`,
        cancelButton: {
          text: 'no thx',
          action: () => {
            this.inviteListLoading = false
          },
        },
        confirmButton: {
          text: 'yes please',
          type: 'danger',
          action: () => {
            this.orgService
              .cancelInvite(invite.orgId, invite.uuid)
              .subscribe((_) => {
                this.find()
              })
          },
        },
      })
    })
  }
  public resendInvite(invite: InvitationDto) {
    this.ngZone.run(() => {
      this.inviteListLoading = true
      this.modalService.openModal({
        title: 'Resend Invitation',
        bodyIsRaw: true,
        body: `Are you sure you want to resend the inviation for <b>${invite.email}</b>?`,
        cancelButton: {
          text: 'no thx',
          action: () => {
            this.inviteListLoading = false
          },
        },
        confirmButton: {
          text: 'yes please',
          type: 'primary',
          action: () => {
            this.orgService
              .resendInvite(invite.orgId, invite.uuid)
              .subscribe((_) => {
                this.inviteListLoading = false
              })
          },
        },
      })
    })
  }
}
