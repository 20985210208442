import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-root',
  styleUrls: ['./root.component.scss'],
  templateUrl: './root.component.html',
})
export class RootComponent implements OnInit {
  public signup = undefined
  constructor() {}

  ngOnInit() {
    if (window.location.href.includes('/signup')) {
      this.signup = true
    } else {
      this.signup = false
    }
  }
}
