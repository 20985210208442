<cm-page class="email-verification">
  <cm-page-tab>
    <div
      class="verification-container invalid"
      *ngIf="
        verification && !verification.tokenValid && !verification.userVerified
      "
    >
      <h1>Email verification failed</h1>
      <cm-text
        >Unfortunately we can not verify your email right now, if the problem
        continues please contact
        <cm-link
          href="mailto:cloudbeta@camunda.com"
          label="cloudbeta@camunda.com"
        ></cm-link
        >.</cm-text
      >
      <cm-text>
        <cm-link
          (click)="forwardToLogin($event)"
          label="Go to Log in"
        ></cm-link>
      </cm-text>
    </div>
    <div
      class="verification-container already-set"
      *ngIf="
        verification && !verification.tokenValid && verification.userVerified
      "
    >
      <h1>Email verification failed</h1>
      <cm-text>
        This verification link is already used.
      </cm-text>
      <cm-text>
        <cm-link
          (click)="forwardToLogin($event)"
          label="Go to Log in"
        ></cm-link>
      </cm-text>
    </div>
  </cm-page-tab>
</cm-page>
<div class="loading" *ngIf="loading">
  <cm-loader></cm-loader>
</div>
