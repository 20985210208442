import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { UserDto } from '../../../../commons/User.dto'
import { UserRestService } from '../service/user.rest.service'

@Injectable({
  providedIn: 'root',
})
export class LatestUsersResolverService implements Resolve<UserDto[]> {
  private latestUsers: Observable<UserDto[]>
  constructor(private userService: UserRestService) {
    this.latestUsers = this.userService.getLatestUsers().pipe(shareReplay())
  }
  public resolve(): Observable<UserDto[]> {
    return this.latestUsers
  }
}
