<cm-page class="invitation-verification">
  <cm-page-tab>
    <div class="verification-container invalid" *ngIf="status === 'error'">
      <h1>Join Organization failed</h1>
      <cm-text>The invitation link is invalid.</cm-text>
      <cm-text>
        <cm-link
          (click)="forwardToLogin($event)"
          label="Go to Log in"
        ></cm-link>
      </cm-text>
    </div>
  </cm-page-tab>
</cm-page>
<div class="loading" *ngIf="status === 'verifying'">
  <cm-loader></cm-loader>
</div>
