import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { of } from 'rxjs'

export interface AppSettings {
  readOnlyNotExpiredOrgs: boolean
}

// eslint-disable-next-line no-shadow
export enum AppSetting {
  READ_ONLY_NOT_EXPIRED_ORGS = 'onlyNotExpiredOrgs',
}

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService implements Resolve<AppSettings> {
  public settings: AppSettings = {
    readOnlyNotExpiredOrgs: true,
  }

  constructor() {}

  public resolve() {
    return of(this.settings)
  }

  public setAppSetting(setting: AppSetting, enabled: boolean) {
    switch (setting) {
      case AppSetting.READ_ONLY_NOT_EXPIRED_ORGS:
        this.settings.readOnlyNotExpiredOrgs = enabled
        break
    }
  }
}
