import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { UserRestService } from '../service/user.rest.service'

@Injectable({
  providedIn: 'root',
})
export class AllRolesResolverService implements Resolve<string[]> {
  private allRoles: Observable<string[]>
  constructor(private userService: UserRestService) {
    this.allRoles = this.userService.getAllRoles().pipe(shareReplay())
  }
  public resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<string[]> {
    return this.allRoles
  }
}
