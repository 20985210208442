import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { OrganizationDto } from '../../../../commons/Organization.dto'
import { OrgRestService } from '../service/org.rest.service'
import { AugmentedOrgsResolverService } from './augmented-orgs.resolver'

@Injectable({
  providedIn: 'root',
})
export class OrgsResolverService implements Resolve<OrganizationDto[]> {
  constructor(
    private augmentedOrgResolverService: AugmentedOrgsResolverService,
  ) {}
  public resolve(): Observable<OrganizationDto[]> {
    return this.augmentedOrgResolverService.getAllOrgs()
  }
}
