import 'zone.js/dist/zone-error' // Included with Angular CLI.
export const environment = {
  production: false,
  integration: false,
  camundaCloudAudience: 'cloud.dev.ultrawombat.com',
  backendUrl: 'https://accounts.cloud.dev.ultrawombat.com',
  consoleBackendUrl: 'https://console.cloud.dev.ultrawombat.com',
  orgIsAgedTresholdInMinutes: 10,
  recaptchaSiteKey: '6LfTY8kUAAAAABi9ROv3XLXtvOt_PEK-KePHzsmD',
  googleSheetId: '1pAbU63RmsHB1gqcyV50bqA-nYdGAUlPkyXt9BV9Rs4k',
  auditBackendUrl: 'https://audit.cloud.dev.ultrawombat.com',
  grafana: 'https://grafana.internal.dev.ultrawombat.com',
  valueMetrics: {
    includedProcessInstances: 100,
    includedTaskUsers: 10,
  },
}
