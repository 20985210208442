import { Component, OnInit } from '@angular/core'
import { VerifyService } from '../../service/verify.service'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss'],
})
export class AcceptInviteComponent implements OnInit {
  public status: 'verifying' | 'accepted' | 'error'

  private orgId: string
  private inviteToken: string
  private userEmail: string

  constructor(
    private verifyService: VerifyService,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.status = 'verifying'
    this.orgId = this.route.snapshot.params.orgId
    this.inviteToken = this.route.snapshot.params.inviteToken
    this.checkInvitation()
  }

  private checkInvitation() {
    this.route.queryParams.subscribe((queryParams) => {
      this.userEmail =
        queryParams && queryParams.email ? queryParams.email : null
      this.verifyService
        .verifyInvitation(this.orgId, this.inviteToken, this.userEmail)
        .subscribe(
          (acceptInvitationDto) => {
            if (acceptInvitationDto.signupNeeded === true) {
              window.location.href = `${window.location.origin}/signup/${this.inviteToken}`
            } else if (acceptInvitationDto.alreadyMember === true) {
              const url = acceptInvitationDto.email
                ? `${environment.consoleBackendUrl}?email=${encodeURIComponent(
                    acceptInvitationDto.email,
                  )}`
                : `${environment.consoleBackendUrl}`
              window.location.href = url
            } else {
              this.status = 'error'
            }
          },
          (_) => {
            this.status = 'error'
          },
        )
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public forwardToLogin(event?) {
    if (event) {
      event.preventDefault()
    }
    const url = this.userEmail
      ? `${environment.consoleBackendUrl}?email=${encodeURIComponent(
          this.userEmail,
        )}`
      : `${environment.consoleBackendUrl}`
    window.location.href = url
  }
}
