import { Component, OnInit } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'

@Component({
  selector: 'app-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  public profile: any

  constructor(private authService: AuthService) {}

  public ngOnInit() {
    this.authService.user$.subscribe((userProfile) => {
      this.profile = userProfile
    })
  }
}
