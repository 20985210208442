<cm-modal
  *ngIf="currentModalOptions"
  #modal
  [headline]="currentModalOptions.title"
  cancelLabel="{{ currentModalOptions.cancelButton.text }}"
  cancelAppearance="{{ currentModalOptions.cancelButton.type }}"
  confirmLabel="{{ currentModalOptions.confirmButton.text }}"
  confirmAppearance="{{ currentModalOptions.confirmButton.type }}"
>
  <cm-text *ngIf="!currentModalOptions.bodyIsRaw">
    {{ currentModalOptions.body }}
  </cm-text>
  <div
    style="white-space: pre-line"
    *ngIf="currentModalOptions.bodyIsRaw"
    [innerHtml]="currentModalOptions.body"
  ></div>
</cm-modal>
