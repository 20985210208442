<cm-entity-list
  [enableCreateButton]="activatedFeatures.enterprise.salesPlan.create"
  headline="Enterprise Plans"
  createButtonLabel="Create"
  [createHandler]="salesPlanListCreateHandler"
  [columns]="salesPlanColumns"
  [entities]="salesPlanContent"
  [loading]="status === 'processing'"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">No Enterprise Plans</div>
</cm-entity-list>

<cm-modal
  #createSalesPlanModal
  headline="Create new Enterprise Plan"
  cancelLabel="Cancel"
  confirmLabel="Create"
  [confirmDisabled]="!newSalesPlanForm.valid"
>
  <form [formGroup]="newSalesPlanForm">
    <label
      >Name<br />
      <input type="text" formControlName="name" />
    </label>
  </form>
</cm-modal>
<cm-modal
  #assignedOrgsModal
  headline="Orgs running with {{ assignedSalesPlan?.name }}"
  confirmLabel="Close"
>
  <ul *ngIf="assignedOrgs">
    <li *ngFor="let org of assignedOrgs">
      <cm-button
        appearance="link"
        (cmPress)="goToOrg(org.uuid)"
        [label]="org.name"
      ></cm-button>
    </li>
  </ul>
  <cm-text *ngIf="!assignedOrgs || assignedOrgs.length === 0"
    >No orgs running with this plan</cm-text
  >
</cm-modal>
