import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, first, shareReplay } from 'rxjs/operators'
import { environment } from '../../environments/environment'

const CACHE_TTL = 1000 * 60 * 30 // 30mins

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptorService implements HttpInterceptor {
  private store: Map<string, Observable<HttpEvent<any>>> = new Map()
  private timestamps: Map<string, number> = new Map()

  constructor() {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const url = req.urlWithParams
    const method = req.method

    if (!this.useCache(req)) {
      this.invalidateRelevantCacheEntries()
      return next.handle(req)
    }

    const cachedObservable = this.cachedAndValid(url)
      ? this.store.get(url)
      : this.addToCacheAndReturn(req, next)

    return cachedObservable.pipe(first())
  }

  private cachedAndValid(url: string) {
    if (!this.store.has(url) || !this.timestamps.has(url)) {
      return false
    }
    return Date.now() - this.timestamps.get(url) < CACHE_TTL
  }

  // eslint-disable-next-line class-methods-use-this
  private useCache(req: HttpRequest<any>) {
    const isGetRequest = req.method === 'GET'
    const isEnabledOnStage = !environment.production
    const isCamundaCloudApi =
      req.url.startsWith(environment.backendUrl) ||
      req.url.startsWith(environment.consoleBackendUrl)
    const useCache = isGetRequest && isEnabledOnStage && isCamundaCloudApi
    return useCache
  }

  private invalidateRelevantCacheEntries() {
    this.store.clear()
    this.timestamps.clear()
  }

  private addToCacheAndReturn(req: HttpRequest<any>, next: HttpHandler) {
    this.store.set(
      req.urlWithParams,
      next.handle(req).pipe(
        filter((res) => res instanceof HttpResponse),
        shareReplay(1),
      ),
    )
    this.timestamps.set(req.urlWithParams, Date.now())
    return this.store.get(req.urlWithParams)
  }
}
