import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'
import { FeatureService } from '../service/feature.service'

@Injectable({
  providedIn: 'root',
})
export class OrgSearchGuard implements CanActivate {
  constructor(private featureService: FeatureService) {}

  public canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.featureService.activated$.pipe(
      map((features) => {
        return features.admin.searchForOrgs
      }),
    )
  }
}
