<cm-link
  href="https://{{ chargebeeSite }}.chargebee.com"
  label="Show Chargeebee Dashboard"
></cm-link>

<cm-entity-list
  [enableCreateButton]="false"
  [headline]="entityListHeadline"
  createButtonLabel="..."
  [columns]="itemPricesColumns"
  [entities]="itemPricesContent"
  [loading]="status === 'processing'"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">No Item Prices</div>
</cm-entity-list>

<cm-modal #itemPriceDetails headline="Item Price Details" confirmLabel="Close">
  <span *ngIf="selectedItemPrice">
    <cm-text
      >Details for <b>{{ selectedItemPrice.name }}</b></cm-text
    ><br />
    <ul>
      <li>
        <cm-text>Id: {{ selectedItemPrice.id }}</cm-text>
      </li>
      <li>
        <cm-text>Name: {{ selectedItemPrice.name }}</cm-text>
      </li>
      <li>
        <cm-text
          >Created:
          {{ selectedItemPrice.created_at * 1000 | date: 'short' }}</cm-text
        >
      </li>
      <li>
        <cm-text
          >Updated:
          {{ selectedItemPrice.updated_at * 1000 | date: 'short' }}</cm-text
        >
      </li>
      <li>
        <cm-text>Family Id: {{ selectedItemPrice.item_family_id }}</cm-text>
      </li>
      <li>
        <cm-text>Item Id: {{ selectedItemPrice.item_id }}</cm-text>
      </li>
      <li>
        <cm-text>External Name: {{ selectedItemPrice.external_name }}</cm-text>
      </li>
      <li>
        <cm-text>Pricing Model: {{ selectedItemPrice.pricing_model }}</cm-text>
      </li>
      <li>
        <cm-text
          >Price:
          <span *ngFor="let tier of selectedItemPrice.tiers; let last = last"
            >[{{ tier.starting_unit }},{{ tier.ending_unit }}]: {{ tier.price
            }}<span *ngIf="!last">, </span></span
          ></cm-text
        >
      </li>
      <li>
        <cm-text>Currency: {{ selectedItemPrice.currency_code }}</cm-text>
      </li>
      <li>
        <cm-text>Period Unit: {{ selectedItemPrice.period_unit }}</cm-text>
      </li>
    </ul>
    <span *ngIf="selectedItemPrice.metadata">
      <cm-text><b>Metadata:</b></cm-text>
      <ul>
        <li>
          <cm-text>Type: {{ selectedItemPrice.metadata.type }}</cm-text>
        </li>
        <li>
          <cm-text>Data: {{ selectedItemPrice.metadata.data }}</cm-text>
        </li>
      </ul>
    </span>
  </span>
</cm-modal>
