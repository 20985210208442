import { Injectable } from '@angular/core'
import jwtDecode from 'jwt-decode'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  Features,
  userFeaturesFromPermissions,
} from '../../../../commons/Features'
import { AuthService } from '@auth0/auth0-angular'

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public activated$: Observable<Features>
  constructor(private authService: AuthService) {
    this.activated$ = this.authService.getAccessTokenSilently().pipe(
      map((accessToken) => {
        let activated: Features = userFeaturesFromPermissions([])

        if (accessToken) {
          const decoded: any = jwtDecode(accessToken)
          if (decoded && decoded.permissions) {
            const permissions = decoded.permissions
            activated = userFeaturesFromPermissions(decoded.permissions)
          }
        }
        return activated
      }),
    )
  }
}
