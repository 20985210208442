<cm-button
  appearance="link"
  (cmPress)="goToProducts()"
  label="Show Chargebee Product Items"
></cm-button>

<cm-entity-list
  [enableCreateButton]="true"
  headline="Sales Plans"
  createButtonLabel="Create"
  [createHandler]="salesPlanListCreateHandler"
  [columns]="salesPlanColumns"
  [entities]="salesPlanContent"
  [loading]="status === 'processing'"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">No Sales Plans</div>
</cm-entity-list>

<cm-modal
  #createSalesPlanModal
  headline="Create new Sales Plan"
  cancelLabel="Cancel"
  confirmLabel="Create"
  [confirmDisabled]="!newSalesPlanForm.valid"
>
  <form [formGroup]="newSalesPlanForm">
    <label
      >Name<br />
      <input type="text" formControlName="name" /> </label
    ><br />
    <label>
      Version<br />
      <input min="0" type="number" formControlName="version" /> </label
    ><br />
    <label>
      Type<br />
      <select formControlName="type">
        <option [ngValue]="null" disabled>Choose Type</option>
        <option *ngFor="let type of salesPlanTypes" [value]="type">
          {{ type }}
        </option>
      </select>
    </label>
    <br />
  </form>
</cm-modal>
