import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export interface ModalButtonOptions {
  type?: 'main' | 'primary' | 'secondary' | 'danger'
  text: string
  action: Function
}

export interface ModalOptions {
  title: string
  body: string
  bodyIsRaw?: boolean
  confirmButton: ModalButtonOptions
  cancelButton: ModalButtonOptions
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  public modalRequest$: Subject<ModalOptions> = new Subject()

  constructor() {}

  public openModal(modalOptions: ModalOptions) {
    this.modalRequest$.next(modalOptions)
  }
}
