import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { BpmnModelMetrics } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { BpmnModelerRestService } from '../service/bpmnmodeler.rest.service'

@Injectable({
  providedIn: 'root',
})
export class BpmnModelMetricsResolver implements Resolve<BpmnModelMetrics> {
  constructor(private bpmnModelRestService: BpmnModelerRestService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<BpmnModelMetrics> {
    const orgId = route.paramMap.get('orgUuid')
    return this.bpmnModelRestService.getMetrics(orgId).pipe(shareReplay())
  }
}
