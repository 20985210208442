/* eslint-disable no-invalid-this */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { CookieService } from 'ngx-cookie-service'
import { SignupDto } from '../../../../../commons/Signup.dto'
import { environment } from '../../../environments/environment'
import { SignUpService } from '../../service/signup.service'
import { UserRestService } from '../../service/user.rest.service'
import { VerifyService } from '../../service/verify.service'

// eslint-disable-next-line no-shadow
export enum SignupStatus {
  BEFORE_SUBMIT = 'BEFORE_SUBMIT',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
  LOADING = 'LOADING',
  FAILURE_INVITATION = 'FAILURE_INVITATION',
  ENTERPRISE_CONNECTION = 'ENTERPRISE_CONNECTION',
}

const SHOW_PASSWORD = 'show'
const HIDE_PASSWORD = 'hide'

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public allValidators = new Map<string, { valid: boolean; validator: any }>()

  public firstNameFormControl = new FormControl('', [Validators.required])
  public lastNameFormControl = new FormControl('', [Validators.required])
  public emailFormControl = new FormControl('', [Validators.required])
  public companyFormControl = new FormControl('', [Validators.required])
  public jobFunctionFormControl = new FormControl('', [Validators.required])
  public motivation = new FormControl('', [Validators.required])

  public pwControl = new FormControl('', [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(64),
    this.createPasswordStrengthValidator(),
  ])

  @ViewChild('emailInput')
  public emailInputRef: ElementRef

  @ViewChild('passwordInput')
  public passwordInputRef: ElementRef

  public regularUpdatesCheck: boolean = false

  public status: SignupStatus = SignupStatus.BEFORE_SUBMIT
  public userId: string
  public orgId: string
  public userEmail: string
  public showPasswordStrength: boolean = false
  public focusedElement: string
  public passwordInputType = 'password'
  public passwordEyeIconSource = SHOW_PASSWORD
  private inviteToken: string
  public campaign: string
  public consoleUrl = environment.consoleBackendUrl
  public showCawemoInfo: boolean = false

  createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value

      if (!value) {
        return null
      }

      const doesNotIncludeFirstName = !value
        .toLowerCase()
        .includes(this.firstNameFormControl.value.toLowerCase())
      const doesNotIncludeLastName = !value
        .toLowerCase()
        .includes(this.lastNameFormControl.value.toLowerCase())
      const doesNotIncludeEmail = !value
        .toLowerCase()
        .includes(this.emailFormControl.value.toLowerCase())

      const passwordValid =
        doesNotIncludeFirstName && doesNotIncludeLastName && doesNotIncludeEmail

      return !passwordValid ? { passwordStrength: true } : null
    }
  }

  constructor(
    private signupService: SignUpService,
    private cookieService: CookieService,
    private verifyService: VerifyService,
    private userRestService: UserRestService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  public ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams && queryParams.campaign) {
        this.campaign = queryParams.campaign
      }
    })

    this.route.params.subscribe((params) => {
      this.inviteToken = params.inviteToken

      if (this.inviteToken) {
        this.status = SignupStatus.LOADING

        this.route.queryParams.subscribe((queryParams) => {
          if (queryParams && queryParams.email && queryParams.orgId) {
            this.userEmail = queryParams.email
            this.orgId = queryParams.orgId
            this.verifyService
              .getStatusOfInviteToken(
                this.inviteToken,
                queryParams.orgId,
                queryParams.email,
              )
              .subscribe(
                (response) => {
                  if (response.alreadyMember) {
                    window.location.href = `${
                      environment.consoleBackendUrl
                    }?email=${encodeURIComponent(queryParams.email)}`
                  } else if (response.signupNeeded) {
                    this.userEmail = response.email
                    this.emailFormControl.setValue(this.userEmail)
                    this.status = SignupStatus.BEFORE_SUBMIT
                  }
                },
                (_) => {
                  this.status = SignupStatus.FAILURE_INVITATION
                },
              )
          }
        })
      }
    })

    this.pwControl.valueChanges.subscribe(() => {
      requestAnimationFrame(() => {
        if (this.passwordInputRef) {
          this.passwordInputRef.nativeElement.setCustomValidity('')
          this.passwordInputRef.nativeElement.reportValidity()
        }
      })
    })
    this.firstNameFormControl.valueChanges.subscribe(() => {
      requestAnimationFrame(() => {
        if (this.passwordInputRef) {
          this.passwordInputRef.nativeElement.setCustomValidity('')
          this.passwordInputRef.nativeElement.reportValidity()
        }
      })
    })
    this.lastNameFormControl.valueChanges.subscribe(() => {
      requestAnimationFrame(() => {
        if (this.passwordInputRef) {
          this.passwordInputRef.nativeElement.setCustomValidity('')
          this.passwordInputRef.nativeElement.reportValidity()
        }
      })
    })
    this.emailFormControl.valueChanges.subscribe(() => {
      requestAnimationFrame(() => {
        if (this.passwordInputRef) {
          this.passwordInputRef.nativeElement.setCustomValidity('')
          this.passwordInputRef.nativeElement.reportValidity()
        }
      })
    })
  }

  public toggleConsentRegularUpdates() {
    this.regularUpdatesCheck = !this.regularUpdatesCheck
  }

  public backToSignup(event) {
    event.preventDefault()

    this.status = SignupStatus.BEFORE_SUBMIT
  }

  public async emailAvailable(email: string) {
    const existingUsers = await this.userRestService
      .searchUsers({
        email,
      })
      .toPromise()

    return existingUsers.length === 0
  }

  public attemptCreateUser(recaptchaToken?: string) {
    this.pwControl.updateValueAndValidity()
    if (!this.pwControl.valid) {
      requestAnimationFrame(() => {
        this.passwordInputRef.nativeElement.setCustomValidity(
          'The password should be between 12 and 64 characters long, and must not contain your name or email',
        )
        this.passwordInputRef.nativeElement.reportValidity()
      })
      return
    }
    this.status = SignupStatus.LOADING
    this.createUser(recaptchaToken)
  }

  public createUser(recaptchaToken?: string) {
    this.status = SignupStatus.LOADING

    const first = this.firstNameFormControl.value
    const last = this.lastNameFormControl.value
    const email = this.emailFormControl.value
    const company = this.companyFormControl.value
    const jobFunction = this.jobFunctionFormControl.value
    const motivation = this.motivation.value
    const password = this.pwControl.value

    const campaign = this.campaign

    const context = {
      hutk: this.cookieService.get('hubspotutk'),
      pageUri: environment.backendUrl,
      pageName: 'Signup',
    }

    const data: SignupDto = {
      email,
      first,
      last,
      password,
      company,
      jobFunction,
      motivation,
      context,
      inviteToken: this.inviteToken ? this.inviteToken : null,
      recaptcha: recaptchaToken
        ? {
            token: recaptchaToken,
          }
        : undefined,
      consent: {
        regularUpdates: this.regularUpdatesCheck,
      },
      campaign,
    }

    this.signupService.signup(data).subscribe(
      (result) => {
        if (result.redirectToConsole) {
          window.location.href = `${
            environment.consoleBackendUrl
          }?email=${encodeURIComponent(this.emailFormControl.value)}`
        } else {
          this.status = SignupStatus.SUCCESS
          this.userId = result.userId
        }
      },
      (error) => {
        if (error.status === 409) {
          this.status = SignupStatus.BEFORE_SUBMIT
          requestAnimationFrame(() => {
            this.emailInputRef.nativeElement.setCustomValidity(
              'This Email is already in use.',
            )
            this.emailInputRef.nativeElement.reportValidity()
          })
        } else if (error.status === 412) {
          this.status = SignupStatus.ENTERPRISE_CONNECTION
          setTimeout(() => {
            window.location.href = `${
              environment.consoleBackendUrl
            }?email=${encodeURIComponent(this.emailFormControl.value)}`
          }, 5000)
        } else {
          this.status = SignupStatus.FAILURE
        }
      },
    )
  }

  public resendEmailVerification(event) {
    event.preventDefault()

    if (this.userId) {
      this.status = SignupStatus.RESEND_EMAIL_VERIFICATION
      this.signupService.resendEmailVerification(this.userId).subscribe(
        (_) => {
          setTimeout(() => {
            this.status = SignupStatus.SUCCESS
          }, 5000)
        },
        (_error) => {
          setTimeout(() => {
            this.status = SignupStatus.SUCCESS
          }, 5000)
        },
      )
    }
  }

  public changeJobFunction(newValue: string) {
    this.jobFunctionFormControl.setValue(newValue)
  }

  public changeMotivation(newValue: string) {
    this.motivation.setValue(newValue)
    this.showCawemoInfo = newValue === 'Process Modeling / Documentation'
  }
  public togglePasswordInputType() {
    if (this.passwordInputType === 'password') {
      this.passwordInputType = 'text'
      this.passwordEyeIconSource = HIDE_PASSWORD
    } else {
      this.passwordInputType = 'password'
      this.passwordEyeIconSource = SHOW_PASSWORD
    }
  }

  public forwardToLogin(event?) {
    if (event) {
      event.preventDefault()
    }

    const url = this.userEmail
      ? `${environment.consoleBackendUrl}?email=${encodeURIComponent(
          this.userEmail,
        )}`
      : `${environment.consoleBackendUrl}`

    window.location.href = url
  }

  public loginWithSocialProvider(connection: 'google-oauth2' | 'github') {
    const campaignParams = this.campaign ? `&campaign=${this.campaign}` : ''
    const target =
      this.orgId && this.inviteToken
        ? `redirect?organization=${this.orgId}&invitation=${this.inviteToken}${campaignParams}`
        : `redirect${campaignParams}`

    this.authService.loginWithRedirect({
      appState: {
        target,
      },
      connection,
    })
  }
  // eslint-disable-next-line class-methods-use-this
  public openOsano() {
    ;(window as any).Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
  }
}
