import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { SignupDto } from '../../../../commons/Signup.dto'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  private httpOptions = {
    headers: new HttpHeaders({}),
  }

  constructor(private http: HttpClient) {}

  public signup(
    data: SignupDto,
  ): Observable<{ userId: string; redirectToConsole: boolean }> {
    return this.http.post<{ userId: string; redirectToConsole: boolean }>(
      `${environment.backendUrl}/signup`,
      { data },
      this.httpOptions,
    )
  }

  public resendEmailVerification(userId: string) {
    return this.http.post(
      `${environment.backendUrl}/signup/resend`,
      { userId },
      this.httpOptions,
    )
  }
}
