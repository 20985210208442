import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { UserDto } from '../../../../commons/User.dto'
import { UserRestService } from '../service/user.rest.service'

@Injectable({
  providedIn: 'root',
})
export class TopLoginUsersResolverService implements Resolve<UserDto[]> {
  private topLogins: Observable<UserDto[]>
  constructor(private userService: UserRestService) {
    this.topLogins = this.userService.getTopLogins().pipe(shareReplay())
  }
  public resolve(): Observable<UserDto[]> {
    return this.topLogins
  }
}
