import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { LoginDto } from '../../../../commons/Login.dto'
import { UserRestService } from '../service/user.rest.service'
const RANGE_IN_HOURS = 24

@Injectable({
  providedIn: 'root',
})
export class LatestLoginsResolverService implements Resolve<LoginDto[]> {
  private logins: Observable<LoginDto[]>
  constructor(private userService: UserRestService) {
    this.logins = this.userService
      .getLatestLogins(RANGE_IN_HOURS)
      .pipe(shareReplay())
  }
  public resolve(): Observable<LoginDto[]> {
    return this.logins
  }
}
