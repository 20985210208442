import { SubscriptionConfig } from '@camunda-cloud/cloud-node-libs'
import {
  InputFilters,
  ItemPriceAssignment,
} from '../../subscription-management/list-item-prices/list-item-prices.component'

export class SubscriptionConfigController {
  public dirty: boolean = false
  public subscriptionConfigs: SubscriptionConfig[] = []
  public inputFiltersPerConfig: {
    [id: string]: {
      plan: InputFilters
      addons: InputFilters
      selection: InputFilters
      changeTs: number
    }
  } = {}

  public updateSubscriptionConfigs(subscriptionConfigs?: SubscriptionConfig[]) {
    this.subscriptionConfigs = []
    this.inputFiltersPerConfig = {}
    this.dirty = false
    if (!subscriptionConfigs) {
      return
    }
    this.subscriptionConfigs = JSON.parse(JSON.stringify(subscriptionConfigs))
    this.subscriptionConfigs.forEach((config) => {
      this.updateInputFilterPerConfig(config)
    })
  }

  public addSubscriptionConfig(chargebeeSite: string): string {
    const maxId = this.subscriptionConfigs.reduce((acc, config) => {
      return Math.max(acc, config.index)
    }, 0)
    const subscriptionConfig: SubscriptionConfig = {
      index: maxId + 1,
      id: `subscription ${maxId}`,
      chargebeeSite,
    }
    this.updateInputFilterPerConfig(subscriptionConfig)
    this.subscriptionConfigs.push(subscriptionConfig)
    this.dirty = true
    return subscriptionConfig.id
  }

  public removeLastSubscriptionConfig() {
    this.subscriptionConfigs.pop()
    this.subscriptionConfigs.forEach((config) => {
      this.updateInputFilterPerConfig(config)
    })
    this.dirty = true
  }

  public assignItemPrice(itemPriceAssignment: ItemPriceAssignment) {
    const foundSubscriptionConfig = this.subscriptionConfigs.find(
      (config) => config.id === itemPriceAssignment.subscriptionConfigId,
    )
    if (!foundSubscriptionConfig) {
      return
    }
    if (itemPriceAssignment.operation === 'add') {
      if (itemPriceAssignment.type === 'plan') {
        foundSubscriptionConfig.planItemPriceId =
          itemPriceAssignment.itemPrice.id
        this.inputFiltersPerConfig[
          itemPriceAssignment.subscriptionConfigId
        ].plan = SubscriptionConfigController.createInputConfig(
          'plan',
          foundSubscriptionConfig,
        )
      }
      if (itemPriceAssignment.type === 'addon') {
        if (!foundSubscriptionConfig.addons) {
          foundSubscriptionConfig.addons = []
        }
        if (
          !foundSubscriptionConfig.addons.find(
            (addon) => addon.itemPriceId === itemPriceAssignment.itemPrice.id,
          )
        ) {
          foundSubscriptionConfig.addons.push({
            itemPriceId: itemPriceAssignment.itemPrice.id,
            data: itemPriceAssignment.itemPrice.metadata?.data,
            type: itemPriceAssignment.itemPrice.metadata?.type,
          })
          this.inputFiltersPerConfig[
            itemPriceAssignment.subscriptionConfigId
          ].addons = SubscriptionConfigController.createInputConfig(
            'addons',
            foundSubscriptionConfig,
          )
        }
      }
    }
    if (itemPriceAssignment.operation === 'remove') {
      if (itemPriceAssignment.type === 'plan') {
        foundSubscriptionConfig.planItemPriceId = undefined
        this.inputFiltersPerConfig[
          itemPriceAssignment.subscriptionConfigId
        ].plan = SubscriptionConfigController.createInputConfig(
          'plan',
          foundSubscriptionConfig,
        )
      }
      if (itemPriceAssignment.type === 'addon') {
        if (!foundSubscriptionConfig.addons) {
          foundSubscriptionConfig.addons = []
        }
        foundSubscriptionConfig.addons = foundSubscriptionConfig.addons.filter(
          (addon) => addon.itemPriceId !== itemPriceAssignment.itemPrice.id,
        )
        this.inputFiltersPerConfig[
          itemPriceAssignment.subscriptionConfigId
        ].addons = SubscriptionConfigController.createInputConfig(
          'addons',
          foundSubscriptionConfig,
        )
      }
    }
    this.inputFiltersPerConfig[
      itemPriceAssignment.subscriptionConfigId
    ].selection = SubscriptionConfigController.createInputConfig(
      'selection',
      foundSubscriptionConfig,
    )
    this.inputFiltersPerConfig[
      itemPriceAssignment.subscriptionConfigId
    ].changeTs = Date.now()
    this.dirty = true
  }

  private updateInputFilterPerConfig(subscriptionConfig: SubscriptionConfig) {
    this.inputFiltersPerConfig[subscriptionConfig.id] = {
      plan: SubscriptionConfigController.createInputConfig(
        'plan',
        subscriptionConfig,
      ),
      addons: SubscriptionConfigController.createInputConfig(
        'addons',
        subscriptionConfig,
      ),
      selection: SubscriptionConfigController.createInputConfig(
        'selection',
        subscriptionConfig,
      ),
      changeTs: Date.now(),
    }
  }

  private static createInputConfig(
    type: 'plan' | 'addons' | 'selection',
    config: SubscriptionConfig,
  ): InputFilters {
    const inputFilters: InputFilters = {
      selection: type === 'selection',
      typeFilter: type === 'plan' ? 'plan' : 'addon',
      subscriptionConfig: config,
      changeTs: Date.now(),
    }
    return inputFilters
  }
}
