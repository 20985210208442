import { DatePipe } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuditDto,
  BpmnModelMetrics,
  ClusterPlanTypeDto,
  ClusterPlanTypesToReserved,
  SalesPlanDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject, combineLatest, from, of, Subject } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { ClusterDto } from '../../../../../commons/Cluster.dto'
import { Features } from '../../../../../commons/Features'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserSearchFilterDto } from '../../../../../commons/SearchFilter.dto'
import { UserDto } from '../../../../../commons/User.dto'
import { environment } from '../../../environments/environment'
import { AugmentedOrgsResolverService } from '../../resolver/augmented-orgs.resolver'
import { LatestLoginsResolverService } from '../../resolver/latest-logins.resolver'
import { AuditService } from '../../service/audit.service'
import { ClientRestService } from '../../service/client.rest.service'
import { ClusterRestService } from '../../service/cluster.rest.service'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { NavbarService } from '../../service/navbar.service'
import { NotificationService } from '../../service/notification.service'
import { OrgRestService } from '../../service/org.rest.service'
import { SalesPlanRestService } from '../../service/salesplan.rest.service'
import { SubscriptionManagementRestService } from '../../service/subscription-management.rest.service'
import { UserRestService } from '../../service/user.rest.service'

const AUDIT_PAGE_SIZE = 50

@Component({
  selector: 'organization-details-page',
  templateUrl: './organization-details.page.html',
  styleUrls: ['./organization-details.page.scss'],
})
export class OrganizationDetailsPage implements OnInit {
  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef
  public tabChangedTo: BehaviorSubject<string> = new BehaviorSubject('')
  public activatedFeatures: Features
  public org: OrganizationDto
  public allOrgs: OrganizationDto[]
  public allRoles: string[]
  public salesPlans: SalesPlanDto[]
  public userLogins: string[] = []
  public latestUsers: UserDto[] = []
  public audits: AuditDto[] = []
  public operateLogins: number = 0
  public consoleLogins: number = 0
  public bpmnModelMetrics: BpmnModelMetrics
  public environment = environment
  public organizationNameFormControl = new FormControl('', [
    Validators.required,
  ])
  public users: UserDto[] = []
  public users$ = new Subject<UserDto[]>()

  public showSelection = false
  public selected: string[] = []
  public showDeleteClustersModal = false
  public deleteClustersModal = {
    action: () => {},
  }

  public isInternal: boolean
  public changeReservations: boolean = false
  public processingChange: boolean = false
  public reservedControls: {
    [key: string]: FormControl
  }
  public noReservations = false

  public salesPlanLoading: boolean = false
  private allClusterPlanTypes: ClusterPlanTypeDto[] = []

  public clientIdToCheck: string
  public clientSecretToCheck: string
  @ViewChild('clientSecretCheckModal', { read: ElementRef })
  public modalRef: ElementRef

  @ViewChild('extendTrialModal', { read: ElementRef })
  public extendTrialModal: ElementRef
  public newExtendDate
  public newExendDateMinimum

  public valueMetricsColumns = [
    {
      name: 'Year/Month',
      width: '1fr',
      ellipsis: 'right',
    },
    { name: 'Process Instances (all)', width: '2fr', ellipsis: 'right' },
    { name: 'Process Instances (billable)', width: '2fr', ellipsis: 'right' },
    { name: 'Task Users (all)', width: '2fr', ellipsis: 'right' },
    { name: 'Task Users (billable)', width: '2fr', ellipsis: 'right' },
  ]
  public valueMetricsContent = []
  public subscriptions: Array<{
    subscriptionId: string
    subscription: any
    customer: any
  }> = []

  private auditPage = 0
  public moreAuditLogsAvailable = true

  @ViewChild('changeSalesPlanModal', { read: ElementRef })
  public changeSalesPlanModal: ElementRef
  public selectedNewSalesPlanName: string
  public forceChangeSalesPlan: boolean = false

  constructor(
    private augmentedOrgResolverService: AugmentedOrgsResolverService,
    private orgService: OrgRestService,
    private route: ActivatedRoute,
    private router: Router,
    private userRestService: UserRestService,
    private clusterService: ClusterRestService,
    private clientService: ClientRestService,
    private auditService: AuditService,
    private latestLoginsResolverService: LatestLoginsResolverService,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    private salesPlanService: SalesPlanRestService,
    private navbarService: NavbarService,
    private datePipe: DatePipe,
    private subscriptionManagementService: SubscriptionManagementRestService,
  ) {
    const minExtendDate = new Date()
    minExtendDate.setDate(
      minExtendDate.getDate() + (this.environment.production ? 8 : 1),
    )
    this.newExendDateMinimum = this.datePipe.transform(
      minExtendDate,
      'yyyy-MM-dd',
    )
  }

  public ngOnInit() {
    this.org = this.route.snapshot.data.org

    if (
      this.org.organizationToSalesPlan.salesPlan.salesPlanType ===
      SalesPlanType.ENTERPRISE
    ) {
      this.valueMetricsColumns[0].name = 'Term Start'
    }

    this.updateNoReservations()
    this.navbarService.switch(this.org)
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.bpmnModelMetrics = this.route.snapshot.data.bpmnModelMetrics

    this.isInternal =
      OrganizationDetailsPage.isInternalOrg(this.org) &&
      this.activatedFeatures.admin.addToOrg

    this.allOrgs = this.route.snapshot.data.allOrgs
    this.allClusterPlanTypes = this.route.snapshot.data.allClusterPlanTypes
    this.allRoles = this.route.snapshot.data.allRoles

    this.salesPlans = this.route.snapshot.data.salesPlans.filter(
      (salesplan) => salesplan.salesPlanType !== SalesPlanType.ENTERPRISE,
    )

    const detailsIndex = location.href.split('/').indexOf(this.org.uuid)

    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel
    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        const url = location.href
          .split('/')
          .slice(0, detailsIndex + 1)
          .join('/')

        switch (cmPage.activeLabel) {
          case 'Clusters':
            history.replaceState({}, '', `${url}/clusters`)
            this.tabChangedTo.next('clusters')
            break
          case 'Members':
            history.replaceState({}, '', `${url}/members`)
            this.tabChangedTo.next('members')
            break
          case 'Invites':
            history.replaceState({}, '', `${url}/invites`)
            this.tabChangedTo.next('invites')
            break
          case 'Support Agents':
            history.replaceState({}, '', `${url}/supportagents`)
            this.tabChangedTo.next('supportagents')
            break
          case 'Audit':
            history.replaceState({}, '', `${url}/audit`)
            this.tabChangedTo.next('audit')
            break
          case 'Value Metrics':
            history.replaceState({}, '', `${url}/valuemetrics`)
            this.tabChangedTo.next('valuemetrics')
            break
          default:
            history.replaceState({}, '', `${url}`)
            this.tabChangedTo.next('')
            break
        }
      })
    })

    this.refreshUserSearch()

    if (this.org.chargebeeSite && this.org.subscriptionIds?.length > 0) {
      this.org.subscriptionIds.map((subscriptionId) =>
        this.subscriptionManagementService
          .getSubscription(this.org.chargebeeSite, subscriptionId)
          .subscribe((response) => {
            this.subscriptions.push({
              subscriptionId,
              subscription: response.subscription,
              customer: response.customer,
            })
          }),
      )
    }

    combineLatest([
      this.users$,
      this.latestLoginsResolverService.resolve(),
      this.activatedFeatures.admin.readAudit
        ? this.auditService.getAuditLogs(this.org.uuid, {
            per_page: AUDIT_PAGE_SIZE,
            page: this.auditPage,
          })
        : of([] as AuditDto[]),
      this.activatedFeatures.admin.readAll
        ? this.orgService.getValueMetrics(this.org.uuid)
        : of([]),
    ]).subscribe(([users, logins, auditLines, valueMetrics]) => {
      this.users = users
      const latestUsers: UserDto[] = this.users.sort((user1, user2) => {
        if (user1.created_at < user2.created_at) {
          return 1
        }
        if (user1.created_at > user2.created_at) {
          return -1
        }
        return 0
      })
      this.latestUsers =
        latestUsers.length >= 0
          ? latestUsers.slice(0, 5)
          : latestUsers.slice(0, latestUsers.length)

      const orgLogins = logins.filter((login) =>
        this.users.map((user) => user.user_id).includes(login.userId),
      )
      const userLogins: string[] =
        orgLogins.length >= 5
          ? orgLogins.slice(0, 5).map((lgn) => lgn.userName)
          : orgLogins.slice(0, orgLogins.length).map((lgn) => lgn.userName)
      this.userLogins = [...new Set(userLogins.reverse())]

      this.operateLogins = orgLogins.filter((login) =>
        login.clientName.includes('Operate'),
      ).length
      this.consoleLogins = orgLogins.filter((login) =>
        login.clientName.includes('Console'),
      ).length

      this.addNextAuditLogs(auditLines)

      const includedValueMetrics = this.getIncludedValueMetrics()

      this.valueMetricsContent = valueMetrics.map((value) => {
        return {
          data: [
            {
              type: 'text',
              content:
                this.org.organizationToSalesPlan.salesPlan.salesPlanType ===
                SalesPlanType.ENTERPRISE
                  ? `${new Date(value.timestamp)
                      .toISOString()
                      .substring(0, 10)}`
                  : `${value.year}/${value.month < 10 ? '0' : ''}${
                      value.month
                    }`,
            },
            {
              type: 'text',
              content: `${value.processInstances}`,
            },
            {
              type: 'text',
              content: `${Math.max(
                0,
                value.processInstances - includedValueMetrics.processInstances,
              )}`,
              showCopyButton: true,
            },
            {
              type: 'text',
              content: `${value.taskUsers}`,
            },
            {
              type: 'text',
              content: `${Math.max(
                0,
                value.taskUsers - includedValueMetrics.taskUsers,
              )}`,
              showCopyButton: true,
            },
          ],
        }
      })
    })
    this.org.clusters.forEach((cluster) => {
      this.clientService
        .getClientsForCluster(this.org.uuid, cluster.uuid)
        .subscribe((clients) => {
          cluster.clients = clients
        })
    })
  }

  public refreshUserSearch() {
    const filter: UserSearchFilterDto = {
      organization: {
        uuid: this.org.uuid,
        member: true,
      },
    }
    this.userRestService.searchUsers(filter).subscribe((foundUsers) => {
      this.users$.next(foundUsers)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public isTrial(organization: OrganizationDto) {
    const isTrial =
      organization &&
      organization.organizationToSalesPlan &&
      organization.organizationToSalesPlan.salesPlan &&
      organization.organizationToSalesPlan.salesPlan.salesPlanType ===
        SalesPlanType.TRIAL
    return isTrial
  }

  public extendTrial() {
    ;(this.extendTrialModal.nativeElement as CmModal).open().then((result) => {
      if (result === 'confirm') {
        this.orgService
          .extendTrial(this.org.uuid, this.newExtendDate)
          .subscribe((_) => {
            this.augmentedOrgResolverService.refreshOrgs()
            this.router.navigate([`organizations/${this.org.uuid}`])
          })
      }
    })
  }

  public getClusterPlanTypeUsage(clusterPlanTypeUuid: string) {
    if (!this.org || !this.org.clusters) {
      return 0
    }
    return this.org.clusters.filter(
      (cluster) => cluster.planType.uuid === clusterPlanTypeUuid,
    ).length
  }

  public getClusterPlanTypeName(clusterPlanTypeUuid: string) {
    const cpt = this.allClusterPlanTypes.find(
      (cluster) => cluster.uuid === clusterPlanTypeUuid,
    )
    return cpt ? `${cpt.name} - ${cpt.k8sContext.name}` : ''
  }

  public toggleForceChangeSalesPlan(event) {
    this.forceChangeSalesPlan = event.detail.isChecked
  }

  public changeSalesPlan(orgId, salesPlanUuid) {
    if (
      !this.org ||
      !this.org.organizationToSalesPlan ||
      !this.org.organizationToSalesPlan.salesPlan ||
      this.org.organizationToSalesPlan.salesPlan.uuid !== salesPlanUuid
    ) {
      this.forceChangeSalesPlan = false
      this.selectedNewSalesPlanName =
        this.salesPlans?.find((salesplan) => salesplan.uuid === salesPlanUuid)
          ?.name ?? ''
      ;(this.changeSalesPlanModal.nativeElement as CmModal)
        .open()
        .then(async (result) => {
          if (result === 'confirm') {
            try {
              this.salesPlanLoading = true
              await this.orgService
                .changeSalesPlan(orgId, salesPlanUuid, {
                  force: this.forceChangeSalesPlan,
                })
                .toPromise()
              this.notificationService.enqueueNotification({
                headline: 'Successfully switched salesplan!',
                appearance: 'success',
              })
              this.org.organizationToSalesPlan.salesPlan.uuid = salesPlanUuid
              this.salesPlanLoading = false
            } catch (error) {
              this.salesPlanLoading = false
              this.notificationService.enqueueNotification({
                headline: 'SalesPlanSwitch did not work',
                appearance: 'error',
                description:
                  'sorry, could not switch salesplan. probably because the target salesplan does not support the currently used clusterplantypes',
              })
            }
            this.router.navigate([`organizations/${this.org.uuid}`])
          } else {
            this.salesPlanLoading = true
            this.router.navigate([`organizations/${this.org.uuid}`])
          }
        })
    }
  }

  public changeOrgName() {
    this.modalService.openModal({
      title: 'Change org name',
      bodyIsRaw: true,
      body: `Are you sure you want to change the orgname to <b>${this.organizationNameFormControl.value}</b>?`,
      cancelButton: {
        text: 'no',
        action: () => {
          this.organizationNameFormControl.setValue('')
        },
      },
      confirmButton: {
        text: 'yes',
        type: 'danger',
        action: async () => {
          await this.orgService
            .changeOrganizationName(
              this.org.uuid,
              this.organizationNameFormControl.value,
            )
            .toPromise()
          this.augmentedOrgResolverService.refreshOrgs()
          this.router.navigate([`organizations/${this.org.uuid}`])
        },
      },
    })
  }

  public updateSelection(clusterId: string) {
    if (this.selected.includes(clusterId)) {
      this.selected = this.selected.filter(
        (selectedClusterId) => selectedClusterId !== clusterId,
      )
    } else {
      this.selected.push(clusterId)
    }
  }

  public toggleShowSelection() {
    if (this.showSelection) {
      this.selected = []
      this.showSelection = false
    } else {
      this.showSelection = true
    }
  }

  public selectAllClusters() {
    if (this.selected.length > 0) {
      this.selected = []
    } else {
      this.selected =
        this.org.clusters && this.org.clusters.length > 0
          ? this.org.clusters.map((cluster) => {
              return cluster.uuid
            })
          : []
    }
  }

  public selectClustersByStatus(healthy: boolean) {
    if (!this.org.clusters || this.org.clusters.length === 0) {
      return
    }
    const clusters = healthy
      ? this.org.clusters.filter((cluster) => {
          return cluster.status && cluster.status.ready === 'Healthy'
        })
      : this.org.clusters.filter((cluster) => {
          return cluster.status && cluster.status.ready !== 'Healthy'
        })
    this.selected = clusters.map((cluster) => cluster.uuid)
  }

  public deleteClusterDialog() {
    const clusters: ClusterDto[] = this.org.clusters.filter((cluster) =>
      this.selected.includes(cluster.uuid),
    )
    this.modalService.openModal({
      title: 'Delete clusters',
      body: `Are you sure you want to delete ${clusters.length} clusters?`,
      confirmButton: {
        text: 'Börn',
        type: 'danger',
        action: () => {
          from(clusters)
            .pipe(
              mergeMap((cluster) => {
                return this.clusterService.deleteCluster(
                  this.org.uuid,
                  cluster.uuid,
                )
              }),
            )
            .subscribe(null, null, () => {
              this.org.clusters = this.org.clusters.filter(
                (cluster) => !this.selected.includes(cluster.uuid),
              )
              this.selected = []
            })
        },
      },
      cancelButton: {
        text: 'nope',
        type: 'secondary',
        action: () => {},
      },
    })
  }

  public updateNoReservations() {
    for (const key of Object.keys(
      this.org.organizationToSalesPlan.clusterPlanTypesToReserved,
    )) {
      if (
        this.org.organizationToSalesPlan.clusterPlanTypesToReserved[key] > 0
      ) {
        this.noReservations = false
        return
      }
    }
    this.noReservations = true
  }

  public reservationsToHumanReadable(raw: ClusterPlanTypesToReserved) {
    if (!raw) {
      return []
    }
    return Object.keys(raw)
      .map((key) => {
        return {
          clusterPlanType: this.allClusterPlanTypes.find(
            (clusterplanType) => clusterplanType.uuid === key,
          ),
          price:
            this.org.organizationToSalesPlan.salesPlan &&
            this.org.organizationToSalesPlan.salesPlan.clusterPlanTypesToConfig[
              key
            ]
              ? this.org.organizationToSalesPlan.salesPlan
                  .clusterPlanTypesToConfig[key].price
              : '',
          reservedCount: raw[key],
        }
      })
      .sort((a, b) => {
        if (!a.price) {
          return 1
        }
        if (!b.price) {
          return -1
        }
        const priceCompare = a.price.localeCompare(b.price)
        if (priceCompare !== 0) {
          return priceCompare
        }
        const nameCompare = a.clusterPlanType!.name.localeCompare(
          b.clusterPlanType!.name,
        )
        if (nameCompare !== 0) {
          return nameCompare
        }
        return a.clusterPlanType!.k8sContext.name.localeCompare(
          b.clusterPlanType!.k8sContext.name,
        )
      })
  }

  // eslint-disable-next-line class-methods-use-this
  public copyToClipboard(toClipboard: string) {
    return navigator.clipboard.writeText(toClipboard)
  }

  public showReservationChange() {
    if (this.activatedFeatures.admin.modifyClusterPlanTypeReservations) {
      this.reservedControls = {}
      Object.keys(
        this.org.organizationToSalesPlan.clusterPlanTypesToReserved,
      ).forEach((cpttr) => {
        this.reservedControls[
          cpttr
        ] = new FormControl(
          this.org.organizationToSalesPlan.clusterPlanTypesToReserved[cpttr],
          [Validators.required, Validators.min(0)],
        )
      })
      this.changeReservations = true
    }
  }

  public cancelReservationChange() {
    this.reservedControls = {}
    this.changeReservations = false
  }

  public saveReservations() {
    if (
      !this.activatedFeatures.admin.modifyClusterPlanTypeReservations ||
      !this.isReservationChangeValid()
    ) {
      return
    }
    this.processingChange = true
    const newReservations: ClusterPlanTypesToReserved = {}
    Object.keys(this.reservedControls).forEach(
      (newReservationClusterPlanTypeId) => {
        newReservations[
          newReservationClusterPlanTypeId
        ] = this.reservedControls[newReservationClusterPlanTypeId].value
      },
    )
    if (Object.keys(newReservations).length > 0) {
      this.salesPlanService
        .setReservedClusterPlanTypes(this.org.uuid, newReservations)
        .subscribe(
          (_) => {
            this.orgService.getOrganization(this.org.uuid).subscribe((org) => {
              this.org = org
              this.updateNoReservations()
              this.processingChange = false
              this.reservedControls = {}
              this.changeReservations = false
            })
          },
          (_) => {
            this.processingChange = false
            this.reservedControls = {}
            this.changeReservations = false
          },
        )
    }
  }

  public isReservationChangeValid() {
    for (const control of Object.values(this.reservedControls)) {
      if (!control.valid) {
        return false
      }
    }
    return true
  }

  public clustersConformToSalesplanAndReservations() {
    return this.reservationsToHumanReadable(
      this.org.organizationToSalesPlan.clusterPlanTypesToReserved,
    ).reduce((prev, cur) => {
      const usage = this.getClusterPlanTypeUsage(cur.clusterPlanType.uuid)
      return prev && usage <= cur.reservedCount
    }, true)
  }

  public openCheckClientSecret(clientIdToCheck: string) {
    this.clientIdToCheck = clientIdToCheck
    requestAnimationFrame(() => {
      ;(this.modalRef.nativeElement as CmModal).open().then((result) => {
        if (result === 'confirm') {
          this.clientService
            .checkClientCredentials(clientIdToCheck, this.clientSecretToCheck)
            .subscribe(
              (res) => {
                if (res.valid) {
                  this.notificationService.enqueueNotification({
                    headline: 'secret was good',
                    appearance: 'success',
                  })
                } else {
                  this.notificationService.enqueueNotification({
                    headline: 'secret was bad',
                    appearance: 'info',
                  })
                }
              },
              (_error) => {
                this.notificationService.enqueueNotification({
                  headline: 'secret was bad',
                  appearance: 'info',
                })
              },
            )

          this.clientSecretToCheck = undefined
        }
      })
    })
  }
  public static isInternalOrg(org: OrganizationDto) {
    return (
      org.organizationToSalesPlan &&
      org.organizationToSalesPlan.salesPlan &&
      org.organizationToSalesPlan.salesPlan.salesPlanType ===
        SalesPlanType.INTERNAL
    )
  }
  // eslint-disable-next-line class-methods-use-this
  public forbiddenSalesPlan(targetSalesplan: string) {
    return (
      ['Paid', 'Free', 'Trial - Legacy', 'Free to Paid Request'].includes(
        targetSalesplan,
      ) ||
      this.org.organizationToSalesPlan.salesPlan.salesPlanType ===
        SalesPlanType.ENTERPRISE ||
      targetSalesplan.toLowerCase().startsWith('[obsolete]')
    )
  }

  public goToEnterprisePlan(salesPlanId: string) {
    this.router.navigate([`enterprise/${salesPlanId}`])
  }

  public checkForBoost(cluster: ClusterDto) {
    this.clusterService
      .checkForAvailableBoost(cluster.uuid)
      .subscribe((available) => {
        if (available.availableClusterplan) {
          this.modalService.openModal({
            title: `Boost cluster ${cluster.name}`,
            bodyIsRaw: true,
            body: `Do you want to boost this cluster to <b>${available.availableClusterplan.name}</b>?`,
            cancelButton: {
              action: () => {},
              text: 'not today',
            },
            confirmButton: {
              text: 'do it',
              action: () => {
                this.clusterService.boost(cluster.uuid).subscribe(
                  (_) => {
                    this.notificationService.enqueueNotification({
                      headline: 'boost triggered',
                      appearance: 'success',
                      description: 'you may wanna reload this page in a second',
                    })
                  },
                  (error) => {
                    this.notificationService.enqueueNotification({
                      headline: 'boost triggered failed',
                      appearance: 'error',
                      description: 'you may wanna reload this page in a second',
                    })
                  },
                )
              },
            },
          })
        } else {
          this.notificationService.enqueueNotification({
            appearance: 'info',
            headline: 'No Boost available',
            description: `for cluster ${cluster.name}`,
          })
        }
      })
  }

  public nextAuditLogs() {
    if (!this.moreAuditLogsAvailable) {
      return
    }
    this.auditService
      .getAuditLogs(this.org.uuid, {
        per_page: AUDIT_PAGE_SIZE,
        page: this.auditPage,
      })
      .subscribe((nextAudits) => {
        this.addNextAuditLogs(nextAudits)
      })
  }

  private addNextAuditLogs(auditLogs: AuditDto[]) {
    const enrichedNextAudits = auditLogs.map((auditLog) => {
      const foundUser = this.users.find(
        (user) => user.user_id === auditLog.userId,
      )
      if (foundUser) {
        auditLog.userId = foundUser.email
      }
      return auditLog
    })
    if (enrichedNextAudits.length < AUDIT_PAGE_SIZE) {
      this.moreAuditLogsAvailable = false
    }
    this.auditPage++
    this.audits.push(...enrichedNextAudits)
  }

  private getIncludedValueMetrics(): {
    processInstances: number
    decisionInstances: number
    taskUsers: number
  } {
    const included = {
      processInstances: 0,
      decisionInstances: 0,
      taskUsers: 0,
    }
    switch (this.org.organizationToSalesPlan.salesPlan.salesPlanType) {
      case SalesPlanType.PAID:
      case SalesPlanType.PAID_CC:
        included.processInstances =
          environment.valueMetrics.includedProcessInstances
        included.taskUsers = environment.valueMetrics.includedTaskUsers
        included.decisionInstances = 0
        break
      case SalesPlanType.ENTERPRISE:
        included.processInstances = this.org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.processInstances
        included.taskUsers = this.org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.taskUsers
        included.decisionInstances = this.org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.decisionInstances
        break
    }

    return included
  }
}
