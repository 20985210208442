import { Injectable } from '@angular/core'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NavbarService {
  public $currentOrg: Observable<OrganizationDto>
  private $internalCurrentOrg = new BehaviorSubject<
    OrganizationDto | undefined
  >(undefined)
  constructor() {
    this.$currentOrg = this.$internalCurrentOrg.asObservable()
  }

  public switch(newOrg: OrganizationDto) {
    this.$internalCurrentOrg.next(newOrg)
  }
}
