import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInfoRestService {
  constructor(private http: HttpClient) {}

  public getAppInfo() {
    return this.http
      .get<any>(`${environment.backendUrl}/api/info`)
      .pipe(first());
  }
}
