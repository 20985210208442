import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { UserQueryParams } from '@camunda-cloud/camunda-cloud-idm'
import { OrganizationRole, SalesPlanType } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject, Subject } from 'rxjs'
import { Features } from '../../../../../commons/Features'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserDto } from '../../../../../commons/User.dto'
import { OrganizationDetailsPage } from '../../pages/organization-details/organization-details.page'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { NotificationService } from '../../service/notification.service'
import { UserRestService } from '../../service/user.rest.service'

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @Input()
  public org: OrganizationDto
  @Input()
  public users$: Subject<UserDto[]>
  @Input()
  public allOrgs: OrganizationDto[]
  @Output()
  public update = new EventEmitter()
  @Input()
  public allRoles: string[]

  public mayAddExistingUsers: boolean = false
  public createButtonLabel: string
  public emptySlot: string

  @Input()
  public memberListLoading = true

  public memberListColumns = [
    {
      name: 'Name',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'E-Mail',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Roles',
      width: '240px',
      ellipsis: 'right',
    },
    { name: 'Identities', width: '80px' },
    { name: 'Memberships', width: '100px' },
    { name: '', width: '35px' },
  ]
  public memberListEntites = []
  public addHandler: () => void

  // for detail modals
  public selectedUser: UserDto | undefined = undefined
  public selectedUserOrgs: OrganizationDto[] | undefined = undefined

  // identities
  @ViewChild('identitiesModal', { read: ElementRef })
  public identitiesModal: ElementRef
  public userVerified: boolean | undefined
  public tokenValid: boolean | undefined
  public tokenValidTo: number | undefined
  public tokenValidToRepresentation: string | undefined

  @ViewChild('orgMembershipsModal', { read: ElementRef })
  public orgMembershipsModal: ElementRef

  // add existing user
  public existingUserSearch: string | undefined
  public foundUsers$ = new BehaviorSubject<UserDto[]>([])
  public selectedUserToAdd: UserDto | undefined = undefined
  public inviteRoleFormControl = new FormControl('', [Validators.required])

  // roles modal
  @ViewChild('rolesModal', { read: ElementRef })
  public rolesModal: ElementRef
  public updatingRoles = true
  public userForRoles: UserDto | undefined
  public rolesOfUser: string[] | undefined

  @ViewChild('addExistingUserModal', { read: ElementRef })
  public addExistingUserModal: ElementRef

  activatedFeatures: Features

  constructor(
    private userService: UserRestService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
  ) {}
  public ngOnInit(): void {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.mayAddExistingUsers =
      this.org &&
      OrganizationDetailsPage.isInternalOrg(this.org) &&
      this.activatedFeatures.admin.addToOrg

    this.addHandler = () => {
      this.ngZone.run(() => {
        this.memberListLoading = true
        ;(this.addExistingUserModal.nativeElement as CmModal)
          .open()
          .then((result) => {
            if (result === 'confirm') {
              this.userService
                .addUserToOrganization(
                  this.selectedUserToAdd.user_id,
                  this.org.uuid,
                  this.inviteRoleFormControl.value,
                )
                .subscribe((_) => {
                  this.update.emit()

                  this.existingUserSearch = undefined
                  this.foundUsers$.next([])
                  this.selectedUserToAdd = undefined
                })
            } else {
              this.existingUserSearch = undefined
              this.foundUsers$.next([])
              this.selectedUserToAdd = undefined
              this.memberListLoading = false
            }
          })
      })
    }

    this.users$.subscribe((users) => {
      this.memberListLoading = true
      this.memberListEntites = users.map((user) => {
        let contextMenu = []

        contextMenu.push({
          label: 'Show Email Activity in Sendgrid',
          handler: () => {
            window.open(
              `https://app.sendgrid.com/email_activity?filters=%5B%7B%22val%22%3A%5B%22${encodeURIComponent(
                user.email,
              )}%22%5D%2C%22selectedFieldName%22%3A%22to_email%22%2C%22comparisonType%22%3A%22Contains%22%7D%5D&isAndOperator=true&page=1`,
              '_blank',
            )
          },
        })

        if (
          this.activatedFeatures.supportAgent.seeOrgMemberships &&
          this.getOrgRoles(user)
        ) {
          contextMenu.push(...this.getOrgRoleChangeOptions(user))

          if (
            this.userOrgRolesInGivenOrgRoles(
              user,
              this.getAvailableOrgRolesWithLegacy(),
            )
          ) {
            contextMenu.push({
              label: 'Upgrade to Owner',
              handler: () =>
                this.ngZone.run(() => {
                  this.memberListLoading = true
                  this.modalService.openModal({
                    title: 'Upgrade User to Owner?',
                    body: `Are you sure you want to upgrade "${user.name}" to Owner?`,
                    cancelButton: {
                      text: 'No',
                      action: () => {
                        this.memberListLoading = false
                      },
                    },
                    confirmButton: {
                      text: 'I am sure',
                      type: 'primary',
                      action: () => {
                        this.userService
                          .setUserAsOrgOwner(user.user_id, this.org.uuid)
                          .subscribe(
                            (_) => {
                              this.update.emit()
                              this.memberListLoading = false
                            },
                            (_) => {
                              this.memberListLoading = false
                            },
                          )
                      },
                    },
                  })
                }),
            })
          }
        }

        if (
          this.org &&
          (this.userOrgRolesInGivenOrgRoles(
            user,
            this.getAvailableOrgRolesWithLegacy().filter(
              (role) => role !== 'admin',
            ),
          ) ||
            (this.getOrgRoles(user).includes('admin') &&
              this.activatedFeatures.admin.removeFromOrg &&
              this.org.organizationToSalesPlan &&
              this.org.organizationToSalesPlan.salesPlan.salesPlanType ===
                SalesPlanType.INTERNAL) ||
            (this.getOrgRoles(user).includes(OrganizationRole.SUPPORTAGENT) &&
              this.activatedFeatures.supportAgent.removeAgentsFromOrg))
        ) {
          contextMenu.push({
            label: 'Remove from Organization',
            handler: () =>
              this.ngZone.run(() => {
                this.memberListLoading = true
                this.modalService.openModal({
                  title: 'Remove User from Organization?',
                  body: `Are you sure you want to remove "${user.name}" from this Organization?`,
                  cancelButton: {
                    text: 'No',
                    action: () => {
                      this.memberListLoading = false
                    },
                  },
                  confirmButton: {
                    text: 'I am sure',
                    type: 'danger',
                    action: () => {
                      this.userService
                        .removeUserFromOrganization(user.user_id, this.org.uuid)
                        .subscribe(
                          (_) => {
                            this.update.emit()
                            this.memberListLoading = false
                          },
                          (_) => {
                            this.memberListLoading = false
                          },
                        )
                    },
                  },
                })
              }),
          })
        }
        if (
          !user.email_verified &&
          UsersComponent.isTokenValid(user) === false &&
          this.activatedFeatures.admin.resetTokenVerification
        ) {
          contextMenu.push({
            label: 'Re-send Activation Email',
            handler: () =>
              this.ngZone.run(() => {
                this.modalService.openModal({
                  title: `Re-send Activation Email for "${user.name}"?`,
                  body: `Do you really want to re-send the Activation Email for '${user.name}' to "${user.email}"?`,
                  cancelButton: {
                    action: () => {},
                    text: 'No',
                  },
                  confirmButton: {
                    text: 'I am sure',
                    type: 'primary',
                    action: () => {
                      this.userService
                        .refreshVerificationTokenAndResendWelcomeMail(
                          user.user_id,
                        )
                        .subscribe((_) => {
                          this.notificationService.enqueueNotification({
                            headline: 'Successfully re-sent Activation email',
                            appearance: 'success',
                          })
                          this.memberListLoading = true
                          this.update.emit()
                        })
                    },
                  },
                })
              }),
          })
        }
        if (
          !user.email_verified &&
          this.activatedFeatures.admin.resetTokenVerification
        ) {
          contextMenu.push({
            label: 'Activate User',
            handler: () =>
              this.ngZone.run(() => {
                this.modalService.openModal({
                  title: `Activate Account for "${user.name}"?`,
                  body: `Do you really want to activate the account for "${user.name}" with email "${user.email}"?`,
                  cancelButton: {
                    action: () => {},
                    text: 'No',
                  },
                  confirmButton: {
                    text: 'I really want to',
                    type: 'primary',
                    action: () => {
                      this.userService
                        .activateUser(user.user_id)
                        .subscribe((_) => {
                          this.notificationService.enqueueNotification({
                            headline: 'Successfully activated account',
                            appearance: 'success',
                          })
                          this.memberListLoading = true
                          this.update.emit()
                        })
                    },
                  },
                })
              }),
          })
        }
        if (
          !user.email_verified &&
          this.activatedFeatures.admin.deleteNotActivatedUser
        ) {
          contextMenu.push({
            label: 'Delete not-activated User',
            isDangerous: true,
            handler: () => {
              this.ngZone.run(() => {
                this.modalService.openModal({
                  title: `Delete not yet activated user "${user.name}"?`,
                  body: `Are you sure you want to delete the user "${user.name}" who has not yet activated?`,
                  confirmButton: {
                    text: 'I am sure',
                    type: 'danger',
                    action: () => {
                      this.userService
                        .deleteNotActivatedUser(user.user_id)
                        .subscribe(() => {
                          this.notificationService.enqueueNotification({
                            headline: `Successfully deleted not yet activated account of "${user.name}"!`,
                            appearance: 'success',
                          })
                          this.memberListLoading = true
                          this.update.emit()
                        })
                    },
                  },
                  cancelButton: {
                    text: 'No',
                    action: () => {},
                  },
                })
              })
            },
          })
        }

        if (user.email_verified && this.activatedFeatures.admin.deleteUser) {
          contextMenu.push({
            label: 'Delete User',
            isDangerous: true,
            handler: () =>
              this.ngZone.run(() => {
                this.modalService.openModal({
                  title: `Delete User "${user.name}"?`,
                  body: `Are you sure you want to delete user "${user.name}"?`,
                  confirmButton: {
                    text: 'I am sure',
                    type: 'danger',
                    action: () => {
                      this.userService
                        .deleteUser(user.user_id)
                        .subscribe(() => {
                          this.notificationService.enqueueNotification({
                            headline: 'Successfully deleted Account!',
                            appearance: 'success',
                          })
                          this.memberListLoading = true
                          this.update.emit()
                        })
                    },
                  },
                  cancelButton: {
                    text: 'No',
                    type: 'secondary',
                    action: () => {},
                  },
                })
              }),
          })
        }

        return {
          data: [
            {
              type: 'text',
              content: UsersComponent.userHasVerificationMetadata(user)
                ? `[NOT ACTIVATED] ${user.name}`
                : user.name,
            },
            { type: 'text', content: user.email, showCopyButton: true },
            this.org
              ? {
                  type: 'text',
                  content:
                    UsersComponent.isTokenValid(user) === false
                      ? `[ACTIVATION TOKEN INVALID] ${this.getOrgRoles(
                          user,
                        ).join(', ')}`
                      : this.getOrgRoles(user).join(', '),
                }
              : {
                  type: 'button',
                  label:
                    UsersComponent.isTokenValid(user) === false
                      ? `[ACTIVATION TOKEN INVALID] Roles`
                      : 'Roles',
                  onPress: () => {
                    this.ngZone.run(() => {
                      this.updatingRoles = true
                      this.userForRoles = user
                      this.userService
                        .getUser(this.userForRoles.user_id, true)
                        .subscribe((userWithRoles) => {
                          this.rolesOfUser =
                            userWithRoles && userWithRoles.roles
                              ? userWithRoles.roles
                              : []
                          this.updatingRoles = false
                          ;(this.rolesModal.nativeElement as CmModal)
                            .open()
                            .then((_result) => {
                              this.userForRoles = undefined
                              this.rolesOfUser = undefined
                            })
                        })
                    })
                  },
                },
            {
              type: 'button',
              label: 'Identities',
              onPress: () => {
                this.ngZone.run(() => {
                  this.selectedUser = user
                  this.userVerified = this.selectedUser.email_verified

                  if (UsersComponent.userHasVerificationMetadata(user)) {
                    this.tokenValidTo = this.selectedUser.app_metadata.accounts.verification.validTo
                    this.tokenValid = UsersComponent.isTokenValid(user)
                    this.tokenValidToRepresentation = new Date(
                      this.tokenValidTo,
                    ).toISOString()
                  }

                  ;(this.identitiesModal.nativeElement as CmModal)
                    .open()
                    .then(() => {
                      this.userVerified = undefined
                      this.tokenValid = undefined
                      this.tokenValidTo = undefined
                      this.tokenValidToRepresentation = undefined
                    })
                })
              },
            },
            {
              type: 'button',
              label: 'Memberships',
              onPress: () => {
                this.ngZone.run(() => {
                  this.selectedUser = user
                  const usersOrgIds =
                    user.app_metadata && user.app_metadata.orgs
                      ? user.app_metadata.orgs.map((uorg) => uorg.id)
                      : []

                  this.selectedUserOrgs = this.allOrgs.filter((forg) =>
                    usersOrgIds.includes(forg.uuid),
                  )
                  ;(this.orgMembershipsModal.nativeElement as CmModal).open()
                })
              },
            },
            {
              type: 'contextMenu',
              options: [
                {
                  options: contextMenu,
                },
              ],
            },
          ],
        }
      })

      this.memberListLoading = false
    })
  }

  public goToOrg(orgUuid: string) {
    this.router.navigate([`organizations/${orgUuid}`])
  }

  public findUser() {
    const filter: UserQueryParams = {
      name: this.existingUserSearch,
      organization: { member: false, uuid: this.org.uuid },
    }

    if (this.existingUserSearch.length >= 3) {
      this.userService.searchUsers(filter).subscribe((user) => {
        this.foundUsers$.next(user)
      })
    } else {
      this.foundUsers$.next([])
    }
  }

  public selectUserToAdd(event) {
    this.selectedUserToAdd = this.foundUsers$.value.find(
      (fuser) => fuser.user_id === event.target.value,
    )
  }

  public async changeRole(userId: string, role: string, checked: boolean) {
    this.updatingRoles = true

    if (checked) {
      await this.userService.addRoleToUser(userId, role).toPromise()
    } else {
      await this.userService.removeRoleFromUser(userId, role).toPromise()
    }

    this.userService.getUser(userId, true).subscribe((userWithRoles) => {
      this.rolesOfUser =
        userWithRoles && userWithRoles.roles ? userWithRoles.roles : []
      this.updatingRoles = false
    })
  }

  private getOrgRoles(user: UserDto): string[] {
    return this.org && user.app_metadata && user.app_metadata.orgs
      ? user.app_metadata.orgs.find((forg) => forg.id === this.org.uuid).roles
      : undefined
  }

  // eslint-disable-next-line class-methods-use-this
  private getAvailableOrgRoles(): string[] {
    return [
      OrganizationRole.ADMIN,
      OrganizationRole.ANALYST,
      OrganizationRole.DEVELOPER,
      OrganizationRole.OPERATIONS_ENGINEER,
      OrganizationRole.TASK_USER,
      OrganizationRole.VISITOR,
    ].map((role) => String(role))
  }

  private getAvailableOrgRolesWithLegacy(): string[] {
    return [...this.getAvailableOrgRoles(), String(OrganizationRole.MEMBER)]
  }

  private userOrgRolesInGivenOrgRoles(
    user: UserDto,
    orgRoles: string[],
  ): boolean {
    const roles = this.getOrgRoles(user)
    for (const role of roles) {
      if (!orgRoles.includes(role)) {
        return false
      }
    }
    return true
  }

  private getOrgRoleChangeOptions(user: UserDto) {
    const roles = this.getOrgRoles(user)

    const addRoles: string[] = []
    const removeRoles: string[] = []

    const availableRoles: string[] = [
      OrganizationRole.ADMIN,
      OrganizationRole.ANALYST,
      OrganizationRole.DEVELOPER,
      OrganizationRole.OPERATIONS_ENGINEER,
      OrganizationRole.TASK_USER,
      OrganizationRole.VISITOR,
    ].map((role) => String(role))

    for (const availableRole of availableRoles) {
      if (roles.includes(availableRole)) {
        if (roles.length > 1) {
          removeRoles.push(availableRole)
        }
      } else {
        addRoles.push(availableRole)
      }
    }

    const contextMenu: any[] = []
    addRoles.forEach((role) => {
      contextMenu.push({
        label: `Add Org Role ${role}`,
        handler: () =>
          this.ngZone.run(() => {
            this.memberListLoading = true
            this.modalService.openModal({
              title: `Add Org Role ${role}?`,
              body: `Are you sure you want to add Org Role ${role} to "${user.name}"?`,
              cancelButton: {
                text: 'No',
                action: () => {
                  this.memberListLoading = false
                },
              },
              confirmButton: {
                text: 'I am sure',
                type: 'primary',
                action: () => {
                  this.userService
                    .setUserOrgRoles(user.user_id, this.org.uuid, [
                      ...roles,
                      role,
                    ])
                    .subscribe(
                      (_) => {
                        this.update.emit()
                        this.memberListLoading = false
                      },
                      (_) => {
                        this.memberListLoading = false
                      },
                    )
                },
              },
            })
          }),
      })
    })

    removeRoles.forEach((role) => {
      contextMenu.push({
        label: `Remove Org Role ${role}`,
        handler: () =>
          this.ngZone.run(() => {
            this.memberListLoading = true
            this.modalService.openModal({
              title: `Remove Org Role ${role}?`,
              body: `Are you sure you want to remove Org Role ${role} from "${user.name}"?`,
              cancelButton: {
                text: 'No',
                action: () => {
                  this.memberListLoading = false
                },
              },
              confirmButton: {
                text: 'I am sure',
                type: 'primary',
                action: () => {
                  this.userService
                    .setUserOrgRoles(
                      user.user_id,
                      this.org.uuid,
                      roles.filter((currentRole) => currentRole !== role),
                    )
                    .subscribe(
                      (_) => {
                        this.update.emit()
                        this.memberListLoading = false
                      },
                      (_) => {
                        this.memberListLoading = false
                      },
                    )
                },
              },
            })
          }),
      })
    })

    return contextMenu
  }

  private static userHasVerificationMetadata(user: UserDto) {
    return (
      user.app_metadata &&
      user.app_metadata.accounts &&
      user.app_metadata.accounts.verification &&
      user.app_metadata.accounts.verification.validTo
    )
  }

  private static isTokenValid(user: UserDto): boolean | undefined {
    if (this.userHasVerificationMetadata(user)) {
      return (
        user.app_metadata.accounts.verification.validTo > new Date().getTime()
      )
    }

    return undefined
  }
}
