<cm-page #cmPage>
  <div slot="header">
    <h1>Camunda Cloud Accounts Admin - {{ org.name}}</h1>
  </div>
  <!-- OVERVIEW -->
  <cm-page-tab label="Overview">
    <cm-banner
      appearance="warning"
      *ngIf="!clustersConformToSalesplanAndReservations()"
      >Clusters of this org do not conform to Salesplan. Salesplan or
      ClusterPlanType was probalbly force modified.</cm-banner
    >
    <div class="flex-container">
      <div
        class="card"
        *ngIf="org.organizationToSalesPlan?.salesPlan.salesPlanType === 'enterprise'"
      >
        <h3>Enterprise Plan</h3>
        <cm-banner> This org is running with an Enterprise Plan! </cm-banner>
        <dl>
          <dt>Enterprise Plan</dt>
          <dd>
            <cm-button
              appearance="link"
              (cmPress)="goToEnterprisePlan(org.organizationToSalesPlan?.salesPlan.uuid)"
              [label]="org.organizationToSalesPlan.salesPlan.name"
            ></cm-button>
          </dd>
          <dt>Startdate</dt>
          <dd>
            <b
              >{{ org.organizationToSalesPlan.enterpriseStartDate |
              date:"yyyy-MM-dd"}}</b
            >
          </dd>
          <dt>Duration</dt>
          <dd>
            <b>{{ org.organizationToSalesPlan.enterpriseDurationInMonth}}</b>
            months.
          </dd>
          <dt>Included Process Instances</dt>
          <dd>
            <b
              >{{
              org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.processInstances}}</b
            >
          </dd>
          <dt>Included Decision Instances</dt>
          <dd>
            <b
              >{{
              org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.decisionInstances}}</b
            >
          </dd>
          <dt>Included Task Users</dt>
          <dd>
            <b
              >{{
              org.organizationToSalesPlan.enterpriseIncludingValueMetrics?.taskUsers}}</b
            >
          </dd>
        </dl>
      </div>
      <!-- SALES PLAN -->
      <div
        class="card"
        *ngIf="org.organizationToSalesPlan && org.organizationToSalesPlan?.salesPlan.salesPlanType !== 'enterprise'"
        style="position: relative"
      >
        <div class="loader" *ngIf="salesPlanLoading">
          <cm-loader></cm-loader>
        </div>
        <h3>Sales Plan</h3>
        <div class="radio">
          <span *ngFor="let sp of salesPlans">
            <input
              type="radio"
              name="filterRadio"
              [id]="sp.uuid"
              [value]="sp.uuid"
              [disabled]="!activatedFeatures.salesPlan.switch || forbiddenSalesPlan(sp.name)"
              [checked]="
                org.organizationToSalesPlan &&
                org.organizationToSalesPlan.salesPlan.uuid === sp.uuid
              "
              (click)="changeSalesPlan(org.uuid, sp.uuid)"
            />
            <label for="{{ sp.uuid }}">{{ sp.name }}</label>
            <br />
          </span>
        </div>

        <a
          *ngIf="
            org.organizationToSalesPlan &&
            org.organizationToSalesPlan.billingSheetId
          "
          href="https://docs.google.com/spreadsheets/d/{{
            environment.googleSheetId
          }}/edit#gid={{ org.organizationToSalesPlan.billingSheetId }}"
          target="_blank"
          >billing details</a
        >
      </div>

      <!-- CLUSTER PLAN TYPE RESERVATIONS -->
      <div class="card" *ngIf="org.organizationToSalesPlan">
        <h3>Reservations and usage</h3>
        <div
          *ngFor="
          let cpttr of reservationsToHumanReadable(
            org.organizationToSalesPlan.clusterPlanTypesToReserved
          )
        "
        >
          <dl
            *ngIf="cpttr && cpttr.clusterPlanType && (cpttr.reservedCount > 0 || getClusterPlanTypeUsage(cpttr.clusterPlanType.uuid) > 0 || noReservations)"
          >
            <dt>
              {{ cpttr.clusterPlanType.name }} - {{
              cpttr.clusterPlanType.k8sContext.name }}
            </dt>
            <dd *ngIf="!changeReservations">
              Reserved: {{ cpttr.reservedCount }} Used: {{
              getClusterPlanTypeUsage(cpttr.clusterPlanType.uuid) }}
            </dd>
            <dd *ngIf="changeReservations">
              <input
                type="number"
                class="input-search"
                placeholder="{{ cpttr.reservedCount }}"
                [formControl]="reservedControls[cpttr.clusterPlanType.uuid]"
              />
            </dd>
          </dl>
        </div>

        <span *ngIf="activatedFeatures.admin.modifyClusterPlanTypeReservations">
          <cm-button
            label="Change Reservations"
            appearance="secondary"
            *ngIf="!changeReservations"
            (cmPress)="showReservationChange()"
          >
          </cm-button>
          <cm-button
            label="Save Reservations"
            appearance="danger"
            [disabled]="!isReservationChangeValid()"
            *ngIf="changeReservations"
            (cmPress)="saveReservations()"
          >
          </cm-button>
          <cm-button
            label="Cancel"
            appearance="secondary"
            *ngIf="changeReservations"
            (cmPress)="cancelReservationChange()"
          >
          </cm-button>
        </span>

        <div *ngIf="isTrial(org)">
          <h3>Trial Plan</h3>
          <dl>
            <dt>Expired</dt>
            <dd>{{ org.organizationToSalesPlan.trialStateExpired }}</dd>
          </dl>
          <dl>
            <dt>Start date</dt>
            <dd>
              {{ org.organizationToSalesPlan.trialPlanStartDate | date: 'medium'
              }}
            </dd>
          </dl>
          <dl>
            <dt>Expiration date</dt>
            <dd>
              {{ org.organizationToSalesPlan.trialExpirationDate | date:
              'medium' }}
            </dd>
          </dl>

          <cm-button
            label="Extend Trial"
            appearance="primary"
            [disabled]="!isTrial(org) || !activatedFeatures.admin.extendTrial"
            (cmPress)="extendTrial()"
          ></cm-button>
        </div>
      </div>

      <!-- PASSWORD NOT SET -->
      <div class="card" *ngIf="!org.organizationToSalesPlan">
        <h3>Sales Plan</h3>
        <p>User has not set a password yet</p>
      </div>

      <!-- CHARGEBEE CUSTOMER -->
      <div class="card" *ngIf="subscriptions[0]?.customer">
        <h3>Customer</h3>
        <p>
          <cm-link
            href="https://{{org.chargebeeSite}}.chargebee.com/d/customers/{{subscriptions[0].customer.id}}"
            label="Chargebee"
          ></cm-link>
        </p>
        <p>
          Subsidiary:
          <cm-link
            href="https://{{org.chargebeeSite}}.chargebee.com"
            label="{{org.chargebeeSite}}"
          ></cm-link>
        </p>
        <p>First Name: {{subscriptions[0].customer.first_name}}</p>
        <p>Last Name: {{subscriptions[0].customer.last_name}}</p>
        <p>
          Created: {{subscriptions[0].customer.created_at * 1000 | date:
          'short'}}
        </p>
      </div>

      <!-- CHARGEBEE SUBSCRIPTIONS -->
      <span *ngFor="let subscription of subscriptions">
        <div class="card">
          <h3>Subscription</h3>
          <p>
            <cm-link
              href="https://{{org.chargebeeSite}}.chargebee.com/d/subscriptions/{{subscription.subscription.id}}"
              label="Chargebee"
            ></cm-link>
          </p>
          <p>Currency: {{subscription.subscription.currency_code}}</p>
          <p>
            Created: {{subscription.subscription.created_at * 1000 | date:
            'short'}}
          </p>
          <p>
            Current Term End: {{subscription.subscription.current_term_end *
            1000 | date: 'short'}}
          </p>
          <p *ngFor="let item of subscription.subscription.subscription_items">
            {{item.item_price_id}} ({{item.item_type}})
          </p>
        </div>
      </span>

      <!-- LATEST USERS AND LOGINS -->
      <div class="card">
        <h3>Latest Users</h3>
        <span *ngIf="latestUsers.length > 0">
          <p *ngFor="let user of latestUsers">{{ user.name }}</p>
        </span>
        <h3>Latest Logins</h3>
        <span *ngIf="userLogins.length > 0">
          <p *ngFor="let login of userLogins">{{ login }}</p>
        </span>
        <span *ngIf="userLogins.length === 0"> No Logins </span>
        <h3>Logins by Application</h3>
        <p>Console Logins: <b>{{ consoleLogins }}</b></p>
        <p>Operate Logins: <b>{{ operateLogins }}</b></p>
      </div>

      <!-- BPMN MODELS -->
      <div class="card">
        <h3>BPMN Models</h3>
        <ul>
          <li># All: <b>{{ bpmnModelMetrics.all }}</b></li>
          <li># Deployed: <b>{{ bpmnModelMetrics.deployed }}</b></li>
          <li># Not deployed: <b>{{ bpmnModelMetrics.notdeployed }}</b></li>
        </ul>
      </div>
    </div>
    <div class="full-width-card" *ngIf="activatedFeatures.admin.changeOrgName">
      <h1>Change organization name</h1>
      <div>
        <input
          type="text"
          [placeholder]="org.name"
          [formControl]="organizationNameFormControl"
        />
      </div>
      <cm-button
        label="Change"
        appearance="primary"
        [disabled]="
          !organizationNameFormControl.valid ||
          organizationNameFormControl.value === org.name
        "
        (cmPress)="changeOrgName()"
      ></cm-button>
    </div>
    <cm-modal
      #extendTrialModal
      headline="Extend Trial"
      cancelLabel="Cancel"
      confirmLabel="Extend Trial"
    >
      <cm-text> Set the new expire date for this org: </cm-text>

      <input
        type="date"
        [(ngModel)]="newExtendDate"
        [min]="newExendDateMinimum"
      />
    </cm-modal>
  </cm-page-tab>
  <!-- CLUSTERS -->
  <cm-page-tab label="Clusters">
    <div class="flex-container">
      <div class="full-width-card">
        <h2>Clusters</h2>
        <div>
          <cm-button
            label="Toggle cluster selection"
            appearance="primary"
            (cmPress)="toggleShowSelection()"
            *ngIf="org.clusters && org.clusters.length > 0"
          ></cm-button>

          <cm-button
            label="Select all"
            (cmPress)="selectAllClusters()"
            *ngIf="showSelection && org.clusters && org.clusters.length > 0"
          ></cm-button>

          <cm-button
            label="Select healthy"
            (cmPress)="selectClustersByStatus(true)"
            *ngIf="showSelection && org.clusters && org.clusters.length > 0"
          ></cm-button>

          <cm-button
            label="Select unhealthy"
            (cmPress)="selectClustersByStatus(false)"
            *ngIf="showSelection && org.clusters && org.clusters.length > 0"
          >
          </cm-button>

          <cm-button
            label="Delete selected clusters"
            appearance="danger"
            (cmPress)="deleteClusterDialog()"
            *ngIf="
              activatedFeatures.admin.deleteClusters &&
              showSelection &&
              this.selected.length > 0
            "
          ></cm-button>
        </div>
        <span *ngIf="org.clusters">
          <div class="full-width-card" *ngFor="let cluster of org.clusters">
            <span>
              <div class="checkbox" *ngIf="showSelection">
                <input
                  id="checkbox-{{ cluster.uuid }}"
                  type="checkbox"
                  [checked]="selected.includes(cluster.uuid)"
                  (click)="updateSelection(cluster.uuid)"
                />
                <label for="checkbox-{{ cluster.uuid }}"></label>
              </div>
              <h4>{{ cluster.name }}</h4>
              <b *ngIf="cluster.hasSignsOfActivity">ACTIVE! </b>
              <b *ngIf="cluster.minAgeReached === false">NEW! </b>
              Healthy? {{ cluster.status ? cluster.status.ready : 'No status' }}
              - ({{ cluster.uuid }}
              <cm-icon-button
                icon="copy"
                (cmPress)="copyToClipboard(cluster.uuid)"
              ></cm-icon-button
              >)
              <br />
            </span>
            <span *ngIf="cluster.generation">
              generation: <b>{{ cluster.generation.name }}</b> (<cm-link
                href="{{environment.consoleBackendUrl}}/admin/versions/generations/{{cluster.generation.uuid}}"
                label="{{ cluster.generation.uuid }}"
              ></cm-link
              >)<br />
            </span>
            <span *ngIf="cluster.planType">
              clusterPlanType: <b>{{ cluster.planType.name }}</b> ({{
              cluster.planType.uuid }}) <br />
            </span>
            <span *ngIf="cluster.plan">
              clusterPlan: <b>{{ cluster.plan.name }}</b> (<cm-link
                href="{{environment.consoleBackendUrl}}/admin/cluster-plan/plans/{{cluster.plan.uuid}}"
                label="{{cluster.plan.uuid}}"
              ></cm-link
              >)<br *ngIf="activatedFeatures.admin.boostClusters" />
              <cm-button
                appearance="link"
                (cmPress)="checkForBoost(cluster)"
                label="check for available boost"
                *ngIf="activatedFeatures.admin.boostClusters"
              ></cm-button>
              <br />
            </span>
            <span *ngIf="cluster.k8sContext">
              datacenter: <b>{{ cluster.k8sContext.name }}</b> <br />
            </span>
            <span>created: {{ cluster.created }} </span> <br />
            <span
              >process instances (1w): {{ cluster.createdWorkflowInstances ?
              cluster.createdWorkflowInstances : 0 }}</span
            >
            <span>
              m2m tokens (24h): {{ cluster.m2mTokenCount ? cluster.m2mTokenCount
              : 0 }}</span
            ><br />
            <br />
            <h4>Clients</h4>
            <cm-loader *ngIf="cluster.clients === undefined"></cm-loader>
            <span *ngIf="cluster.clients && cluster.clients.length === 0"
              >no clients</span
            >
            <span *ngIf="cluster.clients && cluster.clients.length > 0">
              <div
                *ngFor="let client of cluster.clients"
                class="full-width-card"
              >
                name: {{ client.name }} <br />
                cliendId: {{ client.clientId }} <br />
                createdBy: {{ client.createdBy }} <br />
                createdAt: {{ client.created}} <br />
                permissions: {{ client.permissions.join(" ")}} <br />
                lastUncachedTokenIssueing: {{ client.lastUsed }} <br />
                <cm-button
                  label="checkPW"
                  (cmPress)="openCheckClientSecret(client.clientId)"
                ></cm-button>
              </div>
            </span>
          </div>
        </span>
      </div>
    </div>
  </cm-page-tab>
  <!-- MEMBERS -->
  <cm-page-tab label="Members">
    <users
      (update)="refreshUserSearch()"
      [org]="org"
      [users$]="users$"
      [allOrgs]="allOrgs"
    ></users>
  </cm-page-tab>
  <cm-page-tab label="Invites">
    <invites [org]="org" [users$]="users$" [allOrgs]="allOrgs"></invites>
  </cm-page-tab>
  <!-- SUPPORT AGENT -->
  <cm-page-tab label="Support Agents">
    <support-agents
      [org]="org"
      [users$]="users$"
      (update)="refreshUserSearch()"
    ></support-agents>
  </cm-page-tab>
  <!-- AUDIT -->
  <cm-page-tab label="Audit">
    <div class="flex-container">
      <div class="full-width-card" *ngIf="activatedFeatures.admin.readAudit">
        <h3>Audit</h3>

        <span *ngIf="audits && audits.length > 0">
          <table>
            <thead>
              <tr>
                <th>ts / user</th>
                <th>log</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let audit of audits">
                <td>
                  {{ audit.timestamp | date: 'medium' }}<br />{{ audit.userId }}
                </td>
                <td>{{ audit.audit }}</td>
              </tr>
            </tbody>
          </table>
          <cm-button
            label="Load more Audit Logs"
            (cmPress)="nextAuditLogs()"
            *ngIf="moreAuditLogsAvailable"
          ></cm-button>
        </span>
        <span *ngIf="audits && audits.length === 0">
          No Audit Logs available
        </span>
        <span *ngIf="!audits">
          <cm-loader></cm-loader>
        </span>
      </div>
    </div>
  </cm-page-tab>
  <!-- VALUE METRICS -->
  <cm-page-tab label="Value Metrics">
    <span *ngIf="activatedFeatures.admin.readAll">
      <cm-entity-list
        [enableCreateButton]="false"
        headline="Value Metrics"
        createButtonLabel="..."
        [columns]="valueMetricsColumns"
        [entities]="valueMetricsContent"
        style="min-height: 200px; margin-bottom: 150px"
      >
        <div slot="empty">No Value Metrics</div>
      </cm-entity-list>
      <div
        class="full-width-card"
        *ngIf="org.organizationToSalesPlan?.uniqueTaskUserHistory"
      >
        <h3>Unique Task Users in current Contract Term</h3>
        <div
          *ngFor="let taskUser of org.organizationToSalesPlan.uniqueTaskUserHistory"
        >
          {{ taskUser.userId }} - last claim reported: {{
          taskUser.lastClaimTimestamp | date: 'medium' }}
        </div>
      </div>
    </span>
  </cm-page-tab>
</cm-page>
<cm-modal
  #clientSecretCheckModal
  #modal
  headline="checking clientSecret for clientId {{ clientIdToCheck }}"
  cancelLabel="Cancel"
  confirmLabel="Check"
>
  <cm-text>put in the secret here</cm-text>
  <input [(ngModel)]="clientSecretToCheck" type="text" />

  <cm-button label="cancel" slot="cancel"></cm-button>
  <cm-button label="check" appearance="primary" slot="confirm"></cm-button>
</cm-modal>

<cm-modal
  #changeSalesPlanModal
  headline="Change Sales Plan"
  cancelLabel="not today!"
  confirmLabel="yes!"
  confirmAppearance="danger"
>
  <cm-text>
    <div>
      Are you sure you want to change the sales plan of <b>{{org.name}}</b> to
      <b>{{selectedNewSalesPlanName}}</b>?
    </div>
  </cm-text>

  <cm-toggle
    (cmInput)="toggleForceChangeSalesPlan($event)"
    [attr.checked]="forceChangeSalesPlan"
    label="FORCE!"
  ></cm-toggle>

  <cm-button label="not today" slot="cancel"></cm-button>
  <cm-button label="do it!!" appearance="danger" slot="confirm"></cm-button>
</cm-modal>
