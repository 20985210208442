import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Features } from '../../../../commons/Features';
import { FeatureService } from '../service/feature.service';

@Injectable({
  providedIn: 'root',
})
export class ActivatedFeaturesResolverService implements Resolve<Features> {
  constructor(private featuresService: FeatureService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<Features> {
    return this.featuresService.activated$.pipe(first());
  }
}
