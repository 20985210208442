<form [formGroup]="filterForm">
  <input
    class="input-search"
    placeholder="Search invites"
    formControlName="search"
  />
</form>

<cm-entity-list
  enableCreateButton="false"
  headline="Invites"
  createButtonLabel="..."
  [columns]="inviteColumns"
  [entities]="inviteEntities"
  [loading]="inviteListLoading"
  style="min-height: 200px; margin-bottom: 150px"
>
  <div slot="empty">nothing to see here</div></cm-entity-list
>
<br />
<br />
<br />
<br />
<br />
<br />
<br />
