import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { SubscriptionManagementRestService } from '../service/subscription-management.rest.service'

@Injectable({
  providedIn: 'root',
})
export class SubsidiariesResolverService
  implements Resolve<Array<{ name: string; site: string }>> {
  constructor(
    private subscriptionManagementService: SubscriptionManagementRestService,
  ) {}

  public resolve(): Observable<Array<{ name: string; site: string }>> {
    return this.subscriptionManagementService
      .getChargebeeSites()
      .pipe(shareReplay())
  }
}
