<cm-card *ngIf="organization">
  <div class="header" slot="header">
    <ng-container>
      <h3>
        <cm-checkbox
          #selectionCheckbox
          [checked]="selected"
          (cmInput)="updateSelection()"
          preventAttributeEmit
          *ngIf="showSelection"
        ></cm-checkbox>
        <span (click)="showDetails = !showDetails" class="cursor">
          <cm-icon
            icon="right"
            *ngIf="!showDetails && !showSelection"
          ></cm-icon>
          <cm-icon icon="down" *ngIf="showDetails && !showSelection"></cm-icon>
          {{ organization.name }}
          <span
            class="cursor"
            *ngIf="organization.clusters.length"
            style="padding-left: 20px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="10"
              height="10"
              viewBox="0 0 20 20"
              fill="#62626E"
            >
              <path
                fill-rule="evenodd"
                d="M5.00024547,9.33666667 L9.28595975,11.8366667 L9.28595975,16.8366667 L5.00024547,19.3366667 L0.71453118,16.8366667 L0.71453118,11.8366667 L5.00024547,9.33666667 Z M15.0002455,9.33666667 L19.2859598,11.8366667 L19.2859598,16.8366667 L15.0002455,19.3366667 L10.7145312,16.8366667 L10.7145312,11.8366667 L15.0002455,9.33666667 Z M10,0.67 L14.2857143,3.17 L14.2857143,8.17 L10,10.67 L5.71428571,8.17 L5.71428571,3.17 L10,0.67 Z"
              />
            </svg>
            {{ organization.clusters.length }}
          </span>
          <span
            class="tag inactive"
            *ngIf="organization.isInactive && organization.isOld"
          ></span>
        </span>
      </h3>
      <span>
        <cm-text>{{ organization.uuid }}</cm-text>
        <cm-icon-button
          icon="copy"
          (cmPress)="copyToClipboard(organization.uuid)"
        ></cm-icon-button>
      </span>
    </ng-container>

    <div class="footer" slot="footer">
      <cm-button
        label="Inspect"
        appearance="primary"
        routerLink="/organizations/{{ organization.uuid }}"
      ></cm-button>

      <cm-button
        label="Delete Clusters"
        appearance="danger"
        (cmPress)="deleteClustersDialog()"
        *ngIf="hasClusters && activatedFeatures.admin.deleteClusters"
      ></cm-button>

      <cm-button
        label="Delete Organization"
        appearance="danger"
        *ngIf="activatedFeatures.admin.deleteOrg && !specialOperationName"
        [disabled]="hasClusters"
        (cmPress)="deleteDialog()"
      ></cm-button>

      <cm-button
        *ngIf="specialOperationName"
        [label]="specialOperationName"
        [appearance]="specialOperationAppearance"
        (cmPress)="specialOperation()"
        [disabled]="specialOperationDisabled"
      ></cm-button>
    </div>
  </div>

  <div class="content" *ngIf="showDetails || showSelection">
    <cm-datalist>
      <cm-datalist-item label="Sales Plan">
        {{
          organization.organizationToSalesPlan
            ? organization.organizationToSalesPlan.salesPlan.name
            : '-'
        }}<span *ngIf="organization.organizationToSalesPlan">
          (Version
          {{ organization.organizationToSalesPlan.salesPlan.version }})</span
        >
        <span *ngIf="isTrial">
          - <b>Trial</b>:
          {{
            organization.organizationToSalesPlan.trialPlanStartDate
              | date: 'MMMM d, y, h:mm:ss a'
          }}
          -
          {{
            organization.organizationToSalesPlan.trialExpirationDate
              | date: 'MMMM d, y, h:mm:ss a'
          }}
        </span>
      </cm-datalist-item>

      <cm-datalist-item label="Created">
        {{ organization.created | date: 'MMMM d, y, h:mm:ss a' }}
      </cm-datalist-item>

      <cm-datalist-item
        label="Logins by Members in the last 24h"
        *ngIf="hasDetails"
      >
        <div class="loginCountContainer">
          <ng-container *ngFor="let application of applicationLogins">
            <span>{{ application.name }}: {{ application.count }}</span>
          </ng-container>
        </div>
      </cm-datalist-item>
    </cm-datalist>

    <ng-container *ngIf="organization.clusters.length">
      <h3>Clusters</h3>
      <div class="clusters">
        <div *ngFor="let cluster of organization.clusters" class="cluster">
          <h4>
            <cm-icon
              icon="right"
              *ngIf="!showClusterDetails(cluster.uuid)"
              (click)="toggleClusterDetails(cluster.uuid)"
              class="cursor"
            ></cm-icon>
            <cm-icon
              icon="down"
              *ngIf="showClusterDetails(cluster.uuid)"
              (click)="toggleClusterDetails(cluster.uuid)"
              class="cursor"
            ></cm-icon>
            <span (click)="toggleClusterDetails(cluster.uuid)" class="cursor">{{
              cluster.name
            }}</span>
            <span class="uuid">
              <cm-text
                (click)="toggleClusterDetails(cluster.uuid)"
                class="cursor"
                >{{ cluster.uuid }}</cm-text
              >
              <cm-icon-button
                icon="copy"
                (cmPress)="copyToClipboard(cluster.uuid)"
              ></cm-icon-button>
            </span>
            (DC: {{ cluster.k8sContext.name }})
            <span class="tag active" *ngIf="cluster.hasSignsOfActivity"></span>
            <span
              class="tag new"
              *ngIf="cluster.minAgeReached === false"
            ></span>
          </h4>

          <cm-datalist *ngIf="showClusterDetails(cluster.uuid)">
            <cm-datalist-item label="Generation">
              {{ cluster.generation.name }}
            </cm-datalist-item>

            <cm-datalist-item label="Cluster Plan Type">
              {{ cluster.planType.name }} (<b>Cluster Plan</b>:
              {{ cluster.plan.name }})
            </cm-datalist-item>

            <cm-datalist-item label="Created">
              {{ cluster.created | date: 'MMMM d, y, h:mm:ss a' }}
            </cm-datalist-item>

            <cm-datalist-item label="Usage">
              <b>Process Instances in last 7d</b>:
              {{
                cluster.createdWorkflowInstances
                  ? cluster.createdWorkflowInstances
                  : 0
              }}, <b>M2M Token in last 24h</b>:
              {{ cluster.m2mTokenCount ? cluster.m2mTokenCount : 0 }}
            </cm-datalist-item>
          </cm-datalist>
        </div>
      </div>
    </ng-container>
  </div>
</cm-card>
