import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ClusterPlanDto } from '@camunda-cloud/cloud-node-libs'
import { of } from 'rxjs'
import { catchError, first } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { environment } from '../../environments/environment'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root',
})
export class ClusterRestService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  public deleteCluster(orgId: string, clusterId: string) {
    return this.http
      .delete<void>(
        `${environment.consoleBackendUrl}/api/orgs/${orgId}/clusters/admin/${clusterId}`,
      )
      .pipe(first())
  }

  public getClustersForOrg(orgId: string) {
    return this.http
      .get<ClusterDto[]>(
        `${environment.consoleBackendUrl}/api/orgs/${orgId}/clusters/`,
      )
      .pipe(first())
  }

  public getAllClusters() {
    return this.http
      .get<ClusterDto[]>(`${environment.consoleBackendUrl}/api/admin/clusters`)
      .pipe(first())
  }

  // DTO: no DTO defined for prometheus data
  public getClusterStats(range: string) {
    return this.http
      .get<{
        clusters: Array<{
          uuid: string
          count: number
        }>
      }>(
        `${environment.consoleBackendUrl}/api/admin/clusters/instances/created?range=${range}`,
      )
      .pipe(first())
      .pipe(
        catchError((error) => {
          // console error to show that we get an error from prometheus
          // returning empty clusters array so accounts frontend can work
          // eslint-disable-next-line no-console
          this.notificationService.enqueueNotification({
            headline: `got a ${error.status} while fetching prometheus-data`,
            description:
              'not a big deal, but please dont burn inactive clusters now',
            appearance: 'info',
          })
          return of({ clusters: [] })
        }),
      )
  }
  public logBurnedClusters(
    clusters: Array<{
      burnDate: string
      org: { name: string; uuid: string }
      cluster: { name: string; uuid: string }
    }>,
  ) {
    return this.http
      .post<void>(`${environment.backendUrl}/api/admin/clusters/burn`, {
        clusters,
      })
      .pipe(first())
  }

  public checkForAvailableBoost(clusterId: string) {
    return this.http
      .get<{ availableClusterplan: ClusterPlanDto | undefined }>(
        `${environment.consoleBackendUrl}/external/clusters/${clusterId}/boost`,
      )
      .pipe(first())
  }
  public boost(clusterId: string) {
    return this.http
      .post<{ newClusterplan: ClusterPlanDto | undefined }>(
        `${environment.consoleBackendUrl}/external/clusters/${clusterId}/boost`,
        {},
      )
      .pipe(first())
  }
}
