import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core'
import { Features } from '../../../../../commons/Features'
import { UserRestService } from '../../service/user.rest.service'
import { ActivatedRoute } from '@angular/router'
import { UserDto } from '../../../../../commons/User.dto'
import { OrganizationRole } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { combineLatest, Subject } from 'rxjs'

@Component({
  selector: 'support-agents',
  templateUrl: './support-agents.component.html',
  styleUrls: ['./support-agents.component.scss'],
})
export class SupportAgentComponent implements OnInit {
  @Output()
  public update = new EventEmitter()
  @Input()
  public org: OrganizationDto
  @Input()
  public users$: Subject<UserDto[]>

  @ViewChild('addAgentModal', { read: ElementRef })
  public addAgentModal: ElementRef
  public createHandler: () => void

  public activatedFeatures: Features

  public agentListLoading = true
  public agentListColums = [
    { name: 'Name', width: '2fr' },
    { name: 'Email', width: '1fr' },
    { name: '', width: '35px' },
  ]
  public agentListEntites = []
  private allAgents: UserDto[] = []
  public availableAgents: UserDto[] = []
  public assignedAgents: UserDto[] = []
  public selectedAgent: UserDto | undefined = undefined

  constructor(
    private userService: UserRestService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.createHandler = () => this.openAddAgentModal()

    combineLatest([this.users$, this.userService.getSupportAgents()]).subscribe(
      ([users, agents]) => {
        this.agentListLoading = true
        this.allAgents = agents.filter((agent) => {
          const foundUser = users.find(
            (orgUser) => orgUser.user_id === agent.user_id,
          )
          if (foundUser) {
            if (
              foundUser.app_metadata.orgs.find(
                (org) => org.id === this.org.uuid,
              ).roles[0] === OrganizationRole.SUPPORTAGENT
            ) {
              return true
            } else {
              return false
            }
          }
          return true
        })

        this.availableAgents = this.allAgents.filter(
          (agent) =>
            !users.map((mapUser) => mapUser.user_id).includes(agent.user_id),
        )
        this.assignedAgents = this.allAgents.filter((agent) =>
          users.map((mapUser) => mapUser.user_id).includes(agent.user_id),
        )
        this.agentListEntites = this.assignedAgents.map((agent) => {
          let contextMenu = []
          if (
            this.activatedFeatures.admin.removeFromOrg ||
            this.activatedFeatures.supportAgent.removeAgentsFromOrg
          ) {
            contextMenu.push({
              label: 'Remove',
              handler: () =>
                this.ngZone.run(() => {
                  this.agentListLoading = true
                  this.userService
                    .removeUserFromOrganization(agent.user_id, this.org.uuid)
                    .subscribe((_) => {
                      this.update.emit()
                    })
                }),
            })
          }

          return {
            data: [
              { type: 'text', content: agent.name },
              { type: 'text', content: agent.email, showCopyButton: true },
              {
                type: 'contextMenu',
                options: [
                  {
                    options: contextMenu,
                  },
                ],
              },
            ],
          }
        })
        this.agentListLoading = false
      },
    )
  }

  public selectAgent(event) {
    this.selectedAgent = this.allAgents.find(
      (agent) => agent.user_id === event.target.value,
    )
  }

  public openAddAgentModal() {
    this.ngZone.run(() => {
      ;(this.addAgentModal.nativeElement as CmModal).open().then((result) => {
        this.agentListLoading = true
        if (result === 'confirm') {
          this.userService
            .addUserToOrganization(
              this.selectedAgent.user_id,
              this.org.uuid,
              OrganizationRole.SUPPORTAGENT,
            )
            .subscribe((_) => {
              this.update.emit()
              this.selectedAgent = undefined
            })
        } else {
          this.selectedAgent = undefined
          this.agentListLoading = false
        }
      })
    })
  }
}
