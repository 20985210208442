<cm-page #cmPage>
  <div slot="header">
    <h1>Camunda Cloud Accounts Admin</h1>
  </div>
  <cm-page-tab label="Users">
    <app-list-all-users></app-list-all-users>
  </cm-page-tab>
  <cm-page-tab label="Invites">
    <list-invites></list-invites>
  </cm-page-tab>
</cm-page>
