import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { Features } from '../../../../../commons/Features'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'overview-page',
  templateUrl: './overview.page.html',
  styleUrls: ['./overview.page.scss'],
})
export class OverviewPage implements OnInit {
  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef
  public tabChangedTo: BehaviorSubject<string> = new BehaviorSubject('')
  public activatedFeatures: Features
  public chargebeeEnabled = true
  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel
    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        const url = location.href.split('/').slice(0, 1).join('/')

        switch (cmPage.activeLabel) {
          case 'Organizations':
            history.replaceState({}, '', `${url}/organizations`)
            this.tabChangedTo.next('organizations')
            break
          case 'Users':
            history.replaceState({}, '', `${url}/users`)
            this.tabChangedTo.next('users')
            break
          case 'Invites':
            history.replaceState({}, '', `${url}/invites`)
            this.tabChangedTo.next('invites')
            break
          case 'Sales Plans':
            history.replaceState({}, '', `${url}/salesplans`)
            this.tabChangedTo.next('salesplans')
            break
          case 'Enterprise Plans':
            history.replaceState({}, '', `${url}/enterprise`)
            this.tabChangedTo.next('enterprise')
            break
          case 'Audits':
            history.replaceState({}, '', `${url}/audits`)
            this.tabChangedTo.next('audits')
            break
          default:
            history.replaceState({}, '', `${url}`)
            this.tabChangedTo.next('')
            break
        }
      })
    })
  }
}
