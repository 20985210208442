/* eslint-disable id-length */
import { Injectable } from '@angular/core'
import {
  ClusterPlanTypeDto,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { OrganizationDto } from '../../../../commons/Organization.dto'

const CLUSTER_UNSET = 'unset'
const NO_CLUSTERS = 'no clusters'
const HAS_CLUSTERS = 'has clusters'
const MIN_ONE_ACTIVE_CLUSTER = 'at least one active cluster'
const ONLY_OLD_AND_INACTIVE_CLUSTERS = 'only old and inactive clusters'
const FILTER_CLUSTERS = [
  CLUSTER_UNSET,
  NO_CLUSTERS,
  HAS_CLUSTERS,
  MIN_ONE_ACTIVE_CLUSTER,
  ONLY_OLD_AND_INACTIVE_CLUSTERS,
]

export interface OrgFilterAge {
  name: string
  ts: number
}

const ORG_AGE_7D = {
  name: '> 7d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
}
const ORG_AGE_14D = {
  name: '> 14d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 14,
}
const ORG_AGE_21D = {
  name: '> 21d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 21,
}
const ORG_AGE_30D = {
  name: '> 30d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
}
const ORG_AGE_60D = {
  name: '> 60d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 60,
}

const ORG_AGE_YT_7D = {
  name: '< 7d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
}
const ORG_AGE_YT_14D = {
  name: '< 14d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 14,
}
const ORG_AGE_YT_21D = {
  name: '< 21d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 21,
}
const ORG_AGE_YT_30D = {
  name: '< 30d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
}
const ORG_AGE_YT_60D = {
  name: '< 60d',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 60,
}

const ORG_AGE_ALL = {
  name: 'all',
  ts: new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 1000,
}

const SALES_PLAN_UNSET_UUID = 'unset'
const SALES_PLAN_UNSET: SalesPlanDto = {
  name: 'unset',
  uuid: SALES_PLAN_UNSET_UUID,
  clusterPlanTypesToConfig: {},
  version: 0,
}

const SALES_PLAN_NONE_UUID = 'none'
const SALES_PLAN_NONE: SalesPlanDto = {
  name: 'none',
  uuid: SALES_PLAN_NONE_UUID,
  clusterPlanTypesToConfig: {},
  version: 0,
}

export const ORG_FILTER_AGES: OrgFilterAge[] = [
  ORG_AGE_ALL,
  ORG_AGE_7D,
  ORG_AGE_YT_7D,
  ORG_AGE_14D,
  ORG_AGE_YT_14D,
  ORG_AGE_21D,
  ORG_AGE_YT_21D,
  ORG_AGE_30D,
  ORG_AGE_YT_30D,
  ORG_AGE_60D,
  ORG_AGE_YT_60D,
]

export const CLUSTER_PLAN_TYPE_RESERVATIONS_UNSET: {
  uuid: string
  name?: string
} = { uuid: 'unset', name: 'unset' }

export interface OrgFilter {
  age?: string
  members?: string
  salesPlan?: string
  cluster?: string
  generation?: string
  generations?: string[]
  planType?: string
  planTypes?: string[]
  reservedPlanType?: string
  searchTerm?: string
  clusterIds?: string[]
  clientId?: string
}

@Injectable({
  providedIn: 'root',
})
export class OrgFilterService {
  public organizations: OrganizationDto[] = []
  public filteredOrganizations: OrganizationDto[] = []

  public fAge: string = ORG_AGE_ALL.name
  public fSalesPlan: string = SALES_PLAN_UNSET_UUID
  public fCluster: string = CLUSTER_UNSET
  public fGenerations: string[] = []
  public fPlanTypes: string[] = []
  public fSearchTerm: string
  public fReservedClusterPlanTypes: Array<{ uuid: string; name?: string }> = [
    CLUSTER_PLAN_TYPE_RESERVATIONS_UNSET,
  ]
  public fReservedClusterPlanType: string =
    CLUSTER_PLAN_TYPE_RESERVATIONS_UNSET.name
  public fClusterIds: string[] = []
  public fClientId: string

  private salesPlans: SalesPlanDto[] = []
  private generations: any[] = []
  private clusterPlanTypes: ClusterPlanTypeDto[] = []

  constructor() {}

  public init(
    organizations: OrganizationDto[],
    allClusterPlanTypes: ClusterPlanTypeDto[],
  ) {
    this.salesPlans = [SALES_PLAN_UNSET, SALES_PLAN_NONE]
    this.generations = []
    this.clusterPlanTypes = []
    this.organizations = organizations
    for (const org of organizations) {
      if (
        org.organizationToSalesPlan &&
        org.organizationToSalesPlan.salesPlan
      ) {
        if (
          !this.salesPlans.find((salesPlan) => {
            if (org.organizationToSalesPlan) {
              return (
                salesPlan.uuid === org.organizationToSalesPlan.salesPlan.uuid
              )
            } else {
              return false
            }
          })
        ) {
          this.salesPlans.push(org.organizationToSalesPlan.salesPlan)
        }
        for (const clusterPlanTypeId of Object.keys(
          org.organizationToSalesPlan.clusterPlanTypesToReserved,
        )) {
          if (
            org.organizationToSalesPlan.clusterPlanTypesToReserved[
              clusterPlanTypeId
            ] > 0 &&
            !this.fReservedClusterPlanTypes.find(
              (clusterPlanType) => clusterPlanType.uuid === clusterPlanTypeId,
            )
          ) {
            const foundClusterPlanType = allClusterPlanTypes.find(
              (clusterPlanType) => clusterPlanType.uuid === clusterPlanTypeId,
            )
            this.fReservedClusterPlanTypes.push({
              uuid: clusterPlanTypeId,
              name: foundClusterPlanType
                ? foundClusterPlanType.name
                : clusterPlanTypeId,
            })
          }
        }
      }
      if (org.clusters && org.clusters.length > 0) {
        for (const cluster of org.clusters) {
          if (
            !this.generations.find(
              (g) => g.uuid === (cluster.generation as any).uuid,
            )
          ) {
            this.generations.push(cluster.generation)
          }
          if (
            cluster.planType &&
            !this.clusterPlanTypes.find(
              (cpt) => cpt.uuid === cluster.planType.uuid,
            )
          ) {
            this.clusterPlanTypes.push(cluster.planType)
          }
        }
      }
    }
  }

  public getPermalinkQuery() {
    const filter: OrgFilter = {
      age: this.fAge,
      salesPlan: this.fSalesPlan,
      cluster: this.fCluster,
      generations: this.fGenerations,
      planTypes: this.fPlanTypes,
      reservedPlanType: this.fReservedClusterPlanType,
      searchTerm: this.fSearchTerm,
      clusterIds: this.fClusterIds,
      clientId: this.fClientId,
    }
    return encodeURI(JSON.stringify(filter))
  }

  // eslint-disable-next-line class-methods-use-this
  public getFilterFromPermalinkQuery(query: string) {
    const filter: OrgFilter = JSON.parse(decodeURI(query))
    return filter
  }

  public reset() {
    this.fAge = ORG_AGE_ALL.name
    this.fSalesPlan = SALES_PLAN_UNSET_UUID
    this.fCluster = CLUSTER_UNSET
    this.fGenerations = []
    this.fPlanTypes = []
    this.fReservedClusterPlanType = CLUSTER_PLAN_TYPE_RESERVATIONS_UNSET.name
    this.fSearchTerm = ''
    this.fClusterIds = []
    this.fClientId = undefined
    return this.update({})
  }

  public update(filter: OrgFilter) {
    this.fAge = filter.age ? filter.age : this.fAge
    this.fSearchTerm = filter.searchTerm ? filter.searchTerm : ''
    this.fReservedClusterPlanType = filter.reservedPlanType
      ? filter.reservedPlanType
      : this.fReservedClusterPlanType
    this.fCluster = filter.cluster ? filter.cluster : this.fCluster
    this.fSalesPlan = filter.salesPlan ? filter.salesPlan : this.fSalesPlan
    if (filter.generation && !filter.generations) {
      if (this.fGenerations.includes(filter.generation)) {
        this.fGenerations = this.fGenerations.filter(
          (g) => g !== filter.generation,
        )
      } else {
        this.fGenerations.push(filter.generation)
      }
    }
    this.fGenerations = filter.generations
      ? filter.generations
      : this.fGenerations
    if (filter.planType && !filter.planTypes) {
      if (this.fPlanTypes.includes(filter.planType)) {
        this.fPlanTypes = this.fPlanTypes.filter(
          (cpt) => cpt !== filter.planType,
        )
      } else {
        this.fPlanTypes.push(filter.planType)
      }
    }
    this.fPlanTypes = filter.planTypes ? filter.planTypes : this.fPlanTypes
    this.fClusterIds = filter.clusterIds ? filter.clusterIds : this.fClusterIds
    if (filter.clientId && filter.clientId.length > 6) {
      this.fClientId = filter.clientId ? filter.clientId : this.fClientId
    } else {
      this.fClientId = undefined
    }

    this.filteredOrganizations = this.organizations.filter(
      (o) =>
        this.filterByAge(o) &&
        this.filterByCluster(o) &&
        this.filterByClusterPlanType(o) &&
        this.filterByGeneration(o) &&
        this.filterBySalesPlan(o) &&
        this.filterBySearchTerm(o) &&
        this.filterByReservedClusterPlanType(o) &&
        this.filterByClusterIds(o),
    )
    return this.filteredOrganizations
  }

  // eslint-disable-next-line class-methods-use-this
  public getOrgFilterAge() {
    return ORG_FILTER_AGES
  }

  // eslint-disable-next-line class-methods-use-this
  public getOrgFilterClusters() {
    return FILTER_CLUSTERS
  }

  public getOrgFilterSalesPlans() {
    return this.salesPlans
  }

  public getOrgFilterGenerations() {
    return this.generations
  }

  public getOrgFilterClusterPlanTypes() {
    return this.clusterPlanTypes
  }

  private filterByAge(org: OrganizationDto) {
    if (this.fAge === ORG_AGE_ALL.name) {
      return true
    }
    const orgTs = new Date(org.created).getTime()
    const age = ORG_FILTER_AGES.find((a) => a.name === this.fAge)

    if (age) {
      if (age.name.startsWith('<')) {
        return orgTs > age.ts
      } else {
        return orgTs < age.ts
      }
    } else {
      return true
    }
  }

  private filterBySalesPlan(org: OrganizationDto) {
    if (!this.fSalesPlan || this.fSalesPlan === SALES_PLAN_UNSET.uuid) {
      return true
    }
    if (
      (!org.organizationToSalesPlan ||
        !org.organizationToSalesPlan.salesPlan) &&
      this.fSalesPlan === SALES_PLAN_NONE.uuid
    ) {
      return true
    }
    return (
      org.organizationToSalesPlan &&
      org.organizationToSalesPlan.salesPlan &&
      org.organizationToSalesPlan.salesPlan.uuid === this.fSalesPlan
    )
  }

  private filterByCluster(org: OrganizationDto) {
    switch (this.fCluster) {
      case CLUSTER_UNSET:
        return true
      case HAS_CLUSTERS:
        return org.clusters && org.clusters.length > 0
      case NO_CLUSTERS:
        return !org.clusters || org.clusters.length === 0
      case MIN_ONE_ACTIVE_CLUSTER:
        if (!org.clusters || org.clusters.length === 0) {
          return false
        }
        for (const c of org.clusters) {
          if (c.hasSignsOfActivity === true) {
            return true
          }
        }
        return false
      case ONLY_OLD_AND_INACTIVE_CLUSTERS:
        if (!org.clusters || org.clusters.length === 0) {
          return false
        }
        // eslint-disable-next-line no-case-declarations
        let oldAndInactive = true
        for (const cluster of org.clusters) {
          oldAndInactive =
            oldAndInactive &&
            cluster.hasSignsOfActivity === false &&
            cluster.minAgeReached === true
        }
        return oldAndInactive
      default:
        return false
    }
  }

  private filterByClusterIds(org: OrganizationDto) {
    if (
      !this.fClientId &&
      (!this.fClusterIds || this.fClusterIds.length === 0)
    ) {
      return true
    }
    if (
      this.fClientId &&
      (!this.fClusterIds || this.fClusterIds.length === 0)
    ) {
      return false
    }
    if (!org.clusters || org.clusters.length === 0) {
      return false
    }
    for (const c of org.clusters) {
      if (this.fClusterIds.includes(c.uuid)) {
        return true
      }
    }
    return false
  }

  private filterByGeneration(org: OrganizationDto) {
    if (!this.fGenerations || this.fGenerations.length === 0) {
      return true
    }
    if (!org.clusters || org.clusters.length === 0) {
      return false
    }
    for (const c of org.clusters) {
      if (
        c.generation &&
        this.fGenerations.includes((c.generation as any).uuid)
      ) {
        return true
      }
    }
    return false
  }

  private filterByClusterPlanType(org: OrganizationDto) {
    if (!this.fPlanTypes || this.fPlanTypes.length === 0) {
      return true
    }
    if (!org.clusters || org.clusters.length === 0) {
      return false
    }
    for (const c of org.clusters) {
      if (
        c.planType &&
        c.planType.uuid &&
        this.fPlanTypes.filter((p) => p === c.planType.uuid).length > 0
      ) {
        return true
      }
    }
    return false
  }

  private filterByReservedClusterPlanType(org: OrganizationDto) {
    if (!this.fReservedClusterPlanType) {
      return true
    }
    if (
      this.fReservedClusterPlanType ===
      CLUSTER_PLAN_TYPE_RESERVATIONS_UNSET.name
    ) {
      return true
    }
    if (
      !org.organizationToSalesPlan ||
      !org.organizationToSalesPlan.clusterPlanTypesToReserved
    ) {
      return false
    }
    if (
      org.organizationToSalesPlan.clusterPlanTypesToReserved[
        this.fReservedClusterPlanType
      ] > 0
    ) {
      return true
    }
    return false
  }

  private filterBySearchTerm(org: OrganizationDto) {
    if (!this.fSearchTerm || this.fSearchTerm.length <= 2) {
      return true
    }
    const searchTerm: string = this.fSearchTerm.toLowerCase()
    if (searchTerm.startsWith('cluster:')) {
      if (!org.clusters || org.clusters.length === 0) {
        return false
      }
      const clusterSearch = searchTerm.split('cluster:')[1].trim()
      for (const c of org.clusters) {
        if (c.name.includes(clusterSearch) || c.uuid.includes(clusterSearch)) {
          return true
        }
      }
      return false
    } else {
      return org.name.toLowerCase().includes(searchTerm)
    }
  }
}
