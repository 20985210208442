<cm-page #cmPage>
  <div slot="header">
    <div class="header-grid">
      <div class="header-title">
        <h1>
          Sales Plan: {{ salesPlan?.name }}
          <span *ngIf="salesPlan?.internal">(INTERNAL)</span>
        </h1>
        Type: <b>{{ salesPlan?.salesPlanType }}</b
        >, Version: <b>{{ salesPlan?.version }}</b
        >, Subsidiary:
        <b *ngIf="selectedSubsidiary">{{ selectedSubsidiary?.name }}</b>
      </div>
      <div class="header-buttons">
        <cm-button
          class="button-margin"
          label="Add Config"
          appearance="secondary"
          slot="header"
          (cmPress)="addSubscriptionConfig()"
        ></cm-button>
        <cm-button
          class="button-margin"
          label="Reset Configs"
          appearance="secondary"
          slot="header"
          (cmPress)="resetSubscriptionConfigs()"
          *ngIf="subscriptionConfigController.dirty"
        ></cm-button>
        <cm-button
          class="button-margin"
          label="Save Configs"
          appearance="primary"
          slot="header"
          (cmPress)="saveSubscriptionConfigs()"
          [disabled]="!subscriptionConfigController.dirty"
        ></cm-button>
        <cm-button
          class="button-margin"
          label="Remove last Config"
          appearance="danger"
          slot="header"
          (cmPress)="removeLastSubscriptionConfig()"
          *ngIf="subscriptionConfigController.subscriptionConfigs.length > 0"
        ></cm-button>
      </div>
    </div>
  </div>
  <cm-page-tab label="Cluster Plan Types">
    <cm-entity-list
      [enableCreateButton]="false"
      headline="Cluster Plan Types"
      createButtonLabel="..."
      [columns]="clusterPlanTypeColumns"
      [entities]="clusterPlanTypeContent"
      [loading]="status === 'processing'"
      style="min-height: 200px; margin-bottom: 150px"
    >
      <div slot="empty">No Cluster Plan Types</div>
    </cm-entity-list>
  </cm-page-tab>
  <cm-page-tab label="Organizations">
    <cm-entity-list
      [enableCreateButton]="false"
      headline="Organizations"
      createButtonLabel="..."
      [columns]="orgColumns"
      [entities]="orgContent"
      [loading]="!orgs"
      style="min-height: 200px; margin-bottom: 150px"
    >
      <div slot="empty">No Cluster Plan Types</div>
    </cm-entity-list>
  </cm-page-tab>
  <cm-page-tab label="Information">
    Sales Plan Name:
    <br />
    <input
      [formControl]="salesPlanNameFormControl"
      aria-label="Sales Plan Name"
    />
    <br />
    Sales Plan Description:
    <br />
    <textarea
      rows="15"
      cols="50"
      [formControl]="salesPlanDescriptionFormControl"
      aria-label="Sales Plan Description"
    ></textarea>
    <cm-button
      class="button-margin"
      label="Save"
      [disabled]="
        status === 'processing' || !activatedFeatures.salesPlan.modify
      "
      appearance="primary"
      (cmPress)="saveSalesPlanInformation()"
    ></cm-button>
  </cm-page-tab>
  <cm-page-tab
    label="{{ config.id }}"
    *ngFor="let config of subscriptionConfigController.subscriptionConfigs"
  >
    <app-list-item-prices
      [chargebeeSite]="selectedSubsidiary.site"
      [inputFilters]="
        subscriptionConfigController.inputFiltersPerConfig[config.id][itemList]
      "
      (assignment)="subscriptionConfigController.assignItemPrice($event)"
      *ngFor="let itemList of ['plan', 'addons', 'selection']"
    ></app-list-item-prices>
  </cm-page-tab>
</cm-page>

<cm-modal
  #modifyItemModal
  headline="{{ selectedItem?.name }} ({{ selectedItem?.uuid }})"
  cancelLabel="Cancel"
  confirmLabel="Modify"
>
  <span *ngIf="selectedItem">
    <form [formGroup]="clusterPlanTypeToConfigItemForm">
      <label
        >Min Reserved<br />
        <input
          min="0"
          [max]="clusterPlanTypeToConfigItemForm.value.maxReserved"
          type="number"
          formControlName="minReserved"
        /> </label
      ><br />
      <label>
        Max Reserved<br />
        <input
          [min]="clusterPlanTypeToConfigItemForm.value.minReserved"
          type="number"
          formControlName="maxReserved"
        /> </label
      ><br />
      <label>
        Price<br />
        <input type="text" formControlName="price" />
      </label>
    </form>
  </span>
  <cm-checkbox
    label="Force"
    [checked]="modifyForce"
    (cmInput)="toggleModifyForce($event)"
    *ngIf="activatedFeatures ? activatedFeatures.salesPlan.forceModify : false"
  ></cm-checkbox>
</cm-modal>

<cm-modal
  #selectSubsidiaryModal
  headline="Select Subsidiary"
  cancelLabel="Cancel"
  confirmLabel="Confirm"
>
  <span *ngIf="subsidiaryOptions">
    <cm-filter
      [filters]="subsidiaryOptions"
      (cmFilterSelected)="selectedSubsidiaryFromModal = $event.detail.value"
    ></cm-filter>
  </span>
</cm-modal>
