import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuditDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(private http: HttpClient) {}

  public getAuditLogs(
    orgId: string,
    options?: { per_page: number; page: number },
  ): Observable<AuditDto[]> {
    // const params = new HttpParams()
    // if (options) {
    //   params.append('per_page', options.per_page.toString())
    //   params.append('page', options.page.toString())
    // }
    const queryParams = options
      ? `?per_page=${options.per_page}&page=${options.page}`
      : ''
    return this.http.get<AuditDto[]>(
      `${environment.auditBackendUrl}/api/audit/orgs/${orgId}${queryParams}`,
    )
  }
}
