import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ExportToCsv } from 'export-to-csv'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { first } from 'rxjs/operators'
import { Features } from '../../../../../commons/Features'
import { LoginDto } from '../../../../../commons/Login.dto'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { AugmentedOrgsResolverService } from '../../resolver/augmented-orgs.resolver'
import {
  AppSetting,
  AppSettingsService,
} from '../../service/appSettings.service'
import { ClientRestService } from '../../service/client.rest.service'
import { ClusterRestService } from '../../service/cluster.rest.service'
import { ConfirmationModalService } from '../../service/confirmation-modal.service'
import { NotificationService } from '../../service/notification.service'
import { OrgRestService } from '../../service/org.rest.service'
import { OrgFilter, OrgFilterService } from '../../service/orgFilter.service'

export interface ClusterDeleteAction {
  isDeleted: boolean
  date: string
  orgName: string
  orgId: string
  clusterName: string
  clusterId: string
}

@Component({
  selector: 'list-organizations',
  styleUrls: ['./list-organizations.component.scss'],
  templateUrl: './list-organizations.component.html',
})
export class ListOrganizationsComponent implements OnInit {
  public activatedFeatures: Features
  public logins: LoginDto[]
  public showModal = false
  public modalTitle = ''
  public modalContent = ''
  public searchOrgInput = ''
  public searchClientInput = ''
  public selectedOrgs: string[] = []
  public showSelection = false

  public filteredOrganizations$: BehaviorSubject<OrganizationDto[]>
  private _organizations: OrganizationDto[]

  public displayingOrgs = 10

  public clusterDeleteActions: Array<ClusterDeleteAction> = []

  public filter: BehaviorSubject<OrgFilter> = new BehaviorSubject({})

  public ageFilters = []
  public ageFiltersActiveIndex: number = 0
  public clusterFilters = []
  public clusterFiltersActiveIndex: number = 0
  public salesPlanFilters = []
  public salesPlanFiltersActiveIndex: number = 0
  public reservedPlanTypeFilters = []
  public reservedPlanTypeFiltersActiveIndex: number = 0

  public clusterDeleteCardDismissed = false

  public organizationNameFormControl = new FormControl('', [
    Validators.required,
  ])

  @ViewChild('createOrganizationModal', { read: ElementRef })
  public createOrganizationModal: ElementRef<HTMLCmModalElement>

  public openCreateOrganizationModal() {
    this.organizationNameFormControl.reset()

    requestAnimationFrame(() => {
      this.createOrganizationModal.nativeElement.open().then((result) => {
        if (result === 'confirm') {
          this.orgService
            .createOrganization(this.organizationNameFormControl.value)
            .subscribe(() => {
              this.notificationService.enqueueNotification({
                headline: 'org has been created',
                description: 'please refresh the page',
                appearance: 'success',
              })
              this.augmentedOrgsResolverService.refreshOrgs()
              this.augmentedOrgsResolverService.resolve().subscribe((_) => {
                this.ngOnInit()
              })
            })
        }
      })
    })
  }

  constructor(
    public orgFilterService: OrgFilterService,
    public appSettingsService: AppSettingsService,
    private orgService: OrgRestService,
    private clusterService: ClusterRestService,
    private clientRestService: ClientRestService,
    private route: ActivatedRoute,
    private augmentedOrgsResolverService: AugmentedOrgsResolverService,
    private router: Router,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.logins = this.route.snapshot.data.latestLogins

    this.filteredOrganizations$ = new BehaviorSubject<OrganizationDto[]>(
      this.route.snapshot.data.orgs,
    )

    combineLatest([
      this.filter,
      this.augmentedOrgsResolverService.resolve(),
    ]).subscribe(([filter, allOrganizations]) => {
      this.orgFilterService.init(
        allOrganizations,
        this.route.snapshot.data.allClusterPlanTypes,
      )

      const filteredOrgs = this.orgFilterService.update(filter)

      this.updateFilters()

      this.displayingOrgs = 10
      this._organizations = filteredOrgs

      this.filteredOrganizations$.next(filteredOrgs)
    })

    const queryParameters = (
      await this.route.queryParamMap.pipe(first()).toPromise()
    ).get('q')

    if (queryParameters) {
      let filterFromQuery = this.orgFilterService.getFilterFromPermalinkQuery(
        queryParameters,
      )

      if (filterFromQuery.searchTerm) {
        this.searchOrgInput = filterFromQuery.searchTerm
      }

      this.filter.next(filterFromQuery)
    }
  }

  public updateFilters() {
    this.ageFilters = this.orgFilterService
      .getOrgFilterAge()
      .map((ageFilter) => {
        let label = ageFilter.name

        if (label === 'all') {
          label = 'Any'
        }

        return {
          label,
          value: ageFilter.name,
        }
      })
    this.ageFiltersActiveIndex = this.orgFilterService
      .getOrgFilterAge()
      .findIndex((ageFilter) => ageFilter.name === this.orgFilterService.fAge)

    this.clusterFilters = this.orgFilterService
      .getOrgFilterClusters()
      .map((clusterFilter) => {
        let label = clusterFilter

        if (label === 'unset') {
          label = 'Any'
        }

        return {
          label,
          value: clusterFilter,
        }
      })

    this.clusterFiltersActiveIndex = this.orgFilterService
      .getOrgFilterClusters()
      .findIndex(
        (clusterFilter) => clusterFilter === this.orgFilterService.fCluster,
      )

    this.salesPlanFilters = this.orgFilterService
      .getOrgFilterSalesPlans()
      .sort((a, b) => {
        if (a.name === 'unset') {
          return -1
        }

        if (b.name === 'unset') {
          return 1
        }

        return a.name.localeCompare(b.name)
      })
      .map((salesPlanFilter) => {
        let label = salesPlanFilter.name

        if (label === 'unset') {
          label = 'Any'
        }

        return {
          label,
          value: salesPlanFilter.uuid,
        }
      })

    this.salesPlanFiltersActiveIndex = this.orgFilterService
      .getOrgFilterSalesPlans()
      .findIndex(
        (salesPlanFilter) =>
          salesPlanFilter.uuid === this.orgFilterService.fSalesPlan,
      )

    this.reservedPlanTypeFilters = this.orgFilterService.fReservedClusterPlanTypes.map(
      (clusterPlanTypeReservation) => {
        let label = clusterPlanTypeReservation.name

        if (label === 'unset') {
          label = 'Any'
        }

        return {
          label,
          value: clusterPlanTypeReservation.uuid,
        }
      },
    )

    this.reservedPlanTypeFiltersActiveIndex = this.orgFilterService.fReservedClusterPlanTypes.findIndex(
      (reservedClusterPlanType) =>
        reservedClusterPlanType.uuid ===
        this.orgFilterService.fReservedClusterPlanType,
    )
  }

  public getOrgFilterGenerations() {
    return this.orgFilterService
      .getOrgFilterGenerations()
      .slice(0)
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
  }

  resetClusterPlanTypeFilter() {
    this.filter.next({ planTypes: [] })
  }

  resetGenerationsFilter() {
    this.filter.next({ generations: [] })
  }

  public getPermalink() {
    return `/organizations?q=${this.orgFilterService.getPermalinkQuery()}`
  }

  public async exportClusterCsv() {
    new ExportToCsv({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Accounts Export - Clusters',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }).generateCsv(
      (
        await this.augmentedOrgsResolverService.getAllClusters().toPromise()
      ).map((cluster) => {
        return {
          clusterName: cluster.name,
          clusterId: cluster.uuid,
          owningOrgId: cluster.ownerId,
          owningOrgName: this._organizations.find(
            (org) => org.uuid === cluster.ownerId,
          )?.name,
          active: cluster.hasSignsOfActivity,
          created: cluster.created,
          planType: cluster.planType?.name,
          plan: cluster.plan?.name,
          salesPlan: this._organizations.find(
            (org) => org.uuid === cluster.ownerId,
          )?.organizationToSalesPlan?.salesPlan.name,
          generation: cluster.generation?.name,
        }
      }),
    )
  }

  public exportCsv() {
    new ExportToCsv({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Accounts Export - Orgs',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }).generateCsv(
      this._organizations.map((org) => {
        return {
          orgName: org.name,
          orgId: org.uuid,
          orgCreated: org.created,
          isInactive: org.isInactive,
          isOld: org.isOld,
          salesPlan: org.organizationToSalesPlan?.salesPlan?.name,
          clusterCount: org.clusters.length,
          instancesStarted: org.clusters
            .map((cluster) => cluster.createdWorkflowInstances)
            .reduce((a, b) => Number(a) + Number(b), 0),
          m2mInteractions: org.clusters
            .map((cluster) => cluster.m2mTokenCount)
            .reduce((a, b) => Number(a) + Number(b), 0),
          generation: org.clusters
            .map((cluster) => cluster.generation.name)
            .reduce((a, b) => `${a} ${b}`, ''),
        }
      }),
    )
  }

  public changeOrgSelection(orgId: string) {
    if (this.selectedOrgs.includes(orgId)) {
      this.selectedOrgs = this.selectedOrgs.filter((org) => org !== orgId)
    } else {
      this.selectedOrgs.push(orgId)
    }
  }

  public setFilter(filter: OrgFilter) {
    this.filter.next(filter)
  }

  public async filterClients() {
    if (this.searchClientInput && this.searchClientInput.length >= 6) {
      const clusterClients = await this.clientRestService
        .findClients(this.searchClientInput)
        .toPromise()
      const clusterIds = clusterClients.map((client) => client.cluster.uuid)
      this.setFilter({ clientId: this.searchClientInput, clusterIds })
    } else {
      this.setFilter({ clientId: '', clusterIds: [] })
    }
  }

  public reset() {
    this.orgFilterService.reset()
    this.searchClientInput = ''
    this.searchOrgInput = ''
    this.filter.next({})
  }

  public toggleShowSelection() {
    if (this.showSelection) {
      this.selectedOrgs = []
      this.showSelection = false
    } else {
      this.showSelection = true
    }
  }

  public onScroll() {
    this.displayingOrgs += 10
  }

  public toggleSelectAll(organizations: OrganizationDto[]) {
    this.selectedOrgs =
      this.selectedOrgs.length > 0
        ? []
        : (this.selectedOrgs = organizations.map((org) => org.uuid))
  }

  public deleteSelectedClustersDialog(allPossible: OrganizationDto[]) {
    const clusterDeleteActions: ClusterDeleteAction[] = []

    for (const orgId of this.selectedOrgs) {
      const organization = allPossible.find((org) => org.uuid === orgId)

      if (organization && organization.clusters) {
        const clusters = organization!.clusters

        for (const cluster of clusters) {
          const clusterDeleteAction: ClusterDeleteAction = {
            isDeleted: false,
            date: new Date().toISOString().substring(0, 10),
            orgName: organization.name,
            orgId: organization.uuid,
            clusterName: cluster.name,
            clusterId: cluster.uuid,
          }

          clusterDeleteActions.push(clusterDeleteAction)
        }
      }
    }

    const clusterNames = clusterDeleteActions.map(
      (clusterToDelete) => clusterToDelete.clusterName,
    )

    this.modalService.openModal({
      title: `Delete all ${clusterDeleteActions.length} Clusters for ${this.selectedOrgs.length} Organizations?`,
      bodyIsRaw: true,
      body: `Are you sure you want to delete all ${
        clusterDeleteActions.length
      } clusters for the ${
        this.selectedOrgs.length
      } selected organizations? This will remove the following clusters: <ul>${clusterNames
        .map((clusterName) => `<li>${clusterName}</li>`)
        .join('')}</ul>`,
      confirmButton: {
        text:
          clusterDeleteActions.length === 1
            ? 'Delete the Cluster'
            : `Delete all ${clusterDeleteActions.length}`,
        type: 'danger',
        action: () => {
          this.clusterDeleteCardDismissed = false
          this.clusterDeleteActions = clusterDeleteActions

          const burnedClusters = new Array<{
            burnDate: string
            org: { name: string; uuid: string }
            cluster: { name: string; uuid: string }
          }>()

          let clusterDeletionPromises = []

          for (const action of this.clusterDeleteActions) {
            let clusterDeletionPromise = this.clusterService
              .deleteCluster(action.orgId, action.clusterId)
              .toPromise()
              .then(() => {
                action.isDeleted = true

                burnedClusters.push({
                  burnDate: action.date,
                  cluster: {
                    name: action.clusterName,
                    uuid: action.clusterId,
                  },
                  org: { uuid: action.orgId, name: action.orgName },
                })
              })

            clusterDeletionPromises.push(clusterDeletionPromise)
          }

          Promise.all(clusterDeletionPromises).then(() => {
            if (burnedClusters.length > 0) {
              this.clusterService.logBurnedClusters(burnedClusters).toPromise()
              this.augmentedOrgsResolverService.refreshClusters()
            }
          })
        },
      },
      cancelButton: {
        text: 'No',
        type: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteSelectedOrgsDialog(allPossible: OrganizationDto[]) {
    const orgsWithClusters = allPossible.filter(
      (org) =>
        this.selectedOrgs.includes(org.uuid) &&
        org.clusters &&
        org.clusters.length > 0,
    )

    const orgsWithoutClusters = allPossible.filter(
      (org) =>
        this.selectedOrgs.includes(org.uuid) &&
        (!org.clusters || org.clusters.length === 0),
    )

    let body = ''

    if (orgsWithClusters.length > 0) {
      body = `<p>There are ${orgsWithClusters.length} orgs selected that have clusters - we are ignoring them. Please have a closer look.</p>`
    }

    body += `<p>Are you sure you want to delete ${orgsWithoutClusters.length} organizations?`

    this.modalService.openModal({
      title: `Delete all ${orgsWithoutClusters.length} selected Organizations?`,
      bodyIsRaw: true,
      body,
      confirmButton: {
        text: `Delete all ${orgsWithoutClusters.length}`,
        type: 'danger',
        action: () => {
          Promise.all(
            orgsWithoutClusters.map((orgToDelete) =>
              this.orgService.deleteOrganization(orgToDelete.uuid).toPromise(),
            ),
          ).then(() => {
            this.augmentedOrgsResolverService.refreshOrgs()
            this.router.navigate(['organizations'])
          })
        },
      },
      cancelButton: {
        text: 'No',
        type: 'secondary',
        action: () => {},
      },
    })
  }
}
