import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ItemPrice } from '@camunda-cloud/subscription-management'
import { first } from 'rxjs/operators'
import {
  BaseRoute,
  SubscriptionManagementRoute,
} from '../../../../commons/Route.enum'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SubscriptionManagementRestService {
  constructor(private http: HttpClient) {}

  public getChargebeeSites() {
    return this.http
      .get<Array<{ name: string; site: string }>>(
        `${environment.backendUrl}/${BaseRoute.API}/${SubscriptionManagementRoute.BASE}/${SubscriptionManagementRoute.CHARGEBEE_SITES}`,
      )
      .pipe(first())
  }

  public getSubscription(chargebeeSite: string, subscriptionId: string) {
    return this.http
      .get<{ subscription: any; customer: any }>(
        `${environment.backendUrl}/${BaseRoute.API}/${SubscriptionManagementRoute.BASE}/${SubscriptionManagementRoute.CHARGEBEE_SITES}/${chargebeeSite}/${SubscriptionManagementRoute.SUBSCRIPTIONS}/${subscriptionId}`,
      )
      .pipe(first())
  }

  public getItemPrices(chargebeeSite: string) {
    return this.http
      .get<ItemPrice[]>(
        `${environment.backendUrl}/${BaseRoute.API}/${SubscriptionManagementRoute.BASE}/${SubscriptionManagementRoute.CHARGEBEE_SITES}/${chargebeeSite}/${SubscriptionManagementRoute.ITEM_PRICES}`,
      )
      .pipe(first())
  }

  public updateItemMetadata(request: {
    chargebeeSite: string
    itemId: string
    metadata: { type: string; data: string }
  }) {
    return this.http.patch(
      `${environment.backendUrl}/${BaseRoute.API}/${SubscriptionManagementRoute.BASE}/${SubscriptionManagementRoute.CHARGEBEE_SITES}/${request.chargebeeSite}/${SubscriptionManagementRoute.ITEMS}/${request.itemId}`,
      { request },
    )
  }
}
