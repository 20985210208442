<div *ngIf="filteredOrganizations$ | async as filteredOrganizations">
  <h1>
    Organizations
    <cm-button
      *ngIf="activatedFeatures.admin.createOrg"
      label="Create new Organization"
      appearance="primary"
      (cmPress)="openCreateOrganizationModal()"
    ></cm-button>
  </h1>

  <div class="search-grid">
    <div class="organization">
      <label>
        <input
          placeholder="Search Organizations"
          [(ngModel)]="searchOrgInput"
          (input)="setFilter({ searchTerm: searchOrgInput })"
        />
        <small>Note: min. 3 characters</small>
        <small
          >Prefix <code>cluster:</code> searches for cluster name or uuid</small
        >
      </label>
    </div>

    <div class="zeebe">
      <label>
        <input
          class="input-search"
          placeholder="Search Zeebe Clients"
          [(ngModel)]="searchClientInput"
          (input)="filterClients()"
        />
        <small> Note: min. 6 characters</small>
      </label>
    </div>

    <div class="links">
      <cm-link
        href="{{ getPermalink() }}"
        target="_blank"
        label="Permalink"
      ></cm-link>

      <cm-button
        appearance="link"
        (cmPress)="exportCsv()"
        label="Export to CSV File"
      ></cm-button>
      <cm-button
        appearance="link"
        (cmPress)="exportClusterCsv()"
        label="Export all Clusters"
      ></cm-button>
    </div>
  </div>

  <div class="filters">
    <div class="filter">
      <h2>Age</h2>
      <cm-filter
        [filters]="ageFilters"
        [activeFilterIndex]="ageFiltersActiveIndex"
        (cmFilterSelected)="setFilter({ age: $event.detail.value })"
      ></cm-filter>
    </div>

    <div class="filter">
      <h2>Clusters</h2>
      <cm-filter
        [filters]="clusterFilters"
        [activeFilterIndex]="clusterFiltersActiveIndex"
        (cmFilterSelected)="setFilter({ cluster: $event.detail.value })"
      ></cm-filter>
    </div>

    <div class="filter">
      <h2>Sales Plans</h2>
      <cm-filter
        [filters]="salesPlanFilters"
        [activeFilterIndex]="salesPlanFiltersActiveIndex"
        (cmFilterSelected)="setFilter({ salesPlan: $event.detail.value })"
      ></cm-filter>
    </div>

    <div class="filter">
      <h2>Cluster Plan Type Reservations</h2>
      <cm-filter
        [filters]="reservedPlanTypeFilters"
        [activeFilterIndex]="reservedPlanTypeFiltersActiveIndex"
        (cmFilterSelected)="
          setFilter({ reservedPlanType: $event.detail.value })
        "
      ></cm-filter>
    </div>

    <div class="filter">
      <h2>
        Generations
        <cm-button
          appearance="link"
          label="Reset"
          *ngIf="orgFilterService.fGenerations.length > 0"
          (cmPress)="resetGenerationsFilter()"
        ></cm-button>
      </h2>
      <div class="checkboxes">
        <cm-checkbox
          *ngFor="let generation of getOrgFilterGenerations()"
          label="{{ generation.name }}"
          [checked]="orgFilterService.fGenerations.includes(generation.uuid)"
          (cmInput)="setFilter({ generation: generation.uuid })"
        ></cm-checkbox>
      </div>
    </div>

    <div class="filter">
      <h2>
        Cluster Plan Types
        <cm-button
          appearance="link"
          label="Reset"
          *ngIf="orgFilterService.fPlanTypes.length > 0"
          (cmPress)="resetClusterPlanTypeFilter()"
        ></cm-button>
      </h2>
      <div class="checkboxes">
        <cm-checkbox
          *ngFor="
            let clusterPlanType of orgFilterService.getOrgFilterClusterPlanTypes()
          "
          label="{{ clusterPlanType.name }}"
          [checked]="orgFilterService.fPlanTypes.includes(clusterPlanType.uuid)"
          (cmInput)="setFilter({ planType: clusterPlanType.uuid })"
        ></cm-checkbox>
      </div>
    </div>
  </div>

  <div class="multiple-selection">
    <cm-button
      class="toggle"
      appearance="secondary"
      label="{{
        showSelection ? 'Disable Multi-Selection' : 'Enable Multi-Selection'
      }}"
      (cmPress)="toggleShowSelection()"
      size="small"
    ></cm-button>

    <div class="buttons" *ngIf="showSelection">
      <cm-button
        appearance="secondary"
        label="{{ selectedOrgs.length > 0 ? 'Deselect all' : 'Select all' }}"
        (cmPress)="toggleSelectAll(filteredOrganizations)"
        [disabled]="!showSelection"
      ></cm-button>

      <cm-button
        appearance="danger"
        label="Delete Clusters of selected Organizations"
        [disabled]="
          !(activatedFeatures.admin.deleteClusters && selectedOrgs.length > 0)
        "
        (cmPress)="deleteSelectedClustersDialog(filteredOrganizations)"
      ></cm-button>

      <cm-button
        appearance="danger"
        label="Delete selected Organizations"
        [disabled]="
          !(activatedFeatures.admin.deleteOrg && selectedOrgs.length > 0)
        "
        (cmPress)="deleteSelectedOrgsDialog(filteredOrganizations)"
      ></cm-button>
    </div>

    <cm-card
      *ngIf="clusterDeleteActions.length > 0 && !clusterDeleteCardDismissed"
      is-dismissable
      (cmDismissed)="clusterDeleteCardDismissed = true"
    >
      <h3 slot="header">Current Cluster Delete Actions</h3>
      <cm-datalist>
        <cm-datalist-item
          *ngFor="let action of clusterDeleteActions"
          label="{{ action.orgName + ': ' + action.clusterName }}"
        >
          <cm-loader *ngIf="!action.isDeleted" size="small"></cm-loader>
          <span class="checkmark" *ngIf="action.isDeleted"></span>
        </cm-datalist-item>
      </cm-datalist>
    </cm-card>
  </div>

  <div
    class="organizations"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <ng-container *ngFor="let organization of filteredOrganizations">
      <organization
        [organization]="organization"
        [showSelection]="showSelection"
        [logins]="logins"
        [selected]="selectedOrgs.includes(organization.uuid)"
        (selectionChange)="changeOrgSelection(organization.uuid)"
        *ngIf="filteredOrganizations.indexOf(organization) < displayingOrgs"
      ></organization>
    </ng-container>
  </div>
</div>

<cm-modal
  #createOrganizationModal
  headline="Creating a new Organization"
  cancelLabel="Cancel"
  confirmLabel="Create Organization"
  [confirmDisabled]="organizationNameFormControl.invalid"
>
  <input
    type="text"
    placeholder="Organization name"
    [formControl]="organizationNameFormControl"
  />

  <cm-button label="Cancel" slot="cancel"></cm-button>
  <cm-button
    label="Create Organization"
    appearance="primary"
    slot="confirm"
    [disabled]="organizationNameFormControl.invalid"
  ></cm-button>
</cm-modal>
