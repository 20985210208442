import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { ClusterPlanTypeDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { SalesPlanRestService } from '../service/salesplan.rest.service'

@Injectable({
  providedIn: 'root',
})
export class AllClusterPlanTypesResolverService
  implements Resolve<ClusterPlanTypeDto[]> {
  private clusterplanTypes: Observable<ClusterPlanTypeDto[]>

  constructor(private salesPlanService: SalesPlanRestService) {
    this.clusterplanTypes = this.salesPlanService
      .getClusterPlanTypes()
      .pipe(shareReplay())
  }

  public resolve(): Observable<ClusterPlanTypeDto[]> {
    return this.clusterplanTypes
  }
}
