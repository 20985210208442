import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { UserQueryParams } from '@camunda-cloud/camunda-cloud-idm'
import { BehaviorSubject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Features } from '../../../../../commons/Features'
import { OrganizationDto } from '../../../../../commons/Organization.dto'
import { UserDto } from '../../../../../commons/User.dto'
import { UserRestService } from '../../../app/service/user.rest.service'

type Filter = 'all' | 'pending' | 'tokenexpired' | 'supportagent'

@Component({
  selector: 'app-list-all-users',
  templateUrl: './list-all-users.component.html',
  styleUrls: ['./list-all-users.component.scss'],
})
export class ListAllUsersComponent implements OnInit {
  public filteredUsers$: BehaviorSubject<UserDto[]> = new BehaviorSubject([])
  public allOrgs: OrganizationDto[]
  public activatedFeatures: Features
  public allRoles: string[]

  public loading: boolean = false

  public filterForm: FormGroup = new FormGroup({
    search: new FormControl(),
  })

  public readonly filters: Array<{ label: string; value: Filter }> = [
    { label: 'All Users', value: 'all' },
    { label: 'Pending Users', value: 'pending' },
    { label: 'Token expired', value: 'tokenexpired' },
  ]

  public filter: Filter = 'all'

  constructor(
    private userRestService: UserRestService,
    private route: ActivatedRoute,
  ) {
    this.filterForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((_) => {
        this.find()
      })
  }

  public ngOnInit() {
    this.allOrgs = this.route.snapshot.data.orgs
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.allRoles = this.route.snapshot.data.allRoles

    if (this.activatedFeatures.supportAgent.seeOrgMemberships) {
      this.filters.push({ label: 'Support Agent', value: 'supportagent' })
    }
  }

  filterSelected(event) {
    this.filter = event.detail.value

    this.find()
  }

  public find() {
    this.loading = true

    let query: UserQueryParams = {}
    const searchControl = this.filterForm.get('search')
    let name

    if (searchControl && searchControl.value) {
      name = searchControl.value.length > 2 ? searchControl.value : undefined
    }

    switch (this.filter) {
      case 'all':
        query = {
          name,
        }

        if (name === undefined) {
          this.filteredUsers$.next([])
          this.loading = false
          return
        }

        break

      case 'pending':
        query = {
          name,
          signUpStatus: {
            verified: false,
          },
        }

        break

      case 'tokenexpired':
        query = {
          name,
          signUpStatus: {
            verified: false,
            tokenExpired: true,
          },
        }

        break

      case 'supportagent':
        this.userRestService.getSupportAgents().subscribe(async (result) => {
          if (result && result.length > 0) {
            const userPromises = result.map((user) =>
              this.userRestService.getUser(user.user_id).toPromise(),
            )
            const supportagents = await Promise.all(userPromises)
            this.filteredUsers$.next(supportagents)
            this.loading = false
          } else {
            this.filteredUsers$.next([])
            this.loading = false
          }
        })

        return

      default:
        return
    }

    if (!query || query === {}) {
      this.filteredUsers$.next([])
      this.loading = false
      return
    }

    this.userRestService.searchUsers(query).subscribe((result) => {
      if (query.signUpStatus && query.signUpStatus.tokenExpired === true) {
        const sevenDays = 1000 * 60 * 60 * 24 * 7
        const now = new Date().getTime()

        this.filteredUsers$.next(
          result.filter((user) => {
            return user &&
              user.app_metadata &&
              user.app_metadata.accounts &&
              user.app_metadata.accounts.verification &&
              user.app_metadata.accounts.verification.validTo &&
              user.app_metadata.accounts.verification.validTo < now - sevenDays
              ? true
              : false
          }),
        )

        this.loading = false
      } else {
        this.filteredUsers$.next(result)
        this.loading = false
      }
    })
  }
}
