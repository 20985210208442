<cm-page #cmPage>
  <div slot="header">
    <h1>Camunda Cloud Accounts Admin</h1>
  </div>
  <cm-page-tab label="Enterprise Plans" *ngIf="activatedFeatures.admin.readAll">
    <list-enterprise-plans></list-enterprise-plans>
  </cm-page-tab>
  <cm-page-tab
    label="Sales Plans"
    *ngIf="!chargebeeEnabled && activatedFeatures.admin.readAll"
  >
    <list-sales-plans></list-sales-plans>
  </cm-page-tab>
  <cm-page-tab
    label="Sales Plans"
    *ngIf="chargebeeEnabled && activatedFeatures.admin.readAll"
  >
    <app-list-salesplans></app-list-salesplans>
  </cm-page-tab>
</cm-page>
